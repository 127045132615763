import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import GridViewIcon from "@mui/icons-material/GridView";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import SchoolIcon from "@mui/icons-material/School";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LanguageIcon from "@mui/icons-material/Language";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import Looks6Icon from "@mui/icons-material/Looks6";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import PendingIcon from "@mui/icons-material/Pending";
import PreviewIcon from "@mui/icons-material/Preview";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import FlakyIcon from "@mui/icons-material/Flaky";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CircleIcon from "@mui/icons-material/Circle";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import HomeIcon from "@mui/icons-material/Home";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import LeakRemoveIcon from "@mui/icons-material/LeakRemove";
import RemoveIcon from "@mui/icons-material/Remove";
import StarIcon from "@mui/icons-material/Star";
import BuildIcon from "@mui/icons-material/Build";
import TableChartIcon from "@mui/icons-material/TableChart";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import InsightsIcon from "@mui/icons-material/Insights";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import EventIcon from "@mui/icons-material/Event";
import TuneIcon from "@mui/icons-material/Tune";
import DescriptionIcon from "@mui/icons-material/Description";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PestControlIcon from "@mui/icons-material/PestControl";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import AddIcon from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
    Apps,
    ArrowDownward,
    ArrowUpward,
    List,
    Square,
} from "@mui/icons-material";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import LoupeIcon from "@mui/icons-material/Loupe";
import LinkIcon from "@mui/icons-material/Link";
import EmailIcon from "@mui/icons-material/Email";
import TodayIcon from "@mui/icons-material/Today";
import PrintIcon from "@mui/icons-material/Print";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import RefreshIcon from "@mui/icons-material/Refresh";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import SentimentDissatisfied from "@mui/icons-material/SentimentDissatisfied";
import LiquorIcon from "@mui/icons-material/Liquor";

import CheckInIcon from "../components/icons/CheckInIcon";
import CheckOutIcon from "../components/icons/CheckOutIcon";
import ResDateIcon from "../components/icons/ResDateIcon";

const Icons = {
    // Pages
    Home: HomeIcon,
    DashBoard: GridViewIcon,
    LeadersBoard: LeaderboardIcon,
    ScoreBoard: SportsScoreIcon,
    Reports: AssessmentIcon,
    ReportBuilder: BuildIcon,
    InsightAssist: ConnectWithoutContactIcon,
    InsightExpert: HubOutlinedIcon,
    Concierge: RoomServiceIcon,
    Academy: SchoolIcon,
    Settings: SettingsIcon,
    UserProfile: PersonIcon,
    RedAlerts: NotificationsIcon,
    ReleaseNotes: DescriptionIcon,
    EventsCalendar: CalendarMonthIcon,
    Today: TodayIcon,
    Insights: QueryStatsIcon,
    PLUS: LoupeIcon,
    Email: EmailIcon,
    AccountManagers: ManageAccountsIcon,
    Extras: LiquorIcon,

    // Settings
    LightMode: LightModeIcon,
    DarkMode: DarkModeIcon,
    Language: LanguageIcon,

    // Navbar
    Navbar: MenuIcon,
    CloseNavbar: CloseIcon,
    NoConnection: WifiOffIcon,
    NoAPIConnection: LeakRemoveIcon,
    Remove: RemoveIcon,
    Print: PrintIcon,

    //Arrows
    ArrowRight: ChevronRightIcon,
    ArrowLeft: ChevronLeftIcon,
    ArrowUp: KeyboardArrowUpIcon,
    ArrowDown: KeyboardArrowDownIcon,

    ArrowUpDouble: KeyboardDoubleArrowUpIcon,
    ArrowDownDouble: KeyboardDoubleArrowDownIcon,

    //Full Arrows
    ArrowFullUp: ArrowUpward,
    ArrowFullDown: ArrowDownward,

    //Priorities
    Priority1: LooksOneIcon,
    Priority2: LooksTwoIcon,
    Priority3: Looks3Icon,
    Priority4: Looks4Icon,
    Priority5: Looks5Icon,
    Priority6: Looks6Icon,
    PriorityMissed: HelpCenterIcon,

    Horizontal: HorizontalRuleIcon,

    //Status
    StatusPendingReview: PendingIcon,
    StatusUnderReview: PreviewIcon,
    StatusInProgress: DirectionsRunIcon,
    StatusPartialComplete: FlakyIcon,
    StatusComplete: CheckCircleIcon,
    StatusMissed: HelpCenterIcon,
    StatusCancel: CancelIcon,
    StatusWarning: ErrorIcon,

    //Login
    User: PersonOutlineOutlinedIcon,
    Password: LockOutlinedIcon,
    LogOut: LogoutIcon,

    CheckIn: CheckInIcon,
    CheckOut: CheckOutIcon,
    ResDate: ResDateIcon,

    //Others
    Hotel: ApartmentIcon,
    Help: HelpOutlineIcon,
    Circle: CircleIcon,
    TriangleRight: PlayArrowIcon,
    Options: MoreVertIcon,
    Search: SearchIcon,
    Edit: EditIcon,
    Star: StarIcon,
    Table: TableChartIcon,
    Info: InfoOutlinedIcon,
    ReportError: ReportProblemOutlinedIcon,
    LineChart: SsidChartIcon,
    LineChart2: InsightsIcon,
    LineChart3: StackedLineChartIcon,
    LineChartScale: LinearScaleIcon,
    Size: FormatSizeIcon,
    Emoji: InsertEmoticonIcon,
    CalendarMap: CalendarViewMonthIcon,
    Calendar: EventIcon,
    Filter: TuneIcon,
    Document: DescriptionIcon,
    New: NoteAddIcon,
    Add: AddIcon,
    Bug: PestControlIcon,
    Change: ChangeCircleIcon,
    Improvement: UploadFileIcon,
    Delete: DeleteIcon,
    OpenLink: OpenInNewIcon,
    Deprecated: AutoDeleteIcon,
    Square: Square,
    Link: LinkIcon,
    Refresh: RefreshIcon,
    Details: Apps,
    List: List,
    NumberedList: FormatListNumberedIcon,
    Copy: ContentCopyIcon,
    EyeOn: Visibility,
    EyeOff: VisibilityOff,
    AdminPanel: AdminPanelSettingsOutlinedIcon,
    FilterFunnel: FilterAltIcon,
    Clean: CleaningServicesOutlinedIcon,
    Cancel: CancelIcon,
    AddMany: LibraryAddOutlinedIcon,
    MidSadFace: SentimentDissatisfied,
};
export default Icons;

export const DynamicIcon = ({ Icon, ...props }) => {
    return <Icon {...props} />;
};
