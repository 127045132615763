import React, { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Stack,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import Colours, { avatarColours } from "../../helpers/colours";
import EventsDetails from "./EventsDetails";
import { newDateUTC } from "../../helpers/dates";
import dayjs from "dayjs";

// EventDisplay rename?
// mode = "line" line with no info | "empty" placeholder | "row" info | "card" info
const EventDisplay = ({
    event = {},
    handleRefreshEvents,
    mode = "line",
    hotelID,
    clickable = true,
    highlight = false,
}) => {
    const [isClicked, setIsClicked] = useState(false);

    const [anchorClick, setAnchorClick] = useState(null);

    const handleClick = (v) => {
        setIsClicked(!isClicked);
        setAnchorClick(v.currentTarget);
    };

    const handleCloseDetails = () => {
        setIsClicked(!isClicked);
        setAnchorClick(null);
    };

    useEffect(() => {}, [event]);

    const getLineWidth = () => {
        const currentDate = dayjs(event.CurrentDateString);
        const currentDay = currentDate.day() === 0 ? 7 : currentDate.day();
        const toDate = dayjs(event.ToDateString);
        const toDay = toDate.day() === 0 ? 7 : toDate.day();
        const oneDay = 1000 * 60 * 60 * 24;
        const diffInTime = toDate.valueOf() - currentDate.valueOf();
        const diffInDays = Math.round(diffInTime / oneDay) + 1;

        let width = 100;

        if (toDay < currentDay) width = (8 - currentDay) * 100;
        else if (toDay >= currentDay)
            width =
                diffInDays > 7
                    ? (8 - currentDay) * 100
                    : (toDay - currentDay + 1) * 101;

        return `${width}%`;
    };

    return (
        <>
            {mode === "empty" && (
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        height: "18px",
                        backgroundColor: "#FFFFFF",
                    }}
                >
                    <Typography height={"18px"}> </Typography>
                </Box>
            )}
            {Object.keys(event).length > 0 && (
                <>
                    {mode === "line" && ( //event.Style = start: calendar event display | full: open a day view
                        <Box
                            sx={{
                                position: "relative",
                                zIndex: event.Elevation,
                                display: "flex",
                                cursor: "pointer",
                                width:
                                    event.Style === "start"
                                        ? getLineWidth
                                        : "100%",
                                height: "18px",
                                backgroundColor: isClicked
                                    ? Colours.secondarySoft
                                    : event.ColourLightMode ?? avatarColours[0],
                                borderRadius: "5px",
                            }}
                            onClick={(v) => handleClick(v)}
                        >
                            <Typography
                                fontSize={"80%"}
                                maxWidth={"90%"}
                                justifyContent={"center"}
                                fontWeight={"bold"}
                                paddingX={1}
                                overflow={"hidden"}
                                textOverflow={"ellipsis"}
                                noWrap
                            >
                                {event.Name}
                            </Typography>
                        </Box>
                    )}
                    {mode === "row" && (
                        <TableRow
                            key={event.EventID}
                            onClick={(v) => handleClick(v)}
                            sx={{
                                cursor: "pointer",
                                opacity:
                                    event.FromDate <
                                    newDateUTC().toISOString().slice(0, 10)
                                        ? 0.5
                                        : 1,
                                border: "2px solid lightgrey",
                            }}
                        >
                            <TableCell>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Typography>{`${new Date(event.FromDate)
                                            .toUTCString()
                                            .slice(0, 16)}`}</Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        direction={"column"}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "10px",
                                                height: "100%",
                                                marginX: 2,
                                                backgroundColor:
                                                    event.ColourLightMode ??
                                                    avatarColours[0],
                                            }}
                                        />

                                        <Typography>{event.Name}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>
                                            {event.Category.toUpperCase()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>
                                            {"Owner: "}
                                            {event.Owner ?? ""}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    )}
                    {mode === "card" && (
                        <div
                            style={clickable ? { cursor: "pointer" } : {}}
                            onClick={(v) => handleClick(v)}
                        >
                            <Stack
                                direction={"column"}
                                sx={{
                                    borderLeft: "10px solid",
                                    borderColor:
                                        event.ColourLightMode ??
                                        avatarColours[0],
                                    paddingX: 2,
                                }}
                            >
                                <Typography
                                    fontWeight={highlight ? "bold" : ""}
                                >
                                    {event.Name}
                                </Typography>
                                <Typography
                                    fontWeight={highlight ? "bold" : ""}
                                >{`${new Date(event.FromDate)
                                    .toUTCString()
                                    .slice(0, 16)}`}</Typography>
                            </Stack>
                        </div>
                    )}

                    {isClicked && clickable && (
                        <EventsDetails
                            eventDetails={event}
                            anchorClick={anchorClick}
                            onCloseDetails={handleCloseDetails}
                            handleRefreshEvents={handleRefreshEvents}
                            hotelID={hotelID}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default EventDisplay;
