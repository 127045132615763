import React from "react";

import { Box, Button, Modal, Stack, Typography } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
};

const ModalConfirmation = ({
    answers = [
        { title: "Confirm", value: "confirm" },
        { title: "Cancel", value: "cancel" },
    ],
    setAnswer,
    open,
    setOpen,
    title = "",
    description = "",
}) => {
    const handleClose = (value = "") => {
        setAnswer(value);
        setOpen(false);
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-dialog">
            <Box sx={style}>
                <Stack direction="column" spacing={2}>
                    {title && (
                        <Typography fontWeight={"bold"}>{title}</Typography>
                    )}
                    {description && <Typography>{description}</Typography>}
                    <Stack
                        direction={"row"}
                        justifyContent={"space-around"}
                        display={"flex"}
                    >
                        {answers.length > 0 &&
                            answers.map((answer) => {
                                return (
                                    <Button
                                        key={answer.title}
                                        onClick={() =>
                                            handleClose(answer.value)
                                        }
                                        sx={{ textTransform: "none" }}
                                    >
                                        {answer.title}{" "}
                                    </Button>
                                );
                            })}
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    );
};

export default ModalConfirmation;
