import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Axios from "axios";

import { v4 as uuidv4 } from "uuid";

import { URLAPI } from "../configuration";
import version from "../version";

const NOT_TRACK = ["/login"];

const Track = () => {
    const location = useLocation();

    const user_id = useSelector((state) => state.auth?.user?.user_id);
    const email = useSelector((state) => state.auth?.user?.user);

    const sessionId =
        localStorage.getItem("sessionId") || generateNewSessionId();
    localStorage.setItem("sessionId", sessionId);

    let inactivityTimer;

    const resetTimer = () => {
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(
            () => localStorage.removeItem("sessionId"),
            30 * 60 * 1000
        ); // 30 minutes
    };

    useEffect(() => {
        window.addEventListener("mousemove", resetTimer);
        window.addEventListener("keydown", resetTimer);
        window.addEventListener("scroll", resetTimer);

        return () => {
            window.removeEventListener("mousemove", resetTimer);
            window.removeEventListener("keydown", resetTimer);
            window.removeEventListener("scroll", resetTimer);
        };
    }, []);

    useEffect(() => {
        const sendData = async () => {
            const trackData = {
                user_id,
                sessionId,
                url: location.pathname,
                ts: new Date().toISOString(),
                lang: navigator.language,
                WEB_version: version().replace("v", ""),
                email,
            };

            if (user_id && !NOT_TRACK.includes(location.pathname)) {
                Axios.post(`${URLAPI}/track`, trackData)
                    .then((response) => {
                        //console.log(response.data);
                    })
                    .catch((error) => console.log(error));
            }
        };
        sendData();
    }, [location, sessionId]);

    return null;
};

const generateNewSessionId = () => {
    return uuidv4();
};

export default Track;
