// FiltersByClient.jsx

import React, { useEffect, useState } from "react";

import FilterDropdown, { transformArrToOptions } from "./FiltersDropdown";

const FiltersByClient = ({
    height = "auto",
    hotelsList,
    setSelectedHotels,
    handleSelectedHotels,
    currencies,
    setSelectedCurrencies,
    handleSelectedCurrencies,
}) => {
    const [activeFilters, setActiveFilters] = useState({
        Hotels: [],
        Currencies: [],
    });
    const [hotelsOptions, setHotelsOptions] = useState([]);

    const [currenciesOptions, setCurrenciesOptions] = useState({});
    useEffect(() => {
        console.log({ currencies });
        setCurrenciesOptions(
            currencies
                ? Object.fromEntries(
                      Object.entries(currencies).map(([k, v]) => [
                          k,
                          `${k} (${v})`,
                      ])
                  )
                : {}
        );
    }, [currencies]);

    useEffect(() => {
        // const auxHotelList = [];

        // Object.keys(HOTELS_INFO).forEach((e) => {
        //     if (!isNaN(e)) {
        //         auxHotelList.push(HOTELS_INFO[e]["name"]);
        //     }
        // });

        // setActiveFilters({ Hotels: auxHotelList.sort() });

        // setHotelsOptions(auxHotelList.sort());

        // setHotelsOptions([]);
        // if (!hotelsList || hotelsList.length === 0) return;

        setHotelsOptions(hotelsList.length > 0 ? hotelsList.sort() : []);
    }, [hotelsList]);

    useEffect(() => {
        setSelectedHotels(activeFilters.Hotels);
        handleSelectedHotels(activeFilters.Hotels);

        setSelectedCurrencies(activeFilters.Currencies);
        handleSelectedCurrencies(activeFilters.Currencies);
    }, [activeFilters]);

    return (
        <>
            <FilterDropdown
                title="Filters"
                buttonProps={{ sx: { height: height } }}
                activeFilters={activeFilters}
                setActiveFilters={(activeFilters) => {
                    console.log({ activeFilters });
                    setActiveFilters(activeFilters);
                }}
                allowExclusion={false}
                filters={{
                    Hotels: {
                        label: "Hotels",
                        options: transformArrToOptions(hotelsOptions),
                    },
                    Currencies: {
                        label: "Currencies",
                        options: currenciesOptions,

                        // transformArrToOptions(currenciesOptions),
                    },
                }}
            />
        </>
    );
};

export default FiltersByClient;
