import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Axios from "axios";

import {
    Container,
    Paper,
    Stack,
    useMediaQuery,
    useTheme,
    Snackbar,
    Alert,
    Box,
    Typography,
    Grid,
} from "@mui/material";

import EventsCalendar from "../components/events/EventsCalendar";
import EventsList from "../components/events/EventsList";
import EventsCarousel from "../components/events/EventsCarousel";

import { processEvents } from "../helpers/reports";

import { URLAPI } from "../configuration";
import { dataSimpleTypes } from "../types/dataSimpleTypes";

export const CALENDAR_MODE = "CALENDAR_MODE";
export const LIST_MODE = "LIST_MODE";

const EventsCalendarScreen = ({ isolated = false }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const dispatch = useDispatch();

    const { dataSimple } = useSelector((state) => state);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const { auth } = useSelector((state) => state);

    const { t } = useTranslation("navbar");

    const [mainMode, setMainMode] = useState(CALENDAR_MODE);

    const [events, setEvents] = useState({});

    const [refreshEvents, setRefreshEvents] = useState(false);

    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const handleSuccessAlertClose = () => {
        setSuccessAlertOpen(false);
        setAlertMessage("");
    };

    const handleRefreshEvents = (alert = "Event was deleted.") => {
        setRefreshEvents(!refreshEvents);
        setSuccessAlertOpen(true);
        setAlertMessage(alert);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios({
                    method: "get",
                    url: `${URLAPI}/events/${
                        hotelID === "default" ? 88 : hotelID
                    }`,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                });

                const auxEvents = response.data.data;
                const auxProcessedEvents = processEvents(auxEvents);

                setEvents(auxProcessedEvents);
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        };

        fetchData();

        dispatch({ type: dataSimpleTypes.reload });
    }, [hotelID, refreshEvents]);

    return (
        <>
            <Grid container>
                <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                    <Typography variant="h4">Events</Typography>
                </Grid>
            </Grid>

            <Container component={Paper} maxWidth={false} sx={{ p: 3 }}>
                <Stack direction={"column"} spacing={2}>
                    {mainMode === CALENDAR_MODE ? (
                        <EventsCalendar
                            hotelID={hotelID}
                            isMobile={isMobile}
                            setMainMode={setMainMode}
                            events={events}
                            handleRefreshEvents={handleRefreshEvents}
                        />
                    ) : (
                        <EventsList
                            events={events}
                            isMobile={isMobile}
                            setMainMode={setMainMode}
                            handleRefreshEvents={handleRefreshEvents}
                            hotelID={hotelID}
                        />
                    )}
                    <EventsCarousel
                        isMobile={isMobile}
                        events={events}
                        handleRefreshEvents={handleRefreshEvents}
                        hotelID={hotelID}
                        clickable={true}
                    />
                </Stack>
            </Container>
            <br />

            <Snackbar
                open={successAlertOpen}
                autoHideDuration={6000}
                onClose={handleSuccessAlertClose}
            >
                <Alert onClose={handleSuccessAlertClose} severity="success">
                    {alertMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default EventsCalendarScreen;
