import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Box,
    Container,
    Stack,
    Table,
    TableBody,
    Typography,
} from "@mui/material";

import EChartsReact from "echarts-for-react";

import HoverPaper from "./shared/HoverPaper";
import NoDataRow from "./NoDataRow";
import FiltersMetricSelection from "./FiltersMetricSelection";
import { COMPARISON_FILTER, DEFAULT_COMPARISON } from "../helpers/filters";
import { fns } from "../helpers/common";
import Colours from "../helpers/colours";

const ReportChartBarsExtras = ({
    data,
    entriesLimit = 5,
    entriesLimitSort = "descending",
    initialMetric = "totalCost",
    metrics = {
        totalCost: {
            label: "Total cost",
            short: "Extras Revenue",
            format: { left: "currency" },
        },
        count: {
            label: "Count",
            short: "Number of extras sold",
            format: {},
        },
    },
    title = "Total Revenue by Extras",
}) => {
    const js = useSelector((state) => state.globalReportFilters);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [metric, setMetric] = useState(initialMetric);
    const [formattedData, setFormattedData] = useState({});
    const [total, setTotal] = useState(0);

    const [update, setUpdate] = useState(0);

    useEffect(() => {
        if (!data || !data[""] || !data[""].result) {
            return;
        }

        const currentData = data[""].result.extrasByType ?? {};
        const lastYearData = data[""].resultOneYearBefore?.extrasByType ?? {};

        const currentKeys = Object.keys(currentData);
        const lastYearKeys = Object.keys(lastYearData);
        const unionKeys = Array.from(
            new Set([...currentKeys, ...lastYearKeys])
        );

        let sums = {};

        // Armar la lista de los extras con su valor actual
        unionKeys.forEach((extraName) => {
            const valueNow = currentData[extraName]?.[metric] ?? 0;
            sums[extraName] = valueNow;
        });

        let sorted = Object.entries(sums).sort((a, b) =>
            entriesLimitSort === "ascending" ? a[1] - b[1] : b[1] - a[1]
        );

        let top = sorted.slice(0, entriesLimit);
        let leftover = sorted.slice(entriesLimit);

        let finalKeys = top.map(([k]) => k);
        let othersSumThisYear = 0;
        let othersSumLastYear = 0;

        let aux_total = 0;

        const seriesDataThisYear = [];
        const seriesDataLastYear = [];

        top.forEach(([extraName]) => {
            const valueNow = currentData[extraName]?.[metric] ?? 0;
            const valueLast = lastYearData[extraName]?.[metric] ?? 0;
            seriesDataThisYear.push(valueNow);
            seriesDataLastYear.push(valueLast);
            aux_total += valueNow;
        });

        if (leftover.length > 0) {
            leftover.forEach(([extraName]) => {
                othersSumThisYear += currentData[extraName]?.[metric] ?? 0;
                othersSumLastYear += lastYearData[extraName]?.[metric] ?? 0;
            });

            finalKeys.push("Others");
            seriesDataThisYear.push(othersSumThisYear);
            seriesDataLastYear.push(othersSumLastYear);
            aux_total += othersSumThisYear;
        }

        let comparisonLabel =
            COMPARISON_FILTER[js?.filters?.Comparison ?? DEFAULT_COMPARISON]
                ?.label || "Previous year";

        const series = [
            {
                name: comparisonLabel,
                type: "bar",
                data: seriesDataLastYear,
                itemStyle: { color: "grey" },
                lineStyle: { color: "grey" },
                symbolStyle: { color: "grey" },
                label: {
                    show: true,
                    position: "top",
                    formatter: (params) => {
                        return fns(hotelID, params.value, {});
                    },
                    fontSize: 10,
                    color: "black",
                },
            },
            {
                name: "Current Period",
                type: "bar",
                data: seriesDataThisYear,
                itemStyle: { color: "black" },
                lineStyle: { color: "black" },
                symbolStyle: { color: "black" },
                label: {
                    show: true,
                    position: "top",
                    formatter: (params) => {
                        return fns(hotelID, params.value, {});
                    },
                    fontSize: 10,
                    color: "black",
                },
            },
        ];

        setFormattedData({
            xAxisData: finalKeys,
            series,
        });
        setTotal(aux_total);
        setUpdate((prev) => prev + 1);
    }, [data, metric, entriesLimit, entriesLimitSort, js, hotelID]);

    useEffect(() => {
        setMetric(initialMetric);
    }, [metrics, initialMetric]);

    if (!data || !data[""] || !data[""].result) {
        // No data
        return null;
    }

    return (
        <Stack>
            {title && (
                <Typography fontWeight="bold" sx={{ pt: 2, pb: 1 }}>
                    {title}
                </Typography>
            )}
            <Container
                maxWidth={false}
                component={HoverPaper}
                sx={{ p: 2, backgroundColor: Colours.notificationCard }}
            >
                <Box>
                    <Stack direction="row" alignItems="baseline">
                        <FiltersMetricSelection
                            metric={metric}
                            metrics={metrics}
                            setMetric={setMetric}
                        />
                        <Typography>by Extras Name</Typography>
                    </Stack>
                </Box>

                {/* Total */}
                <br />
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography>
                        Total:{" "}
                        <span style={{ fontWeight: "bold" }}>
                            {fns(hotelID, total, metrics[metric]?.format ?? {})}
                        </span>
                    </Typography>
                </Stack>

                {/* Chart */}
                {Object.values(data[""].result.extrasByType ?? {}).length >
                0 ? (
                    <EChartsReact
                        key={update}
                        option={{
                            tooltip: {
                                trigger: "axis",
                                formatter: function (params) {
                                    let tooltipHtml = `<div style="border-collapse: collapse;">`;
                                    tooltipHtml += `<div style="margin-bottom: 5px;"><strong>${params[0].name}</strong></div>`;

                                    params.forEach(function (item) {
                                        tooltipHtml +=
                                            '<div style="display: flex; justify-content: space-between; padding: 0px;">';
                                        tooltipHtml += `<div style="padding-left:3px;">${item.marker}</div>`;
                                        tooltipHtml += `<div style="flex: 1; text-align: left; padding-left:4px;">${item.seriesName}</div>`;
                                        tooltipHtml += `<div style="padding-left:15px;"><strong>${fns(
                                            hotelID,
                                            item.value,
                                            {}
                                        )}</strong></div>`;
                                        tooltipHtml += "</div>";
                                    });

                                    tooltipHtml += "</div>";
                                    return tooltipHtml;
                                },
                            },
                            legend: { show: false },
                            xAxis: {
                                type: "category",
                                data: formattedData.xAxisData,
                                axisLabel: {
                                    interval: 0,
                                    textStyle: {
                                        fontWeight: "light",
                                    },
                                    formatter: function (value) {
                                        if (value?.length > 15) {
                                            return value.slice(0, 15) + "...";
                                        }
                                        return value;
                                    },
                                },
                            },
                            yAxis: {
                                name: metrics[metric]?.short ?? "",
                                nameLocation: "middle",
                                nameGap: 50,
                                nameTextStyle: {
                                    fontSize: 14,
                                },
                                axisLabel: {
                                    formatter: function (value) {
                                        if (value >= 1000) {
                                            return `${value / 1000}k`;
                                        }
                                        return value;
                                    },
                                },
                            },
                            series: formattedData.series,
                        }}
                    />
                ) : (
                    <Table>
                        <TableBody>
                            <NoDataRow text="No data for these filters" />
                        </TableBody>
                    </Table>
                )}
            </Container>
        </Stack>
    );
};

export default ReportChartBarsExtras;
