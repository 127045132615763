import React, { useEffect, useState } from "react";
import Axios from "axios";
import EChartsReact from "echarts-for-react";

import { URLAPI } from "../configuration";

import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";

import { DataElement } from "../components/shared/ReportBuilder/ReportTableMetricsSummary";
import HoverPaper from "../components/shared/HoverPaper";
import Colours from "../helpers/colours";
import DateRangePicker from "../components/DateRangePicker";
import { ITEM_HEIGHT } from "../components/FiltersReport";
import UserConnectionsTable from "../components/UserConnectionsTable";
import SitesUserActivity from "../components/SitesUserActivity";
import dayjs from "dayjs";

const UsersActivityScreen = () => {
    const [data, setData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [notAroUsers, setNotAroUsers] = useState([]);
    const [aroUsers, setAroUsers] = useState([]);

    const [uniqueUsersCount, setUniqueUsersCount] = useState(0);
    const [uniqueSessionsCount, setUniqueSessionsCount] = useState(0);
    const [thisMonthUsers, setThisMonthUsers] = useState(0);
    const [thisMonthSessions, setThisMonthSessions] = useState(0);
    const [thisMonthUsersNotAro, setThisMonthUsersNotAro] = useState(0);
    const [thisMonthUsersAro, setThisMonthUsersAro] = useState(0);

    const [heatMapDataSessions, setHeatMapDataSessions] = useState([
        ["2025-01-01", 2],
    ]);
    const [heatMapDataUsers, setHeatMapDataUsers] = useState([
        ["2025-01-01", 2],
    ]);

    const columns = [
        { field: "user_id", headerName: "User ID", width: 100 },
        { field: "email", headerName: "Email", width: 250 },
        { field: "url", headerName: "URL", width: 200 },
        {
            field: "ts",
            headerName: "Timestamp",
            width: 200,
            format: (params) => {
                const date = new Date(params.value);
                return format(date, "yyyy-MM-dd HH:mm:ss");
            },
        },
        { field: "sessionId", headerName: "Session ID", width: 250 },
        { field: "lang", headerName: "Language", width: 130 },
        { field: "WEB_version", headerName: "Web Version", width: 130 },
        { field: "id", headerName: "ID", width: 90 },
        { field: "SiteName", headerName: "SiteNames", width: 300 },
    ];

    const prepareData = (data_) => {
        // TABLE
        let thisMonth_ = data.filter((d) => {
            const { ts } = d;
            return (
                new Date(ts) >=
                new Date(
                    new Date().getUTCFullYear(),
                    new Date().getUTCMonth(),
                    1
                )
            );
        });

        setUniqueUsersCount(new Set(data.map((item) => item.email)).size);
        setUniqueSessionsCount(
            new Set(data.map((item) => item.sessionId)).size
        );
        setThisMonthUsers(new Set(thisMonth_.map((item) => item.email)).size);
        setThisMonthSessions(
            new Set(thisMonth_.map((item) => item.sessionId)).size
        );
        setThisMonthUsersNotAro(
            new Set(
                thisMonth_
                    .filter((item) => !item.email.includes("@aro.ie"))
                    .map((item) => item.email)
            ).size
        );
        setThisMonthUsersAro(
            new Set(
                thisMonth_
                    .filter((item) => item.email.includes("@aro.ie"))
                    .map((item) => item.email)
            ).size
        );

        // CHARS
        let mapDaySessionIds = {};
        let mapDayUsers = {};
        let mapDayURLs = {};
        data_.forEach((r) => {
            const d = new Date(r.ts);
            if (!isNaN(d.getTime())) {
                const dayStr = d.toISOString().slice(0, 10); // "YYYY-MM-DD"
                if (!mapDaySessionIds[dayStr]) {
                    mapDaySessionIds[dayStr] = new Set();
                }
                mapDaySessionIds[dayStr].add(r.sessionId);

                if (!mapDayUsers[dayStr]) {
                    mapDayUsers[dayStr] = new Set();
                }
                mapDayUsers[dayStr].add(r.email);

                if (!mapDayURLs[dayStr]) {
                    mapDayURLs[dayStr] = new Set();
                }
                mapDayURLs[dayStr].add(r.url);
            }
        });
        // Sessions
        const seriesDataSessions = Object.entries(mapDaySessionIds).map(
            ([day, sessionSet]) => [day, sessionSet.size]
        );
        setHeatMapDataSessions(seriesDataSessions);
        // Users
        const seriesDataUsers = Object.entries(mapDayUsers).map(
            ([day, usersSet]) => [day, usersSet.size]
        );
        setHeatMapDataUsers(seriesDataUsers);
    };

    // Clean selected users if dates are changed
    const [pickerTo, setPickerTo] = useState(dayjs());
    const [pickerFrom, setPickerFrom] = useState(dayjs(2024, 6, 1));
    useEffect(() => {
        setSelectedUsers([]);
    }, [pickerFrom, pickerTo]);

    // Filter data by selected users
    const [filteredData, setFilteredData] = useState([]);
    useEffect(() => {
        if (!data || data.length === 0) return;
        const filteredData_ =
            selectedUsers.length === 0
                ? data
                : data.filter((item) => selectedUsers.includes(item.email));

        prepareData(filteredData_);
        setFilteredData(filteredData_);
    }, [data, selectedUsers]);

    //
    const [URLsFrecuency, setURLsFrecuency] = useState([]);
    const [checkboxExcludeApp, setCheckboxExcludeApp] = useState(false);
    useEffect(() => {
        // Filtrar `filteredData` por fecha
        const filteredByDate = filteredData.filter((item) => {
            const tsDate = new Date(item.ts);
            return tsDate >= pickerFrom && tsDate <= pickerTo;
        });

        setNotAroUsers(
            [
                ...new Set(
                    data
                        .filter(
                            (item) =>
                                new Date(item.ts) >= pickerFrom &&
                                new Date(item.ts) <= pickerTo &&
                                !(item.email ?? "").includes("@aro.ie")
                        )
                        .map((item) => item.email)
                ),
            ].sort()
        );
        setAroUsers(
            [
                ...new Set(
                    data
                        .filter(
                            (item) =>
                                new Date(item.ts) >= pickerFrom &&
                                new Date(item.ts) <= pickerTo &&
                                (item.email ?? "").includes("@aro.ie")
                        )
                        .map((item) => item.email)
                ),
            ].sort()
        );

        // Contar frecuencia de URLs
        const mapUrls = {};
        filteredByDate.forEach((row) => {
            const url = row.url || "N/A";
            if (!mapUrls[url]) {
                mapUrls[url] = 0;
            }
            mapUrls[url]++;
        });

        // Convertir a array [{ url: '...', count: n }, ...]
        const frequencies = Object.entries(mapUrls)
            .map(([url, count]) => ({
                url,
                count,
            }))
            .filter((i) => !(checkboxExcludeApp && i.url === "/app"));

        // Ordenar por más frecuencia (opcional)
        frequencies.sort((a, b) => b.count - a.count);

        // Guardar en state
        setURLsFrecuency(frequencies);
    }, [filteredData, checkboxExcludeApp, pickerFrom, pickerTo]);

    //   ____  _____ _     _____ ____ _____   _   _ ____  _____ ____  ____
    //  / ___|| ____| |   | ____/ ___|_   _| | | | / ___|| ____|  _ \/ ___|
    //  \___ \|  _| | |   |  _|| |     | |   | | | \___ \|  _| | |_) \___ \
    //   ___) | |___| |___| |__| |___  | |   | |_| |___) | |___|  _ < ___) |
    //  |____/|_____|_____|_____\____| |_|    \___/|____/|_____|_| \_\____/
    const handleToggleUser = (email) => {
        // Si el usuario ya está seleccionado, lo quitamos
        // Si no, lo añadimos
        setSelectedUsers((prevSelected) => {
            if (prevSelected.includes(email)) {
                return prevSelected.filter((u) => u !== email);
            } else {
                return [...prevSelected, email];
            }
        });
    };

    const handleToggleGroup = (groupArray) => {
        setSelectedUsers((prevSelected) => {
            const allSelected = groupArray.every((u) =>
                prevSelected.includes(u)
            );

            if (allSelected) {
                return prevSelected.filter((u) => !groupArray.includes(u));
            } else {
                const newSet = new Set(prevSelected);
                groupArray.forEach((u) => newSet.add(u));
                return Array.from(newSet);
            }
        });
    };

    const clearSelection = () => {
        setSelectedUsers([]);
    };

    //   _     ___    _    ____    ____    _  _____  _
    //  | |   / _ \  / \  |  _ \  |  _ \  / \|_   _|/ \
    //  | |  | | | |/ _ \ | | | | | | | |/ _ \ | | / _ \
    //  | |__| |_| / ___ \| |_| | | |_| / ___ \| |/ ___ \
    //  |_____\___/_/   \_\____/  |____/_/   \_\_/_/   \_\
    const LoadData = () => {
        console.log("Loading data");
        setData([]);
        Axios.get(`${URLAPI}/track/report`)
            .then((res) => {
                let newData = res.data.data;
                setData(newData);
            })
            .catch((err) => console.error("Error loading data", err));
    };

    useEffect(() => {
        if (data.length === 0) LoadData();
    }, []);

    //   __  __    _    ___ _   _
    //  |  \/  |  / \  |_ _| \ | |
    //  | |\/| | / _ \  | ||  \| |
    //  | |  | |/ ___ \ | || |\  |
    //  |_|  |_/_/   \_\___|_| \_|
    return (
        <Container maxWidth={false} sx={{ mt: 2 }}>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4">User Activity</Typography>
                <Button onClick={LoadData}>Reload Data</Button>
            </Stack>
            <Typography variant="caption">
                Tracking is not entirely precise, as it may be influenced by
                sessions that are quickly opened and closed or that remain
                active overnight. Please consider these values as approximate.{" "}
            </Typography>
            <br />
            <br />
            <Container
                maxWidth={false}
                component={HoverPaper}
                sx={{ p: 2, backgroundColor: Colours.notificationCard }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Typography
                        sx={{
                            fontSize: "0.8em",
                            fontWeight: "bold",
                        }}
                    >
                        Metrics
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "0.8em",
                            fontStyle: "italic",
                        }}
                    >
                        (data from 2024-06-01)
                    </Typography>
                </Stack>

                <Divider
                    sx={{
                        marginY: 2,
                        borderColor: Colours.navbarBottomLine,
                    }}
                />
                <Grid container rowSpacing={4} columnSpacing={2}>
                    <Grid item xs={6} md={3}>
                        <DataElement
                            name={"Unique users lifetime"}
                            value={uniqueUsersCount}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <DataElement
                            name={"This month users"}
                            value={thisMonthUsers}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <DataElement
                            name={"This month not Aró"}
                            value={thisMonthUsersNotAro}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <DataElement
                            name={"This month Aró"}
                            value={thisMonthUsersAro}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <DataElement
                            name={"Unique Sessions lifetime"}
                            value={uniqueSessionsCount}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <DataElement
                            name={"This month sessions"}
                            value={thisMonthSessions}
                        />
                    </Grid>
                </Grid>
            </Container>
            <br />

            {/*  _____  _    ____  _     _____  */}
            {/* |_   _|/ \  | __ )| |   | ____| */}
            {/*   | | / _ \ |  _ \| |   |  _|   */}
            {/*   | |/ ___ \| |_) | |___| |___  */}
            {/*   |_/_/   \_\____/|_____|_____| */}
            <div style={{ height: "500px", width: "100%" }}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    pageSize={25}
                    disableSelectionOnClick
                />
            </div>
            <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={2}
            >
                <DateRangePicker
                    title="Select time range for URLs"
                    onChangeFromDate={setPickerFrom}
                    onChangeToDate={setPickerTo}
                    fromDateProps={{
                        value: pickerFrom,
                        min: dayjs(2024, 6, 1),
                    }}
                    toDateProps={{
                        value: pickerTo,
                        min: dayjs(2024, 6, 1),
                        max: pickerFrom,
                    }}
                />
            </Stack>
            {/*  _   _ ____  _____ ____  ____    _     ___ ____ _____  */}
            {/* | | | / ___|| ____|  _ \/ ___|  | |   |_ _/ ___|_   _| */}
            {/* | | | \___ \|  _| | |_) \___ \  | |    | |\___ \ | |   */}
            {/* | |_| |___) | |___|  _ < ___) | | |___ | | ___) || |   */}
            {/*  \___/|____/|_____|_| \_\____/  |_____|___|____/ |_|   */}
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                    <Typography
                        fontWeight={"bold"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleToggleGroup(notAroUsers)}
                    >
                        Not Aró - last month
                    </Typography>
                    <List sx={{ overflow: "auto", maxHeight: 500 }}>
                        {notAroUsers.map((email) => (
                            <ListItem key={email} disablePadding>
                                <ListItemButton
                                    onClick={() => handleToggleUser(email)}
                                >
                                    <Checkbox
                                        checked={selectedUsers.includes(email)}
                                    />
                                    <ListItemText primary={email} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                    >
                        <Typography
                            fontWeight={"bold"}
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleToggleGroup(aroUsers)}
                        >
                            Aró
                        </Typography>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={clearSelection}
                        >
                            Clear
                        </Button>
                    </Stack>
                    <List sx={{ overflow: "auto", maxHeight: 500 }}>
                        {aroUsers.map((email) => (
                            <ListItem key={email} disablePadding>
                                <ListItemButton
                                    onClick={() => handleToggleUser(email)}
                                >
                                    <Checkbox
                                        checked={selectedUsers.includes(email)}
                                    />
                                    <ListItemText primary={email} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>

            {/*   ____ _   _    _    ____ _____ ____   */}
            {/*  / ___| | | |  / \  |  _ \_   _/ ___|  */}
            {/* | |   | |_| | / _ \ | |_) || | \___ \  */}
            {/* | |___|  _  |/ ___ \|  _ < | |  ___) | */}
            {/*  \____|_| |_/_/   \_\_| \_\|_| |____/  */}
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <div style={{ marginTop: 40 }}>
                        <EChartsReact
                            option={{
                                title: {
                                    text: "Sessions by day last 6 months",
                                    left: "center",
                                },
                                tooltip: {
                                    formatter: (params) => {
                                        if (params.data) {
                                            const [dayStr, count] = params.data;
                                            return `${dayStr}<br/>${count} session${
                                                count > 1 ? "s" : ""
                                            }`;
                                        }
                                        return "";
                                    },
                                },
                                // tooltip: {
                                //     formatter: (p) => {
                                //         return `Día: ${p.data[0]}<br/>Conexiones: ${p.data[1]}`;
                                //     },
                                // },
                                visualMap: {
                                    pieces: [
                                        {
                                            min: 201,
                                            color: "#24000BFF",
                                            label: "201+",
                                        },
                                        {
                                            min: 100,
                                            max: 200,
                                            color: "#24000BFF",
                                            label: "101-200",
                                        },
                                        {
                                            min: 51,
                                            max: 100,
                                            color: "#4B0218FF",
                                            label: "51-100",
                                        },
                                        {
                                            min: 21,
                                            max: 50,
                                            color: "#800026",
                                            label: "21-50",
                                        },
                                        {
                                            min: 11,
                                            max: 20,
                                            color: "#BD0026",
                                            label: "11-20",
                                        },
                                        {
                                            min: 5,
                                            max: 10,
                                            color: "#FD8D3C",
                                            label: "5-10",
                                        },
                                        {
                                            min: 2,
                                            max: 4,
                                            color: "#FECC5C",
                                            label: "2-4",
                                        },
                                        {
                                            min: 1,
                                            max: 1,
                                            color: "#FFFFB2",
                                            label: "1",
                                        },
                                    ],
                                    type: "piecewise",
                                    orient: "horizontal",
                                    left: "center",
                                    top: 40,
                                },
                                calendar: {
                                    top: 100,
                                    left: 30,
                                    right: 30,
                                    cellSize: ["auto", 20],
                                    range: [
                                        new Date(
                                            new Date().getUTCFullYear(),
                                            new Date().getUTCMonth() - 6,
                                            1
                                        )
                                            .toISOString()
                                            .slice(0, 9),
                                        new Date().toISOString().slice(0, 9),
                                    ],
                                    splitLine: {
                                        show: true,
                                    },
                                },

                                series: [
                                    {
                                        type: "heatmap",
                                        coordinateSystem: "calendar",
                                        data: heatMapDataSessions,
                                    },
                                ],
                            }}
                            style={{ width: "100%", height: 300 }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div style={{ marginTop: 40 }}>
                        <EChartsReact
                            option={{
                                title: {
                                    text: "Users by day last 6 months",
                                    left: "center",
                                },
                                tooltip: {
                                    formatter: (params) => {
                                        if (params.data) {
                                            const [dayStr, count] = params.data;
                                            return `${dayStr}<br/>${count} user${
                                                count > 1 ? "s" : ""
                                            }`;
                                        }
                                        return "";
                                    },
                                },
                                // tooltip: {
                                //     formatter: (p) => {
                                //         return `Día: ${p.data[0]}<br/>Conexiones: ${p.data[1]}`;
                                //     },
                                // },
                                visualMap: {
                                    pieces: [
                                        {
                                            min: 51,
                                            color: "#24000BFF",
                                            label: "51+",
                                        },
                                        {
                                            min: 21,
                                            max: 50,
                                            color: "#4B0218FF",
                                            label: "21-50",
                                        },
                                        {
                                            min: 11,
                                            max: 20,
                                            color: "#800026",
                                            label: "11-20",
                                        },
                                        {
                                            min: 7,
                                            max: 10,
                                            color: "#BD0026",
                                            label: "7-10",
                                        },
                                        {
                                            min: 4,
                                            max: 6,
                                            color: "#FD8D3C",
                                            label: "4-6",
                                        },
                                        {
                                            min: 2,
                                            max: 3,
                                            color: "#FECC5C",
                                            label: "2-3",
                                        },
                                        {
                                            min: 1,
                                            max: 1,
                                            color: "#FFFFB2",
                                            label: "1",
                                        },
                                    ],
                                    type: "piecewise",
                                    orient: "horizontal",
                                    left: "center",
                                    top: 40,
                                },
                                calendar: {
                                    top: 100,
                                    left: 30,
                                    right: 30,
                                    cellSize: ["auto", 20],
                                    range: [
                                        new Date(
                                            new Date().getUTCFullYear(),
                                            new Date().getUTCMonth() - 6,
                                            1
                                        )
                                            .toISOString()
                                            .slice(0, 9),
                                        new Date().toISOString().slice(0, 9),
                                    ],
                                    splitLine: {
                                        show: true,
                                    },
                                },

                                series: [
                                    {
                                        type: "heatmap",
                                        coordinateSystem: "calendar",
                                        data: heatMapDataUsers,
                                    },
                                ],
                            }}
                            style={{ width: "100%", height: 300 }}
                        />
                    </div>{" "}
                </Grid>
            </Grid>
            <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={2}
            >
                <Box>
                    <Typography variant="caption" sx={{ opacity: "0%" }}>
                        {"."}
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ height: ITEM_HEIGHT }}
                    >
                        <Checkbox
                            checked={checkboxExcludeApp}
                            onChange={(e) =>
                                setCheckboxExcludeApp(e.target.checked)
                            }
                            // sx={{ height: ITEM_HEIGHT }}
                        />
                        <Typography>Exclude /app?</Typography>
                    </Box>
                </Box>
            </Stack>
            <div style={{ marginTop: 40 }}>
                <EChartsReact
                    option={{
                        title: {
                            text: "URLs frequency in the time range",
                            left: "center",
                        },
                        tooltip: {
                            trigger: "axis",
                            formatter: (params) => {
                                // params es un array de puntos en la misma categoría
                                // Pero aquí solo hay un 'series' configurado
                                if (params.length) {
                                    const [{ name, value }] = params;
                                    return `URL: ${name} <br/>Visitas: ${value}`;
                                }
                                return "";
                            },
                        },
                        xAxis: {
                            type: "category",
                            data: URLsFrecuency.map((item) => item.url),
                            axisLabel: {
                                rotate: 45, // rotamos labels si son muy largos
                                fontSize: 10,
                            },
                        },
                        yAxis: {
                            type: "value",
                            name: "Visitas",
                        },
                        series: [
                            {
                                name: "Visitas",
                                type: "bar",
                                data: URLsFrecuency.map((item) => item.count),
                                itemStyle: {
                                    color: "#0071bc",
                                },
                            },
                        ],
                    }}
                    style={{ width: "100%", height: 400 }}
                />
            </div>
            <UserConnectionsTable data={data} />
            <SitesUserActivity data={data} />
        </Container>
    );
};

export default UsersActivityScreen;
