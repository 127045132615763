import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Grid,
    Skeleton,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import EChartsReact from "echarts-for-react";

import ProgressBarSteps from "../shared/ProgressBarSteps";
import WrapperPDFEChart from "../shared/WrapperPDFEChart";
import WrapperPDFTable from "../shared/WrapperPDFTable";

import SmallPercentage from "../SmallPercentage";
import InfoIcon from "../InfoIcon";
import ErrorIcon from "../ErrorIcon";
import DateSelector from "../DateSelector";
import SwitchIcons, { LINE_MODE, BAR_MODE } from "./SwitchIcons";

import { HOTELS_INFO } from "../../configuration";

import { tooltip } from "../../helpers/eCharts";
import { months, getYearsBefore, xAxisYearMonth } from "../../helpers/dates";
import { fns } from "../../helpers/common";
import { reservationsToYearMonth } from "../DataLoader";
import dayjs from "dayjs";

const BoldTableCell = styled(TableCell)({ fontWeight: "bold" });

const CalcPercentage = (year, data, key, context, formatter) => {
    const actualYear = new Date().getUTCFullYear();
    if (year === actualYear) return;

    const actual = (data[actualYear] ?? {})[key] ?? 0;
    const old = (data[year] ?? {})[key] ?? 0;

    return (
        <SmallPercentage
            actual={actual}
            old={old}
            context={context}
            formatter={formatter}
        />
    );
};

const VouchersReport = ({ hotelID, width = "40em", height = "280%" }) => {
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const REPORT_DESC = "Vouchers revenue and number of vouchers sold";
    const TITLE = "Vouchers";
    const HEADER = [
        {
            id: "voucherRevenue",
            label: "Voucher Revenue Gross",
            format: { left: "currency" },
            yAxisIndex: 0,
        },
        {
            id: "voucherRevenueNett",
            label: "Voucher Revenue Nett",
            format: { left: "currency" },
            yAxisIndex: 0,
        },
        {
            id: "vouchersSold",
            label: "Vouchers Sold",
            yAxisIndex: 1,
        },
    ];

    const { dataBookings } = useSelector((state) => state);

    const [time, setTime] = useState(
        new Date(new Date().getUTCFullYear(), 0, 1)
    );
    const [fullYear, setFullYear] = useState(true);
    const [displayData, setDisplayData] = useState({});
    const [connectionError, setConnectionError] = useState(false);

    const [graphMode, setGraphMode] = useState(LINE_MODE);

    const [dataByYear, setDataByYear] = useState({});
    const [dataByJanuary, setDataByJanuary] = useState({});
    const [dataByFebruary, setDataByFebruary] = useState({});
    const [dataByMarch, setDataByMarch] = useState({});
    const [dataByApril, setDataByApril] = useState({});
    const [dataByMay, setDataByMay] = useState({});
    const [dataByJune, setDataByJune] = useState({});
    const [dataByJuly, setDataByJuly] = useState({});
    const [dataByAugust, setDataByAugust] = useState({});
    const [dataBySeptember, setDataBySeptember] = useState({});
    const [dataByOctober, setDataByOctober] = useState({});
    const [dataByNovember, setDataByNovember] = useState({});
    const [dataByDecember, setDataByDecember] = useState({});
    const monthsData = [
        dataByJanuary,
        dataByFebruary,
        dataByMarch,
        dataByApril,
        dataByMay,
        dataByJune,
        dataByJuly,
        dataByAugust,
        dataBySeptember,
        dataByOctober,
        dataByNovember,
        dataByDecember,
    ];
    const setMonthsData = [
        setDataByJanuary,
        setDataByFebruary,
        setDataByMarch,
        setDataByApril,
        setDataByMay,
        setDataByJune,
        setDataByJuly,
        setDataByAugust,
        setDataBySeptember,
        setDataByOctober,
        setDataByNovember,
        setDataByDecember,
    ];

    const formatValueUnitAndCommas = (prop, value) => {
        const unit = HEADER.find((e) => e.id === prop).unit ?? "";
        const left_unit = HEADER.find((e) => e.id === prop).left_unit ?? "";
        const valueString = `${value.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        })}`;
        return left_unit + valueString + unit;
    };

    const process = (data) => {
        let returnData = {
            voucherRevenue: 0,
            voucherRevenueNett: 0,
            vouchersSold: 0,
        };
        const breakfast = (HOTELS_INFO[hotelID] ?? {}).breakfast ?? 0;
        const tax = (HOTELS_INFO[hotelID] ?? {}).tax ?? 1;
        data.forEach((element) => {
            if (element.Type === "Voucher") {
                returnData.voucherRevenue += element.TotalPrice;
                returnData.voucherRevenueNett +=
                    element.TotalPrice * tax - breakfast;
                returnData.vouchersSold += element.VoucherCount;
            }
        });
        return returnData;
    };

    useEffect(() => {
        if (dataBookings.status === "error") setConnectionError(true);
        if (dataBookings.status === "loaded") {
            setDisplayData([]);

            // Get array of all years to n years before
            let years = getYearsBefore();
            let aux_data = reservationsToYearMonth(
                dataBookings.data,
                "ResDate"
            );
            months.map((month, index) => {
                setMonthsData[index](
                    Object.assign(
                        {},
                        ...years.map((year) => ({
                            [year]: process(
                                (
                                    (aux_data[`${year}`] ?? {})[month] ?? []
                                ).filter((element) =>
                                    ["Voucher"].includes(element.Type)
                                )
                            ),
                        }))
                    )
                );
                return "";
            });

            setDataByYear(
                Object.assign(
                    {},
                    ...years.map((year) => ({
                        [year]: process(
                            Object.values(aux_data[`${year}`] ?? {})
                                .flat(1)
                                .filter((element) =>
                                    ["Voucher"].includes(element.Type)
                                )
                        ),
                    }))
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataBookings]);

    //      _ _           _
    //     | (_)         | |
    //   __| |_ ___ _ __ | | __ _ _   _
    //  / _` | / __| '_ \| |/ _` | | | |
    // | (_| | \__ \ |_) | | (_| | |_| |
    //  \__,_|_|___/ .__/|_|\__,_|\__, |
    //             | |             __/ |
    //             |_|            |___/
    useEffect(() => {
        if (
            Object.entries(dataByYear).length > 0 &&
            Object.entries(monthsData).every((arr) => arr.length > 0)
        ) {
            let auxDisplayData = fullYear
                ? dataByYear
                : monthsData[time.getUTCMonth()];

            setDisplayData(auxDisplayData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time, fullYear, ...monthsData, dataByYear]);

    return (
        <>
            <Stack
                direction="column"
                height={"100%"}
                spacing={isMobile ? 0 : 1}
            >
                <Stack
                    direction={{ md: "row", xs: "column" }}
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent={"center"}
                    >
                        <Typography fontWeight={"bold"}>{TITLE}</Typography>
                        <InfoIcon text={REPORT_DESC} />
                        <ErrorIcon show={connectionError} />
                    </Stack>
                    <Stack
                        direction={{ md: "row", xs: "column" }}
                        spacing={2}
                        alignItems="center"
                    >
                        <DateSelector
                            fullYear={fullYear}
                            setFullYear={setFullYear}
                            time={dayjs(time)}
                            setTime={setTime}
                            day={false}
                        />
                        <SwitchIcons
                            selectedMode={graphMode}
                            modes={[LINE_MODE, BAR_MODE]}
                            setGraphMode={setGraphMode}
                        />
                    </Stack>
                </Stack>

                {Object.entries(displayData).length === 0 ? (
                    <>
                        <br /> <ProgressBarSteps /> <br />
                    </>
                ) : (
                    <></>
                )}

                {/*   ____ _   _    _    ____ _____  */}
                {/*  / ___| | | |  / \  |  _ \_   _| */}
                {/* | |   | |_| | / _ \ | |_) || |   */}
                {/* | |___|  _  |/ ___ \|  _ < | |   */}
                {/*  \____|_| |_/_/   \_\_| \_\|_|   */}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <WrapperPDFTable k={TITLE + " values"}>
                            <Table>
                                {/*  ____   _____        ______   */}
                                {/* |  _ \ / _ \ \      / / ___|  */}
                                {/* | |_) | | | \ \ /\ / /\___ \  */}
                                {/* |  _ <| |_| |\ V  V /  ___) | */}
                                {/* |_| \_\\___/  \_/\_/  |____/  */}
                                <TableBody>
                                    {HEADER.map((head) => (
                                        <TableRow key={head.id}>
                                            <BoldTableCell
                                                sx={{
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {head.label}
                                            </BoldTableCell>
                                            {Object.entries(displayData)
                                                .length > 0 ? (
                                                <TableCell>
                                                    {fns(
                                                        hotelID,
                                                        displayData[
                                                            time.getUTCFullYear()
                                                        ][head.id],
                                                        head.format ?? {}
                                                    )}
                                                    {CalcPercentage(
                                                        time.getUTCFullYear(),
                                                        displayData,
                                                        head.id,
                                                        fullYear
                                                            ? `Comparing ${new Date().getUTCFullYear()} vs ${time.getUTCFullYear()}`
                                                            : `Comparing ${new Date().getUTCFullYear()}-${
                                                                  months[
                                                                      time.getUTCMonth()
                                                                  ]
                                                              } vs ${time.getUTCFullYear()}-${
                                                                  months[
                                                                      time.getUTCMonth()
                                                                  ]
                                                              }`,
                                                        (number) =>
                                                            fns(
                                                                hotelID,
                                                                number,
                                                                head.format ??
                                                                    {}
                                                            )
                                                    )}
                                                </TableCell>
                                            ) : (
                                                <TableCell>
                                                    <Skeleton width={100} />
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </WrapperPDFTable>
                    </Grid>

                    <Grid item xs={12} md={9}>
                        {Object.entries(displayData).length > 0 ? (
                            <WrapperPDFEChart k={TITLE}>
                                <EChartsReact
                                    option={{
                                        animation: true,
                                        // grid: {
                                        //     top: 10,
                                        //     left: 20,
                                        //     right: 5,
                                        //     bottom: 25,
                                        // },
                                        legend: {
                                            itemGap: isMobile ? 5 : 10,
                                        },
                                        tooltip: tooltip,

                                        dataZoom: [
                                            {
                                                type: "inside",
                                                startValue: `${new Date(
                                                    new Date().getUTCFullYear(),
                                                    0,
                                                    1
                                                )
                                                    .toISOString()
                                                    .slice(0, 7)}`,
                                                endValue: `${new Date(
                                                    new Date().getUTCFullYear() +
                                                        1,
                                                    0,
                                                    0
                                                )
                                                    .toISOString()
                                                    .slice(0, 7)}`,
                                            },
                                            {
                                                start: 0,
                                                end: 10,
                                            },
                                        ],
                                        xAxis: {
                                            type: "category",
                                            data: xAxisYearMonth(),
                                            axisLabel: {
                                                formatter: (v) => {
                                                    return months[
                                                        Number(v.slice(-2)) - 1
                                                    ].slice(0, 3);
                                                },
                                            },
                                        },
                                        yAxis: [
                                            {
                                                axisLabel: {
                                                    formatter: (value) => {
                                                        if (value >= 1000) {
                                                            return (
                                                                (
                                                                    value / 1000
                                                                ).toFixed(1) +
                                                                "K"
                                                            );
                                                        } else {
                                                            return value;
                                                        }
                                                    },
                                                    rotate: isMobile ? 55 : 0,
                                                },
                                            },
                                            {},
                                        ],
                                        series: HEADER.map((head) => ({
                                            type: graphMode.type,
                                            smooth: true,
                                            name: head.label,
                                            // symbol: "none",
                                            yAxisIndex: head.yAxisIndex ?? 1,
                                            data: getYearsBefore()
                                                .reverse()
                                                .map((year) =>
                                                    monthsData.map((monthD) =>
                                                        (
                                                            monthD[year][
                                                                head.id
                                                            ] ?? 0
                                                        ).toFixed(2)
                                                    )
                                                )
                                                .flat(1),
                                        })),
                                    }}
                                />
                            </WrapperPDFEChart>
                        ) : (
                            <Skeleton
                                variant="rounded"
                                height={300}
                                sx={{
                                    marginLeft: isMobile ? -2 : "auto",
                                    marginRight: isMobile ? -2 : 2,
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
};

export default VouchersReport;
