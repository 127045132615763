import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box, Typography, Stack, IconButton, Popover } from "@mui/material";
import Grid from "@mui/material/Grid2";

import axios from "axios";

import EventsModal from "./EventsModal";
import ModalConfirmation from "../ModalConfirmation";

import Colours from "../../helpers/colours";
import Icons from "../../helpers/icons";

import { URLAPI } from "../../configuration";
import { CATEGORY, EVENT_TYPE, OWNER, PRIORITY } from "../../helpers/events";

// Check EventLine to understand how this works

const EventsDetails = ({
    eventDetails = {},
    anchorClick,
    onCloseDetails,
    handleRefreshEvents,
    hotelID,
}) => {
    const { auth } = useSelector((state) => state);

    const { user } = useSelector((state) => state.auth);
    const [userDetails, setUserDetails] = useState({
        name: " ",
        full: " ",
        user_id: 0,
    });

    useEffect(() => {
        if (user) {
            if (user.firstName === "")
                setUserDetails({
                    name: user.user.split("@")[0],
                    user_id: user.user_id,
                });
            else
                setUserDetails({
                    name: user.firstName,
                    full: `${user.firstName} ${user.lastName}`,
                    user_id: user.user_id,
                });
        }
    }, [user]);

    const modalStyle = {
        width: 400,
        bgcolor: "background.paper",
        borderRadius: "10px",
        boxShadow: 24,
        p: 2,
        left: 10,
        zIndex: 1,
    };

    const handleDelete = async () => {
        onCloseDetails(); // Close the modal

        try {
            const response = await axios({
                method: "DELETE",
                url: `${URLAPI}/event/${eventDetails.EventID}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.user.jwt}`,
                },
            });

            if (response.status !== 200) {
                console.error("Request failed:", response.statusText);
                return;
            }

            if (response.status === 200) {
                handleRefreshEvents();
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleExclude = async (refresh = true) => {
        onCloseDetails(); // Close the modal

        try {
            let splittedRuleExclude = eventDetails.RepeatRule.split(" ");
            let excludeDates = [eventDetails.FromDateString];
            if (splittedRuleExclude.length === 2) {
                splittedRuleExclude[1]
                    .slice(8)
                    .split(",")
                    .forEach((date) => {
                        excludeDates.push(date);
                    });
            }
            let newRepeatRule =
                splittedRuleExclude[0] + ` exclude:${excludeDates.join(",")}`;

            let payload = {
                UpdatedBy: userDetails.user_id,
                RepeatRule: newRepeatRule,
            };

            const response = await axios({
                url: `${URLAPI}/event/${eventDetails.EventID}`,
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.user.jwt}`,
                },
                data: payload,
            });

            if (response.status !== 200) {
                console.error("Request failed:", response.statusText);
                return;
            }

            if (response.status === 200 && refresh) {
                handleRefreshEvents();
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const [deleteAnswer, setDeleteAnswer] = useState("");
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

    useEffect(() => {
        if (deleteAnswer === "series") {
            handleDelete();
        } else if (deleteAnswer === "event") {
            handleExclude();
        }
    }, [deleteAnswer]);

    const [isOpen, setIsOpen] = useState(true);
    const handleClose = () => {
        onCloseDetails();
        setIsOpen(false);
    };

    return (
        <>
            <Popover
                open={isOpen}
                anchorEl={anchorClick}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Box sx={modalStyle}>
                    <Grid container spacing={2}>
                        <Grid
                            xs={1}
                            sx={{
                                backgroundColor: `${
                                    eventDetails.ColourLightMode
                                        ? eventDetails.ColourLightMode
                                        : Colours.primary
                                }`,
                            }}
                        ></Grid>
                        <Grid xs={11}>
                            <Stack spacing={1}>
                                <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                >
                                    <Typography variant="h5">
                                        {eventDetails.Name}
                                    </Typography>
                                    <Stack direction={"row"}>
                                        <EventsModal
                                            editEvent={true}
                                            eventDetails={eventDetails}
                                            handleRefreshEvents={
                                                handleRefreshEvents
                                            }
                                            handleExclude={handleExclude}
                                            hotelID={hotelID}
                                            onClose={handleClose}
                                        />
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                if (eventDetails.RepeatRule) {
                                                    setOpenConfirmationModal(
                                                        true
                                                    );
                                                } else {
                                                    handleDelete();
                                                }
                                            }}
                                        >
                                            <Icons.Delete fontSize="small" />
                                        </IconButton>
                                    </Stack>
                                </Stack>

                                <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                >
                                    <Typography
                                        fontWeight="bold"
                                        variant="subtitle2"
                                    >
                                        Date
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {new Date(
                                            eventDetails.FromDate
                                        ).toDateString()}
                                        {eventDetails.FromDate !==
                                            eventDetails.ToDate &&
                                            ` to ${new Date(
                                                eventDetails.ToDate
                                            ).toDateString()}`}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                >
                                    <Typography
                                        fontWeight="bold"
                                        variant="subtitle2"
                                    >
                                        Category
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {(
                                            CATEGORY[
                                                eventDetails.Category ?? ""
                                            ] ?? {}
                                        ).label ?? "Category unavailable"}
                                        {eventDetails.Subcategory
                                            ? " - " + eventDetails.Subcategory
                                            : ""}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                >
                                    <Typography
                                        fontWeight="bold"
                                        variant="subtitle2"
                                    >
                                        Event type
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {(
                                            EVENT_TYPE[
                                                eventDetails.ActionAlert ?? ""
                                            ] ?? {}
                                        ).label ?? "Type unavailable"}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                >
                                    <Typography
                                        fontWeight="bold"
                                        variant="subtitle2"
                                    >
                                        Owner
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {(OWNER[eventDetails.Owner ?? ""] ?? {})
                                            .label ?? "Owner unavailable"}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction="row"
                                    justifyContent={"space-between"}
                                >
                                    <Typography
                                        fontWeight="bold"
                                        variant="subtitle2"
                                    >
                                        Priority
                                    </Typography>
                                    {typeof eventDetails.Priority ===
                                        "string" &&
                                    eventDetails.Priority.trim().length > 0 ? (
                                        <Stack direction={"row"}>
                                            {PRIORITY[
                                                eventDetails.Priority
                                            ].renderIcon()}

                                            <Typography variant="subtitle2">
                                                {
                                                    PRIORITY[
                                                        eventDetails.Priority
                                                    ].label
                                                }
                                            </Typography>
                                        </Stack>
                                    ) : (
                                        <Typography variant="subtitle2">
                                            Priority unavailable
                                        </Typography>
                                    )}
                                </Stack>

                                <Stack
                                    direction="column"
                                    justifyContent={"start"}
                                >
                                    <Typography
                                        fontWeight="bold"
                                        variant="subtitle2"
                                    >
                                        Description
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        padding={1}
                                        sx={{
                                            whiteSpace: "pre-wrap",
                                            overflowWrap: "break-word",
                                        }}
                                    >
                                        {eventDetails.Description}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>

            <ModalConfirmation
                open={openConfirmationModal}
                setOpen={setOpenConfirmationModal}
                title={"DELETING A RECURRENT EVENT"}
                description={
                    "Do you want to delete the series or just this event?"
                }
                answers={[
                    { title: "Delete Series", value: "series" },
                    { title: "Delete event", value: "event" },
                ]}
                setAnswer={setDeleteAnswer}
            />
        </>
    );
};

export default EventsDetails;
