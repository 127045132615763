// filters.js

import { process } from "./reports";

import { getWeekNumberSince2000, MAX_DATE, MIN_DATE, dateToUTC } from "./dates";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const DATATYPE_RESDATE = "ResDate";
export const DATATYPE_CHECKIN = "CheckIn";
export const DATATYPE_CHECKOUT = "CheckOut";
export const DATATYPE_SEARCHES = "SearchDate";

export const PRESET_YESTERDAY = "Yesterday";
export const PRESET_TODAY = "Today";
export const PRESET_TOMORROW = "Tomorrow";
export const PRESET_MONTH_TO_DATE = "Month to date";
export const PRESET_YEAR_TO_DATE = "Year to date";
export const PRESET_THIS_MONTH = "This month";
export const PRESET_THIS_YEAR = "This year";
export const PRESET_LAST_YEAR = "Last year";
export const PRESET_LAST_7_DAYS = "Last 7 days";
export const PRESET_LAST_30_DAYS = "Last 30 days";
export const PRESET_LAST_FULL_MONTH = "Last month";
export const PRESET_LAST_3_MONTHS = "Last 3 months";
export const PRESET_LAST_6_MONTHS = "Last 6 months";
export const PRESET_LAST_12_MONTHS = "Last 12 months";
export const PRESET_NEXT_MONTH = "Next month";
export const PRESET_NEXT_7_DAYS = "Next 7 days";
export const PRESET_NEXT_30_DAYS = "Next 30 days";
export const PRESET_NEXT_3_MONTHS = "Next 3 months";
export const PRESET_NEXT_6_MONTHS = "Next 6 months";
export const PRESET_NEXT_12_MONTHS = "Next 12 months";
export const PRESET_CUSTOM = "Custom";
export const PRESET_CHOOSE_MONTH = "Choose month";
export const PRESET_ANYTIME = "Anytime";

export const PRESET_BOOKING_GROUP = [
    PRESET_MONTH_TO_DATE,
    PRESET_YEAR_TO_DATE,
    PRESET_LAST_FULL_MONTH,
    PRESET_LAST_YEAR,
    PRESET_CHOOSE_MONTH,
    PRESET_TOMORROW,
    PRESET_YESTERDAY,
    PRESET_LAST_7_DAYS,
    PRESET_LAST_30_DAYS,
    PRESET_LAST_3_MONTHS,
    PRESET_LAST_6_MONTHS,
    PRESET_LAST_12_MONTHS,
    PRESET_NEXT_MONTH,
    PRESET_NEXT_7_DAYS,
    PRESET_NEXT_30_DAYS,
    PRESET_NEXT_3_MONTHS,
    PRESET_NEXT_6_MONTHS,
    PRESET_NEXT_12_MONTHS,
];

export const PRESET_RESDATE_GROUP = [
    PRESET_ANYTIME,
    PRESET_MONTH_TO_DATE,
    PRESET_YEAR_TO_DATE,
    PRESET_LAST_FULL_MONTH,
    PRESET_CHOOSE_MONTH,
    PRESET_YESTERDAY,
    PRESET_LAST_7_DAYS,
    PRESET_LAST_30_DAYS,
    PRESET_LAST_3_MONTHS,
    PRESET_LAST_6_MONTHS,
    PRESET_LAST_12_MONTHS,
];
export const PRESET_RESDATE_CHOOSE_MONTH = "Choose month";
export const PRESET_RESDATE_DEFAULT = PRESET_ANYTIME;

export const PRESET_SELECTED_PERIOD = "Selected period";
export const PRESET_CURRENT_MONTH = "Current month";

export const PRESET_ARRIVAL_DEFAULT = PRESET_ANYTIME;
export const PRESET_ARRIVAL_GROUP = [
    PRESET_ANYTIME,
    PRESET_SELECTED_PERIOD,
    PRESET_NEXT_3_MONTHS,
    PRESET_NEXT_12_MONTHS,
    PRESET_CURRENT_MONTH,
    PRESET_NEXT_MONTH,
    PRESET_CHOOSE_MONTH,
];

//   ____
//  |  _ \ _ __ ___   ___ ___  ___ ___
//  | |_) | '__/ _ \ / __/ _ \/ __/ __|
//  |  __/| | | (_) | (_|  __/\__ \__ \
//  |_|   |_|  \___/ \___\___||___/___/
export const process_ = ({ js, dataBookings, hotelID }) => {
    let dataToUse = dataBookings.data ?? [];

    // drop out dates; and countries, rooms and offers
    const {
        fromDay,
        toDay,
        fromArrivalDay,
        toArrivalDay,
        fromResDateDay,
        toResDateDay,
    } = js;

    const {
        Countries = [],
        Rooms = [],
        Offers = [],
        Types = [],
        Extras = [],
        VoucherNames = [],
        Cancellations = "included",
        Comparison = DEFAULT_COMPARISON, // previousYear, previousMonth or previousWeek
    } = js.filters ?? {};

    let fromDayPrevious = new Date(fromDay);
    let toDayPrevious = new Date(toDay);
    let fromDayPreviousBookings = MIN_DATE;
    let toDayPreviousBookings = MAX_DATE;
    let fromDayPreviousArrivals = MIN_DATE;
    let toDayPreviousArrivals = MAX_DATE;

    // Arrivals
    fromDayPreviousArrivals = new Date(fromArrivalDay);
    fromDayPreviousArrivals.setFullYear(
        fromDayPreviousArrivals.getFullYear() - 1
    );
    toDayPreviousArrivals = new Date(toArrivalDay);
    toDayPreviousArrivals.setFullYear(toDayPreviousArrivals.getFullYear() - 1);

    // Bookings
    fromDayPreviousBookings = MIN_DATE;
    toDayPreviousBookings = MAX_DATE;

    if (Comparison === COMPARISON_PREVIOUS_YEAR) {
        // Normal
        fromDayPrevious.setFullYear(fromDayPrevious.getFullYear() - 1);
        toDayPrevious.setFullYear(toDayPrevious.getFullYear() - 1);
    } else if (Comparison === COMPARISON_PREVIOUS_MONTH) {
        fromDayPrevious.setMonth(fromDayPrevious.getMonth() - 1);
        toDayPrevious.setMonth(toDayPrevious.getMonth() - 1);
    } else if (Comparison === COMPARISON_PREVIOUS_WEEK) {
        fromDayPrevious.setDate(fromDayPrevious.getDate() - 7);
        toDayPrevious.setDate(toDayPrevious.getDate() - 7);
    } else if (Comparison === COMPARISON_CUSTOM) {
        fromDayPrevious = new Date(js.filters.ComparisonCustom.from);
        toDayPrevious = new Date(js.filters.ComparisonCustom.to);
    } else if (Comparison === COMPARISON_SAME_TIME_LAST_YEAR) {
        // Normal
        fromDayPrevious.setFullYear(fromDayPrevious.getFullYear() - 1);
        toDayPrevious.setFullYear(toDayPrevious.getFullYear() - 1);

        // ResDate - Bookings
        fromDayPreviousBookings = new Date(fromResDateDay);
        fromDayPreviousBookings.setFullYear(
            fromDayPreviousBookings.getFullYear() - 1
        );
        toDayPreviousBookings = new Date();
        toDayPreviousBookings.setFullYear(
            toDayPreviousBookings.getFullYear() - 1
        );
    }

    fromDayPrevious = formatDate(fromDayPrevious);
    toDayPrevious = formatDate(toDayPrevious);

    // decides if the element must be included according to the Cancellation filter
    const FilterCancellations = (element) =>
        CANCELLATIONS_FILTER[Cancellations].includeCancellation(element);

    const FilterData = (
        data,
        from_,
        to_,
        fromArrival_ = MIN_DATE,
        toArrival_ = MAX_DATE,
        fromBooking_ = MIN_DATE,
        toBooking_ = MAX_DATE,
        extras = {}
    ) => {
        let toPlusOneDay = new Date(to_); //To is included, so we need the following day
        toPlusOneDay.setDate(toPlusOneDay.getDate() + 1);

        let toArrivalPlusOneDay = new Date(toArrival_);
        toArrivalPlusOneDay.setDate(toArrivalPlusOneDay.getDate() + 1);

        let toBookingPlusOneDay = new Date(toBooking_);
        toBookingPlusOneDay.setDate(toBookingPlusOneDay.getDate() + 1);

        let aux = data.filter((e) => {
            let date = new Date(
                helperType(js.dataType, e.ResDate, e.CheckOut, e.CheckIn)
            );

            let isVoucher = e.Type === "Voucher";

            let arrivalDate = new Date(e.CheckIn);
            let bookingDate = new Date(e.ResDate);

            let filteredCancellation = FilterCancellations(e);

            let isCountry = true;
            let isOffer = true;
            let isRoom = true;
            let isType = true;
            let isExtras = true;
            let isVoucherName = true;

            const checkFilter = (array, item) => {
                if (array.length > 0) {
                    const exclude = array.some((i) => i.startsWith("!"));
                    if (exclude) {
                        return !array.some((i) => item === i.slice(1));
                    } else {
                        return array.includes(item);
                    }
                }
                return true;
            };

            if (Countries.length > 0) {
                isCountry = checkFilter(Countries, e.Country);
            }
            if (Offers.length > 0) {
                isOffer = checkFilter(Offers, e.OfferTitle);
            }
            if (Rooms.length > 0) {
                isRoom = checkFilter(Rooms, e.RoomName);
            }
            if (Types.length > 0) {
                isType = checkFilter(Types, e.Type);
            }
            if (Extras.length > 0) {
                const negativeFilters = Extras.filter((f) =>
                    f.startsWith("!")
                ).map((f) => f.slice(1)); // ["Spa", "Gym"] etc.

                const positiveFilters = Extras.filter(
                    (f) => !f.startsWith("!")
                );

                const passesNegativeFilter = (e.Extras ?? []).every(
                    (extra) => !negativeFilters.includes(extra.Description)
                );

                const passesPositiveFilter = (e.Extras ?? []).some((extra) =>
                    positiveFilters.includes(extra.Description)
                );

                isExtras = passesNegativeFilter && passesPositiveFilter;
            }

            if (VoucherNames.length > 0) {
                isVoucherName = checkFilter(VoucherNames, e.VoucherName);
            }

            if (isVoucher) {
                // Vouchers usually have same CheckIn, CheckOut y ResDate
                return (
                    new Date(from_) <= date &&
                    date < toPlusOneDay &&
                    filteredCancellation &&
                    isCountry &&
                    isOffer &&
                    isRoom &&
                    isType &&
                    isExtras &&
                    isVoucherName
                );
            }

            return (
                new Date(fromArrival_) <= arrivalDate &&
                arrivalDate < toArrivalPlusOneDay &&
                new Date(fromBooking_) <= bookingDate &&
                bookingDate < toBookingPlusOneDay &&
                new Date(from_) <= date &&
                date < toPlusOneDay &&
                //
                filteredCancellation &&
                isCountry &&
                isOffer &&
                isRoom &&
                isType &&
                isExtras &&
                isVoucherName
            );
        });

        aux = aux.map((e) => {
            let date = new Date(
                helperType(js.dataType, e.ResDate, e.CheckOut, e.CheckIn)
            );
            return {
                ...e,
                [GROUP_DATE_DAY]: date.getUTCDate(),
                [GROUP_DATE_MONTH]: (date.getUTCMonth() + 1)
                    .toString()
                    .padStart(2, "0"),
                [GROUP_DATE_YEAR]: date.getUTCFullYear(),
                [GROUP_DATE_WEEK]: getWeekNumberSince2000(date),
                selectedDate: formatDate(date),
                ...extras,
            };
        });
        if (js.groupBy?.includes(GROUP_EXTRAS)) {
            let expanded = [];

            aux.forEach((originalBooking) => {
                const bookingExtras = originalBooking.Extras ?? [];
                if (bookingExtras.length > 0) {
                    bookingExtras.forEach((ex) => {
                        let newBooking = {
                            ...originalBooking,
                            [GROUP_EXTRAS]: ex.Description, // TODO: should we change this?
                            // Ajustamos los valores de revenue para que en esta fila
                            // solo cuente el coste de ese extra
                            // TotalPrice: ex.totalCost,
                            revenueSelectedExtra: ex.TotalCost,
                            // RoomRevenue: 0,
                        };
                        expanded.push(newBooking);
                    });
                } else {
                    // expanded.push({ // TODO: should we add this?
                    //     ...originalBooking,
                    //     [GROUP_EXTRAS]: "No extras", // until here
                    //     TotalPrice: 0,
                    //     ExtrasCharge: 0,
                    //     RoomRevenue: 0,
                    // });
                }
            });
            aux = expanded;
        }

        return aux;
    };

    let dataToUseLastYear = FilterData(
        dataToUse,
        fromDayPrevious,
        toDayPrevious,
        fromDayPreviousArrivals,
        toDayPreviousArrivals,
        fromDayPreviousBookings,
        toDayPreviousBookings,
        { period: "lastYear" }
    );
    dataToUse = FilterData(
        dataToUse,
        fromDay,
        toDay,
        fromArrivalDay,
        toArrivalDay,
        fromResDateDay,
        toResDateDay,
        { period: "thisYear" }
    );

    const LENGTH = dataToUse.length;

    // group values by types
    let keys_groups = [...(js.groupBy ?? [])]; // ...timeGroups];

    // Fix case when no keys because it is voucher type
    dataToUse.forEach((obj) => {
        if (obj.Type === "Voucher") {
            keys_groups.forEach((key) => {
                if (obj[key] === null) {
                    obj[key] = "Voucher";
                }
            });
        }
    });
    dataToUseLastYear.forEach((obj) => {
        if (obj.Type === "Voucher") {
            keys_groups.forEach((key) => {
                if (obj[key] === null) {
                    obj[key] = "Voucher";
                }
            });
        }
    });

    // const areKeysTimeBased = keys_groups.every((key) =>
    //     [GROUP_DATE_MONTH, GROUP_DATE_YEAR, GROUP_DATE_DAY].includes(key)
    // );

    // if (Comparison === DEFAULT_COMPARISON) {
    //     fromDayPrevious.setFullYear(fromDayPrevious.getFullYear() - 1);
    //     toDayPrevious.setFullYear(toDayPrevious.getFullYear() - 1);
    // } else if (Comparison === "previousMonth") {
    //     fromDayPrevious.setMonth(fromDayPrevious.getMonth() - 1);
    //     toDayPrevious.setMonth(toDayPrevious.getMonth() - 1);
    // }

    let groups = groupBy(dataToUse, ...keys_groups);
    let groupsLastYear = groupBy(
        dataToUseLastYear.map((e) => {
            let date = new Date(
                e[GROUP_DATE_YEAR],
                Number(e[GROUP_DATE_MONTH]) - 1,
                1
            );
            let weekN = getWeekNumberSince2000(e.selectedDate);
            if (
                Comparison === COMPARISON_PREVIOUS_YEAR ||
                Comparison === COMPARISON_SAME_TIME_LAST_YEAR
            ) {
                date.setFullYear(date.getFullYear() + 1);
                weekN += 52;
            } else if (Comparison === COMPARISON_PREVIOUS_MONTH) {
                date.setMonth(date.getMonth() + 1);
            } else if (Comparison === COMPARISON_PREVIOUS_WEEK) {
                date.setDate(date.getDate() + 7);
                weekN += 1;
            }

            let e_ = {
                ...e,
                [GROUP_DATE_YEAR]: date.getFullYear(),
                [GROUP_DATE_MONTH]: (date.getMonth() + 1)
                    .toString()
                    .padStart(2, "0"),
                [GROUP_DATE_WEEK]: weekN,
            };
            return e_;
        }),
        ...keys_groups
    );

    // const groupsSet = areKeysTimeBased
    //     ? new Set(Object.keys({ ...groups }))
    //     : new Set(Object.keys({ ...groups, ...groupsLastYear }));
    const groupsSet = new Set(Object.keys({ ...groups, ...groupsLastYear }));

    let entries = Array.from(groupsSet)
        .map((k) => {
            const dataThisYear = groups[k] ?? [];
            const dataLastYear = groupsLastYear[k] ?? [];

            const result =
                dataThisYear.length > 0 ? process(dataThisYear, hotelID) : {};
            const resultOneYearBefore =
                dataLastYear.length > 0 ? process(dataLastYear, hotelID) : {};

            if (js.groupBy?.includes(GROUP_EXTRAS)) {
                result.countSelectedExtra = dataThisYear.length;
                resultOneYearBefore.countSelectedExtra = dataLastYear.length;
            }

            return {
                [k]: {
                    result,
                    resultOneYearBefore,
                    keys: keys_groups.map((key) => [
                        key,
                        groups[k]
                            ? groups[k][0][key]
                            : groupsLastYear[k][0][key],
                    ]),
                    data: [...(groups[k] || []), ...(groupsLastYear[k] || [])],
                },
            };
        })
        .reduce((newObj, v) => {
            return { ...newObj, ...v };
        }, {});

    return { entries, len: LENGTH };
};

export const formatDate = (date) => date.toISOString().slice(0, 10);

export const getDatesBetween = (startDateStr, endDateStr) => {
    let startDate = new Date(startDateStr);
    let endDate = new Date(endDateStr);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    if (startDate > endDate) {
        [startDate, endDate] = [endDate, startDate];
    }

    const dateArray = [];
    let currentDate = new Date(startDate.getTime());

    while (currentDate <= endDate) {
        dateArray.push(formatDate(currentDate));
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
        currentDate.setHours(0, 0, 0, 0);
    }

    return dateArray;
};

function newDateUTC(year, month, day) {
    return dayjs.utc().year(year).month(month).date(day).startOf("day");
}
//   ____  ____  _____ ____  _____ _____   ____    _  _____ _____ ____
//  |  _ \|  _ \| ____/ ___|| ____|_   _| |  _ \  / \|_   _| ____/ ___|
//  | |_) | |_) |  _| \___ \|  _|   | |   | | | |/ _ \ | | |  _| \___ \
//  |  __/|  _ <| |___ ___) | |___  | |   | |_| / ___ \| | | |___ ___) |
//  |_|   |_| \_\_____|____/|_____| |_|   |____/_/   \_\_| |_____|____/
export const PresetDates = (type, aux = {}) => {
    let fromDay = dayjs.utc();
    let toDay = dayjs.utc();

    const {
        month = dayjs.utc().month(),
        year = dayjs.utc().year(),
        day = dayjs.utc().date(),
        js = { fromDay, toDay },
    } = aux;

    switch (type) {
        case PRESET_CHOOSE_MONTH:
            fromDay = fromDay.year(year).month(month).date(1);
            toDay = fromDay.endOf("month");
            break;

        case PRESET_SELECTED_PERIOD:
            // Custom date range from `js.fromDay` to `js.toDay`
            fromDay = dayjs.utc(js.fromDay);
            toDay = dayjs.utc(js.toDay);
            break;

        case PRESET_LAST_12_MONTHS:
            // Range covering the last 12 months from today
            fromDay = fromDay.subtract(1, "year").subtract(1, "day");
            toDay = toDay.subtract(1, "day");
            break;

        case PRESET_LAST_6_MONTHS:
            // Range covering the last 6 months from today
            fromDay = fromDay.subtract(6, "month").subtract(1, "day");
            toDay = toDay.subtract(1, "day");
            break;

        case PRESET_LAST_3_MONTHS:
            // Range covering the last 3 months from today
            fromDay = fromDay.subtract(3, "month").subtract(1, "day");
            toDay = toDay.subtract(1, "day");
            break;

        case PRESET_LAST_FULL_MONTH:
            // Range covering the last full month
            fromDay = newDateUTC(year, month - 1, 1);
            toDay = newDateUTC(year, month, 0);
            break;

        case PRESET_LAST_30_DAYS:
            // Range covering the last 30 days from today
            fromDay = fromDay.subtract(30, "day");
            toDay = toDay.subtract(1, "day");
            break;

        case PRESET_LAST_7_DAYS:
            // Range covering the last 7 days from today
            fromDay = fromDay.subtract(7, "day");
            toDay = toDay.subtract(1, "day");
            break;

        case PRESET_LAST_YEAR:
            // Range covering the last year
            fromDay = fromDay
                .year(fromDay.year() - 1)
                .month(0)
                .date(1);
            toDay = toDay.year(toDay.year()).month(0).date(0);
            break;

        case PRESET_YEAR_TO_DATE:
            // Range from the start of the current year to the specified day
            if (
                aux.hasOwnProperty("year") &&
                aux.hasOwnProperty("month") &&
                aux.hasOwnProperty("day")
            ) {
                toDay = newDateUTC(year, month, day - 1);
                fromDay = newDateUTC(toDay.year(), 0, 1);
            } else {
                fromDay = newDateUTC(year, 0, 1);
                toDay = newDateUTC(year, month, day);
                if (!fromDay.isSame(toDay, "day")) {
                    toDay = toDay.subtract(1, "day");
                }
            }
            break;

        case PRESET_MONTH_TO_DATE:
            // Range from the start of the current month to today
            fromDay = fromDay.date(1);
            if (!fromDay.isSame(toDay, "day")) {
                toDay = toDay.subtract(1, "day");
            }
            break;

        case PRESET_THIS_YEAR:
            // Range covering this entire year
            fromDay = fromDay.month(0).date(1);
            toDay = toDay
                .year(toDay.year() + 1)
                .month(0)
                .date(0);
            break;

        case PRESET_THIS_MONTH:
            // Range covering this entire month
            fromDay = fromDay.date(1);
            toDay = dayjs
                .utc()
                .year(fromDay.year())
                .month(fromDay.month() + 1)
                .date(1)
                .subtract(1, "day");
            break;

        case PRESET_TODAY:
            // Today's date range
            break;

        case PRESET_YESTERDAY:
            // Yesterday's date range
            fromDay = fromDay.subtract(1, "day");
            toDay = toDay.subtract(1, "day");
            break;

        case PRESET_TOMORROW:
            // Tomorrow's date range
            fromDay = fromDay.add(1, "day");
            toDay = toDay.add(1, "day");
            break;

        case PRESET_NEXT_7_DAYS:
            // Range covering the next 7 days starting tomorrow
            fromDay = fromDay.add(1, "day");
            toDay = toDay.add(7, "days");
            break;

        case PRESET_NEXT_MONTH:
            // Range covering the next month
            fromDay = dayjs
                .utc()
                .year(dayjs.utc().year())
                .month(dayjs.utc().month() + 1)
                .date(1);
            toDay = dayjs
                .utc()
                .year(dayjs.utc().year())
                .month(dayjs.utc().month() + 2)
                .date(0);
            break;

        case PRESET_NEXT_30_DAYS:
            // Range covering the next 30 days starting tomorrow
            fromDay = fromDay.add(1, "day");
            toDay = toDay.add(30, "days");
            break;

        case PRESET_NEXT_3_MONTHS:
            // Range covering the next 3 months starting tomorrow
            fromDay = fromDay.add(1, "day");
            toDay = fromDay.add(3, "months");
            break;

        case PRESET_NEXT_6_MONTHS:
            // Range covering the next 6 months starting tomorrow
            fromDay = fromDay.add(1, "day");
            toDay = fromDay.add(6, "months");
            break;

        case PRESET_NEXT_12_MONTHS:
            // Range covering the next 12 months starting tomorrow
            fromDay = fromDay.add(1, "day");
            toDay = fromDay.add(1, "year");
            break;

        case PRESET_ANYTIME:
            // Anytime range from the earliest to the latest possible dates
            fromDay = MIN_DATE;
            toDay = MAX_DATE;
            break;

        default:
            break;
    }

    return { fromDay: formatDate(fromDay), toDay: formatDate(toDay) };
};

export const GROUP_COUNTRIES = "Country";
export const GROUP_OFFERS = "OfferTitle";
export const GROUP_ROOM = "RoomName";
export const GROUP_TYPE = "Type";
export const GROUP_VOUCHERS = "VoucherName";
export const GROUP_EXTRAS = "ExtrasGroup";

export const GROUP_DATE_YEAR = "Year";
export const GROUP_DATE_MONTH = "Month";
export const GROUP_DATE_DAY = "Day";
export const GROUP_DATE_WEEK = "WeekNumber";

export const GROUPs = [
    GROUP_COUNTRIES,
    GROUP_OFFERS,
    GROUP_ROOM,
    GROUP_TYPE,
    GROUP_VOUCHERS,
    GROUP_DATE_YEAR,
    GROUP_DATE_MONTH,
    GROUP_DATE_DAY,
    GROUP_DATE_WEEK,
    GROUP_EXTRAS,
];

export const helperType = (type, resOption, outOption, inOption) =>
    type === DATATYPE_RESDATE
        ? resOption
        : type === DATATYPE_CHECKOUT
        ? outOption
        : inOption;

export const groupBy = (items, ...keys) => {
    return items.reduce((accumulator, item) => {
        const groupKey = keys.map((key) => item[key]).join("/");

        if (!accumulator[groupKey]) {
            accumulator[groupKey] = [];
        }

        accumulator[groupKey].push(item);

        return accumulator;
    }, {});
};

export const flat = (data) =>
    Object.keys(data)
        .map((ky) => [...Object.values(data[ky])])
        .flat()
        .flat();

export const GB_NotDate = (js) => {
    return (js.groupBy ?? []).filter((e) => {
        return ![
            GROUP_DATE_DAY,
            GROUP_DATE_MONTH,
            GROUP_DATE_WEEK,
            GROUP_DATE_YEAR,
        ].includes(e);
    });
};

export const DISPLAY_ELEMENT_HELPER = {
    // New
    commissionNotCancelled: {
        // short: "Commission NC",
        short: "Commission not cancelled",
        tooltip: "Commission, for not cancelled",
        upside: false,
        format: { maxDecimals: 2 },
        specific: {
            CommissionScreen: "Total",
        },
    },
    commissionCancelled: {
        short: "Commission cancelled",
        tooltip: "Commission, for cancelled",
        upside: false,
        format: { maxDecimals: 2 },
    },
    commissionTotal: {
        short: "Commission",
        tooltip: "Commission",
        upside: false,
        format: { maxDecimals: 2 },
    },
    commissionRoomsNotCancelled: {
        // short: "Commission rooms NC",
        short: "Commission rooms NC",
        tooltip: "Commission rooms, for not cancelled",
        upside: false,
        format: { maxDecimals: 2 },
        specific: {
            CommissionScreen: "Rooms",
        },
    },
    commissionRoomsCancelled: {
        short: "Commission rooms cancelled",
        tooltip: "Commission rooms, for cancelled",
        upside: false,
        format: { maxDecimals: 2 },
    },
    commissionRoomsTotal: {
        short: "Commission rooms",
        tooltip: "Commission rooms",
        upside: false,
        format: { maxDecimals: 2 },
    },

    commissionVouchers: {
        // short: "Commission vouchers",
        short: "Vouchers",
        tooltip: "Commission, for vouchers",
        upside: false,
        format: { maxDecimals: 2 },
    },
    commissionPercentageFromVouchers: {
        short: "Commission % from vouchers",
        tooltip: "Commission %, for vouchers",
        upside: false,
        format: { maxDecimals: 2 },
    },
    commissionPercentageFromRooms: {
        short: "Commission % from rooms",
        tooltip: "Commission %, for rooms",
        upside: false,
        format: { maxDecimals: 2 },
    },

    //
    ABWNotCancelled: {
        short: "ABW NC",
        tooltip: "Average Booking Window, for not cancelled",
        upside: false,
        format: { right: " days", maxDecimals: 2 },
    },
    ABWCancelled: {
        short: "ABW cancelled",
        tooltip: "Average Booking Window, for cancelled bookings",
        upside: true,
        format: { right: " days", maxDecimals: 2 },
    },
    ABWTotal: {
        short: "Avg. booking window (ABW)",
        abbreviation: "ABW",
        tooltip:
            "Average Booking Window Total, for cancelled and not cancelled",
        upside: false,
        format: { right: " days", maxDecimals: 2 },
    },
    // For calculation purposes
    BWTotal: {
        short: "Booking window (BW)",
        abbreviation: "BW",
        tooltip: "Booking Window Total, for cancelled and not cancelled",
        upside: false,
        format: { right: " days", maxDecimals: 2 },
    },
    //
    ALoSNotCancelled: {
        short: "ALoS NC",
        tooltip: "Average Length of Stay, for not cancelled bookings",
        upside: false,
        format: { right: " days", maxDecimals: 2 },
    },
    ALoSCancelled: {
        short: "ALoS cancelled",
        tooltip: "Average Length of Stay, for cancelled bookings",
        upside: false,
        format: { right: " days", maxDecimals: 2 },
    },
    ALoSTotal: {
        short: "Avg. length of stay (ALoS)",
        abbreviation: "ALoS",
        tooltip:
            "Average Length of Stay, for cancelled and not cancelled bookings",
        upside: false,
        format: { right: " days", maxDecimals: 2 },
    },
    ADRNotCancelled: {
        short: "ADR NC",
        tooltip: "Average daily rate, for not cancelled bookings",
        upside: false,
        format: { left: "currency" },
    },
    ADRCancelled: {
        short: "ADR cancelled",
        tooltip: "Average Daily Rate, for cancelled bookings",
        upside: false,
        format: { left: "currency" },
    },
    ADRTotal: {
        short: "Avg. daily rate (ADR)",
        abbreviation: "ADR",
        tooltip: "Average Daily Rate, for cancelled and not cancelled bookings",
        upside: false,
        format: { left: "currency" },
    },
    bookingsNotCancelled: {
        short: "Bookings",
        tooltip: "Number of not cancelled bookings",
        upside: false,
    },
    bookingsCancelled: {
        short: "Cancelled bookings",
        tooltip: "Number of cancelled bookings",
        upside: false,
    },
    bookingsTotal: {
        short: "Bookings (Total)",
        tooltip: "Number of cancelled and not cancelled bookings",
        specific: {
            ArrivalReport: "Arrivals",
        },
        upside: false,
        important: true,
    },
    bookingsRooms: {
        short: "Bookings Rooms",
        specific: {
            // FiltersTableReport: "Bookings rooms",
            ArrivalReport: "Arrivals",
        },
        tooltip: "Number of cancelled and not cancelled bookings rooms only",
        upside: false,
    },
    revenueNotCancelled: {
        short: "Revenue NC",
        tooltip: "Amount of not cancelled revenue",
        upside: false,
        format: { left: "currency" },
    },
    revenueCancelled: {
        short: "Revenue cancelled",
        tooltip: "Amount of cancelled revenue",
        upside: false,
        format: { left: "currency" },
    },
    revenueTotal: {
        short: "Total revenue",
        tooltip: "Amount of cancelled and not cancelled revenue",
        upside: false,
        important: true,
        format: { left: "currency" },
        headerFormat: { right: "currency", brackets: true },
    },
    revenueRoom: {
        short: "Room revenue",
        tooltip: "Amount of cancelled or not cancelled room revenue only",
        upside: false,
        format: { left: "currency" },
        headerFormat: { right: "currency", brackets: true },
    },
    revenueExtras: {
        short: "Extras revenue",
        tooltip: "Amount of cancelled or not cancelled revenue extras",
        upside: false,
        format: { left: "currency" },
        headerFormat: { right: "currency", brackets: true },
    },
    revenueRoomAndExtras: {
        short: "Room revenue + extras",
        tooltip:
            "Amount of cancelled or not cancelled for room revenue + extras",
        upside: false,
        format: { left: "currency" },
        headerFormat: { right: "currency", brackets: true },
        specific: {
            // FiltersTableReport: "Bookings rooms",
            PacingReport: "Total room & extras revenue",
        },
    },
    revenueVouchers: {
        short: "Vouchers revenue",
        tooltip: "Amount of cancelled or not cancelled revenue vouchers",
        upside: false,
        format: { left: "currency" },
        headerFormat: { right: "currency", brackets: true },
        isVoucher: true,
    },
    roomNightsNotCancelled: {
        short: "Room nights NC",
        tooltip: "Number of not cancelled Room Nights",
        upside: false,
    },
    roomNightsCancelled: {
        short: "Room nights cancelled",
        tooltip: "Number of cancelled Room Nights",
        upside: false,
    },
    roomNightsTotal: {
        short: "Room nights",
        tooltip: "Number of cancelled and not cancelled Room Nights",
        upside: false,
        important: true,
    },
    revenueNettNotCancelled: {
        short: "Revenue nett NC",
        tooltip: "Amount of not cancelled revenue (nett)",
        upside: false,
        format: { left: "currency" },
    },
    revenueNettCancelled: {
        short: "Revenue nett cancelled",
        tooltip: "Amount of cancelled revenue (nett)",
        upside: false,
        format: { left: "currency" },
    },
    revenueNettTotal: {
        short: "Revenue nett",
        tooltip: "Amount of cancelled and not cancelled revenue (nett)",
        upside: false,
        format: { left: "currency" },
    },
    cancellationRate: {
        short: "Cancellation rate",
        tooltip: "Cancellation Rate for the given period and group",
        upside: true,
    },
    vouchersCount: {
        short: "Vouchers sold",
        tooltip:
            "Number of Vouchers in total bought, many vouchers could being purchased in the same booking",
        upside: false,
        isVoucher: true,
    },
    vouchersBookings: {
        short: "Vouchers purchases",
        tooltip:
            "Times that vouchers had being bought, a bought could have multiple vouchers",
        upside: false,
        isVoucher: true,
    },
    voucherMin: {
        short: "Voucher min",
        tooltip: "Voucher's minimum Total Price",
        upside: false,
        format: { left: "currency" },
        isVoucher: true,
    },
    voucherMax: {
        short: "Voucher max",
        tooltip: "Voucher's maximum Total Price",
        upside: false,
        format: { left: "currency" },
        isVoucher: true,
    },
    bookingsWithExtras: {
        short: "Bookings with Extras",
        tooltip: "Number of bookings that include Extras",
        upside: false,
    },
    numberOfExtras: {
        short: "Total Extras Purchased",
        tooltip: "Number of Extras through all bookings",
        upside: false,
    },
    extrasByType: {
        short: "Extras Revenue and count by type",
        tooltip: "Extras revenue and cunt by type",
        upside: false,
    },
    countSelectedExtra: {
        short: "Total Extra Purchased",
        tooltip: "If there is a selected extra, number of this extras sold",
        upside: false,
    },
    revenueSelectedExtra: {
        short: "Total Extra Revenue",
        tooltip: "If there is a selected extra, revenue of this extras",
        upside: false,
    },
    fromDate: {},
    toDate: {},
};

export const getMetricName = (metric, place = "default") => {
    const metricInfo = DISPLAY_ELEMENT_HELPER[metric];

    if (!metricInfo) {
        return null;
    }
    if (metricInfo.specific && metricInfo.specific[place]) {
        return metricInfo.specific[place];
    }
    return metricInfo.short;
};

export const CANCELLATIONS_FILTER = {
    included: {
        label: "Included",
        includeCancellation: (element) => true,
        hideKey: "CANCELLATIONS_INCLUDED",
    },
    excluded: {
        label: "Excluded",
        includeCancellation: (element) => !element.CancelStatus,
        hideKey: "CANCELLATIONS_EXCLUDED",
    },
    only: {
        label: "Only",
        includeCancellation: (element) => element.CancelStatus,
        hideKey: "CANCELLATIONS_ONLY",
    },
};

export const COMPARISON_PREVIOUS_YEAR = "previousYear";
export const COMPARISON_PREVIOUS_MONTH = "previousMonth";
export const COMPARISON_PREVIOUS_WEEK = "previousWeek";
export const COMPARISON_SAME_TIME_LAST_YEAR = "sameTimeLastYear";
export const COMPARISON_CUSTOM = "customComparison";

export const DEFAULT_COMPARISON = COMPARISON_PREVIOUS_YEAR;

export const COMPARISON_FILTER = {
    [COMPARISON_PREVIOUS_YEAR]: {
        label: "Previous year",
    },
    [COMPARISON_PREVIOUS_MONTH]: {
        label: "Previous month",
    },
    [COMPARISON_PREVIOUS_WEEK]: {
        label: "Previous week",
    },
    [COMPARISON_SAME_TIME_LAST_YEAR]: {
        label: "Same time last year",
    },
    [COMPARISON_CUSTOM]: {
        label: "Custom Date",
    },
};
