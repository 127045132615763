import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
    Button,
    Box,
    Container,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import Axios from "axios";

import { useSnackbar } from "notistack";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enIE } from "date-fns/locale";

import Icons from "../../helpers/icons";
import { CATEGORY, OWNER, EVENT_TYPE, PRIORITY } from "../../helpers/events";
import { format } from "date-fns";
import { DEFAULT_HOTEL, URLAPI } from "../../configuration";
import Colours, { pastelColours } from "../../helpers/colours";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const REQUIRED = [
    "Description",
    "EventName",
    "StartDate",
    "EndDate",
    "Category",
    "Owner",
    "EventType",
    "Priority",
];

const EmptyRow = {
    EventName: "",
    StartDate: new Date(),
    EndDate: new Date(),
    Description: "",
    Category: "",
    Subcategory: "",
    Owner: "",
    EventType: "",
    Priority: "",
};

const isError = (type, value) =>
    REQUIRED.includes(type) && (value === undefined || value === "");

const submit = async (row, hotelID, auth, rowIndex) => {
    const payload = {
        Name: row.EventName,
        FromDate: row.StartDate.toISOString(),
        ToDate: row.EndDate.toISOString(),
        Category: row.Category,
        Subcategory: row.Subcategory,
        Description: row.Description,
        ActionAlert: row.EventType,
        Owner: row.Owner,
        Priority: row.Priority,
        HotelID: hotelID === "default" ? DEFAULT_HOTEL : hotelID, //DEFAULT_HOTEL : hotelID
        UserID: auth.user.user_id,
        UpdatedBy: auth.user.firstName + " " + auth.user.lastName,

        // UNUSED FIELDS
        TimeZone: "UTC1",
        AllDay: true,
        //Deadline: deadline,
        ColourLightMode:
            (CATEGORY[row.Category] ?? {}).color ?? pastelColours[0],
        // "ColourDarkMode": "",
        //RepeatRule: repeat || null,
        //RepeatEnd: repeat ? repeatEnd : null,
    };

    return Axios({
        method: "post",
        url: `${URLAPI}/event/`,
        data: payload,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.user.jwt}`,
        },
    })
        .then((response) => ({
            status: "success",
            data: response.data,
            rowIndex,
        }))
        .catch((error) => ({ status: "error", error, rowIndex }));
};

const CustomDateButton = ({ date, handleChange, error = false }) => {
    const [open, setOpen] = useState(false);

    const handleKeyDown = (event) => {
        console.log("handleKeyDown ", { event });
        if (event.key === "Enter" || event.key === "ArrowDown") {
            setOpen(true);
        }
    };

    const displayDate = format(
        (date ?? "") === "" ? new Date() : new Date(date),
        "dd/MMM/yyyy"
    );

    const ButtonField = (props) => {
        const { id, InputProps: { ref } = {}, setOpen } = props;

        return (
            <Box
                sx={{
                    width: "100%",
                }}
            >
                <TextField
                    ref={ref}
                    id={id}
                    onClick={() => setOpen?.((prev) => !prev)}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    variant="standard"
                    size="large"
                    sx={{
                        width: "100%",
                        textTransform: "none",
                        color: "black",
                    }}
                    value={displayDate}
                    error={error}
                />
            </Box>
        );
    };

    const ButtonDatePicker = (props) => {
        return (
            <DatePicker
                timezone="UTC"
                open={open}
                value={dayjs(date)}
                onChange={(newDate) => {
                    setOpen(false);
                    handleChange(newDate);
                }}
                onClose={() => setOpen(false)}
                slots={{ ...props.slots, field: ButtonField }}
                slotProps={{ ...props.slotProps, field: { setOpen } }}
                disableOpenPicker
                {...props}
                minDate={dayjs().subtract(5, "year")}
                maxDate={dayjs().add(5, "year")}
            />
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ButtonDatePicker value={dayjs(date)} onChange={handleChange} />
        </LocalizationProvider>
    );
};

const FieldsSelector = (type, value, handleChange, othersInRow) => {
    const CSelect = (options) => (
        <Select
            fullWidth
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            error={isError(type, value)}
            sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                    borderWidth: "2px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.light",
                },
                background: isError(type, value) ? "#FFF7F7" : "#FFFFFF",
            }}
        >
            <MenuItem value="" disabled>
                <em></em>
            </MenuItem>
            {Object.entries(options).map(([key, valueObj]) => (
                <MenuItem key={key} value={key}>
                    {valueObj.label ?? key}
                </MenuItem>
            ))}
        </Select>
    );

    switch (type) {
        case "Priority":
            return CSelect(PRIORITY);
        case "EventType":
            return CSelect(EVENT_TYPE);
        case "Owner":
            return CSelect(OWNER);
        case "Category":
            return CSelect(CATEGORY);
        case "StartDate":
            return (
                <CustomDateButton date={value} handleChange={handleChange} />
            );
        case "EndDate":
            return (
                <CustomDateButton
                    date={value}
                    handleChange={handleChange}
                    error={othersInRow["EndDate"] < othersInRow["StartDate"]}
                />
            );

        default:
            return (
                <TextField
                    multiline
                    variant="standard"
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                    error={isError(type, value)}
                />
            );
    }
};

const EventsAddMultiple = ({ handleRefreshEvents }) => {
    const [rows, setRows] = useState([{ ...EmptyRow }]);
    const auth = useSelector((state) => state.auth);
    const { id: hotelID = "default" } = useSelector((state) => state.hotelID);

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const lastInputRef = useRef(null);

    const validateFields = () => {
        let haveError = false;
        rows.forEach((row) => {
            Object.entries(row).forEach(([type, value]) => {
                if (isError(type, value)) haveError = true;
            });
            if (row["EndDate"] < row["StartDate"]) haveError = true;
        });
        return !haveError;
    };

    const handleSave = (andReturn = false) => {
        if (validateFields()) {
            Promise.allSettled(
                rows.map((row, index) => submit(row, hotelID, auth, index))
            ).then((results) => {
                const successIds = [];
                const errorIds = [];

                results.forEach((result) => {
                    if (result.value.status === "success") {
                        successIds.push(result.value.rowIndex);
                    } else {
                        errorIds.push(result.value.rowIndex);
                    }
                });

                if (errorIds.length > 0) {
                    enqueueSnackbar(
                        "Some of the events were not created.  \nSuccess: " +
                            successIds.toString() +
                            ".  \nFailed: " +
                            errorIds.toString() +
                            ".  \nScreenshot this message and the events form below and contact support",
                        {
                            variant: "error",
                            style: { whiteSpace: "pre-line" },
                        }
                    );
                    handleRefreshEvents("We couldn't create all the events");
                } else {
                    enqueueSnackbar("Events were created.", {
                        variant: "success",
                    });
                    successIds.length === 1
                        ? handleRefreshEvents("Event was created")
                        : handleRefreshEvents("Events were created");
                    setRows([EmptyRow]);
                    if (andReturn) navigate("/app/plus/events");
                }
            });
        } else {
            enqueueSnackbar(
                "Some fields are missing values or End date cannot be lower than Start date",
                {
                    variant: "error",
                }
            );
        }
    };

    const addEmptyRow = (position) => {
        setRows([
            ...rows.slice(0, position),
            { ...EmptyRow },
            ...rows.slice(position),
        ]);
    };
    const deleteRow = (position) => {
        setRows([...rows.slice(0, position), ...rows.slice(position + 1)]);
    };

    useEffect(() => {
        if (lastInputRef.current) {
            lastInputRef.current.focus();
        }
    }, [rows]);

    return (
        <Container maxWidth="false">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography fontWeight={"bold"}>
                                <span style={{ color: Colours.error }}>*</span>
                                Event Name
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"bold"}>
                                <span style={{ color: Colours.error }}>*</span>
                                Start Date
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"bold"}>
                                <span style={{ color: Colours.error }}>*</span>
                                End Date
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"bold"}>
                                <span style={{ color: Colours.error }}>*</span>
                                Description
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"bold"}>
                                <span style={{ color: Colours.error }}>*</span>
                                Category
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"bold"}>
                                Subcategory
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"bold"}>
                                <span style={{ color: Colours.error }}>*</span>
                                Owner
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"bold"}>
                                <span style={{ color: Colours.error }}>*</span>
                                Event Type
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={"bold"}>
                                <span style={{ color: Colours.error }}>*</span>
                                Priority
                            </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            {Object.keys(row).map((key) => (
                                <TableCell key={key}>
                                    {FieldsSelector(
                                        key,
                                        row[key],
                                        (value) => {
                                            const updatedRows = [...rows];
                                            updatedRows[index][key] = value;

                                            setRows(updatedRows);
                                        },
                                        row
                                    )}
                                </TableCell>
                            ))}
                            <TableCell>
                                {rows.length > 1 && (
                                    <IconButton
                                        onClick={() => deleteRow(index)}
                                    >
                                        <Icons.Delete />
                                    </IconButton>
                                )}
                                {index === rows.length - 1 ? (
                                    <IconButton
                                        onClick={() => addEmptyRow(index + 1)}
                                    >
                                        <Icons.Add />
                                    </IconButton>
                                ) : (
                                    <></>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Typography fontSize={"medium"}>
                <span style={{ color: Colours.error }}>*</span> fields are
                mandatory
            </Typography>
            <Stack
                sx={{ pt: 2 }}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Button variant="outlined" onClick={() => handleSave()}>
                    Save
                </Button>
                <Button variant="outlined" onClick={() => handleSave(true)}>
                    Save and return
                </Button>
            </Stack>
        </Container>
    );
};

export default EventsAddMultiple;

export const EventsAddMultipleButton = ({ isMobile = false }) => {
    const navigate = useNavigate();

    return (
        <Button
            variant="contained"
            sx={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid orange",
                borderRadius: 1000,
                "&:hover": {
                    backgroundColor: "orange",
                    color: "white",
                },
            }}
            disableElevation
            onClick={(v) => {
                navigate("addmanyevents");
            }}
        >
            <>
                <Icons.AddMany sx={{ paddingRight: 1 }} />
                {isMobile ? "" : "Add many"}
            </>
        </Button>
    );
};
