import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
    Container,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";

import DataLoader from "../components/DataLoader";
import FiltersReport, { ITEM_HEIGHT } from "../components/FiltersReport";
import ReportBuilder, {
    BREAK_LINE,
    REPORT_CHART_IN_TABLE,
    REPORT_TABLE_METRICS_SUMMARY,
} from "../components/shared/ReportBuilder/ReportBuilder";

import {
    COMPARISON_SAME_TIME_LAST_YEAR,
    DATATYPE_CHECKIN,
    DISPLAY_ELEMENT_HELPER,
    GROUP_DATE_MONTH,
    GROUP_DATE_WEEK,
    GROUP_DATE_YEAR,
    PRESET_ANYTIME,
    PRESET_CHOOSE_MONTH,
    PRESET_CURRENT_MONTH,
    PRESET_NEXT_12_MONTHS,
    PRESET_NEXT_3_MONTHS,
    PRESET_NEXT_MONTH,
    PRESET_MONTH_TO_DATE,
    PRESET_THIS_MONTH,
    PRESET_YEAR_TO_DATE,
    PRESET_LAST_FULL_MONTH,
    PRESET_YESTERDAY,
    PRESET_LAST_7_DAYS,
    PRESET_LAST_30_DAYS,
    PRESET_LAST_3_MONTHS,
    PRESET_LAST_6_MONTHS,
    PRESET_LAST_12_MONTHS,
} from "../helpers/filters";
import dayjs from "dayjs";

const PacingScreen = () => {
    const [modeWeekMonth, setModeWeekMonth] = useState(GROUP_DATE_MONTH); // GROUP_DATE_MONTH or GROUP_DATE_WEEK
    const js = useSelector((state) => state.globalReportFilters);

    return (
        <>
            <DataLoader Reservations />
            <Container maxWidth={false}>
                <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
                    Pacing
                </Typography>
                <Typography variant="subtitle1" color="initial">
                    This report shows the arrivals from a selected booking
                    period.
                </Typography>
                <br />
                <FiltersReport
                    defaultValues={{
                        presetDate: PRESET_NEXT_12_MONTHS,
                        presetArrivalDate: PRESET_ANYTIME,
                        presetResDate: PRESET_MONTH_TO_DATE,
                        filters: {
                            Cancellations: "excluded",
                            Comparison: COMPARISON_SAME_TIME_LAST_YEAR,
                            // Types: ["Special Offer"],
                        },
                        dataType: DATATYPE_CHECKIN,
                    }}
                    otherProps={{
                        TIME_RANGE: {
                            fromDateProps: {
                                value: dayjs(js.fromDay),
                                // maxDate: new Date(),
                            },
                            toDateProps: {
                                value: dayjs(js.toDay),
                                maxDate: dayjs(
                                    new Date(
                                        new Date(js.fromDay).getUTCFullYear(),
                                        new Date(js.fromDay).getUTCMonth() + 12,
                                        new Date(js.fromDay).getUTCDate() + 1
                                    )
                                ),
                            },
                        },
                        TIME_BOOKING_RANGE: {
                            fromDateProps: {
                                value: dayjs(js.fromResDateDay),
                                maxDate: dayjs(),
                            },
                            toDateProps: {
                                value: dayjs(js.toResDateDay),
                                maxDate: dayjs(),
                            },
                        },
                    }}
                    // ArrivalPresetOptions={[
                    //     PRESET_NEXT_12_MONTHS,
                    //     PRESET_NEXT_MONTH,
                    //     PRESET_NEXT_3_MONTHS,
                    //     PRESET_CURRENT_MONTH,
                    //     PRESET_CHOOSE_MONTH,
                    // ]}
                    BookingPresetOptions={[
                        // PRESET_MONTH_TO_DATE,
                        // PRESET_YEAR_TO_DATE,
                        // PRESET_LAST_FULL_MONTH,
                        // PRESET_CHOOSE_MONTH,
                        // PRESET_YESTERDAY,
                        // PRESET_LAST_7_DAYS,
                        // PRESET_LAST_30_DAYS,
                        // PRESET_LAST_3_MONTHS,
                        // PRESET_LAST_6_MONTHS,
                        // PRESET_LAST_12_MONTHS,
                        PRESET_NEXT_12_MONTHS,
                        PRESET_NEXT_MONTH,
                        PRESET_NEXT_3_MONTHS,
                        PRESET_MONTH_TO_DATE,
                        PRESET_THIS_MONTH,
                        PRESET_CHOOSE_MONTH,
                    ]}
                    ResDatePresetOptions={[
                        PRESET_MONTH_TO_DATE,
                        PRESET_YEAR_TO_DATE,
                        PRESET_LAST_FULL_MONTH,
                        PRESET_CHOOSE_MONTH,
                        PRESET_YESTERDAY,
                        PRESET_LAST_7_DAYS,
                        PRESET_LAST_30_DAYS,
                        PRESET_LAST_3_MONTHS,
                        PRESET_LAST_6_MONTHS,
                        PRESET_LAST_12_MONTHS,
                    ]}
                    filtersOrder={[
                        "RESDATE_PERIOD",
                        "TIME_BOOKING_RANGE",
                        // "DATATYPE",
                        "TIME_PERIOD",
                        "TIME_RANGE",
                        "ARRIVAL_PERIOD",
                        "TIME_ARRIVAL_RANGE",
                        "FILTERS",
                        // "CANCELLATIONS",
                        // "COMPARISON",
                        "CUSTOM_FILTERS",
                    ]}
                    hide={[
                        "DATATYPE",
                        "FILTERS_VOUCHERS",
                        "FILTERS_TYPES",
                        "COMPARISON",
                        "CANCELLATIONS_ONLY",
                        "CANCELLATIONS",
                        // "RESDATE_PERIOD",
                        // "TIME_BOOKING_RANGE",
                        "ARRIVAL_PERIOD",
                        "TIME_ARRIVAL_RANGE",
                    ]}
                    PDF
                    rename={{
                        TIME_PERIOD: "Arrival period",
                        TIME_RANGE: "Custom arrival date",
                        TIME_BOOKING_RANGE: "Custom booking date",
                        RESDATE_PERIOD: "Booking period",
                    }}
                    setProcessed={() => {}}
                    CustomFilters={() => (
                        <>
                            <Typography>
                                Display chart
                                {/* Weekly / Monthly */}
                            </Typography>
                            <FormControl>
                                <Select
                                    labelId="weekMonthFilter"
                                    id="weekMonthFilter"
                                    value={modeWeekMonth}
                                    sx={{
                                        height: ITEM_HEIGHT,
                                        width: "140px",
                                    }}
                                    onChange={(event) => {
                                        let value = event.target.value;
                                        console.log({ value });
                                        setModeWeekMonth(value);
                                    }}
                                    size="small"
                                >
                                    <MenuItem value={GROUP_DATE_WEEK}>
                                        Weekly
                                    </MenuItem>
                                    <MenuItem value={GROUP_DATE_MONTH}>
                                        Monthly
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    )}
                />
                <ReportBuilder
                    reports={[
                        {
                            type: REPORT_TABLE_METRICS_SUMMARY,
                            groupBy: [],
                            dataType: DATATYPE_CHECKIN,
                            fieldsOrder: [
                                // "revenueTotal",
                                "revenueRoomAndExtras",
                                "revenueRoom",
                                "revenueExtras",
                                "bookingsRooms",

                                "roomNightsTotal",
                                "ADRTotal",
                                "ABWTotal",
                                "ALoSTotal",
                                // "BWTotal",
                                // "bookingsCancelled",
                            ],
                            hideFields: [
                                ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
                                    (e) =>
                                        ![
                                            // "revenueTotal",
                                            "revenueRoomAndExtras",
                                            "revenueRoom",
                                            "revenueExtras",
                                            "bookingsRooms",
                                            "roomNightsTotal",
                                            "ADRTotal",
                                            "ABWTotal",
                                            "ALoSTotal",
                                            // "BWTotal",
                                            // "bookingsCancelled",
                                        ].includes(e)
                                ),
                            ],
                            PDF: "Metrics",
                            props: { place: "PacingReport" },
                        },
                        { type: BREAK_LINE, quantity: 1 },
                        {
                            type: REPORT_CHART_IN_TABLE,
                            dataType: DATATYPE_CHECKIN,
                            groupBy:
                                modeWeekMonth === GROUP_DATE_WEEK
                                    ? [GROUP_DATE_WEEK]
                                    : [GROUP_DATE_YEAR, GROUP_DATE_MONTH],
                            props: {
                                title: "Arrivals Chart",
                                bookingsText: "arrivals",
                            },
                            // customProcess: ({ js, dataBookings, hotelID }) => {
                            //     let js_ = { ...js };
                            //     js_["dataType"] = DATATYPE_CHECKIN;
                            //     [js_.fromDay, js_.fromResDateDay] = [
                            //         js_.fromArrivalDay,
                            //         js_.fromDay,
                            //     ]; // exchange metrics: from is arrivalDay and bookingDay is from now
                            //     [js_.toDay, js_.toResDateDay] = [
                            //         js_.toArrivalDay,
                            //         js_.toDay,
                            //     ]; // exchange metrics
                            //     return process_({
                            //         js: js_,
                            //         dataBookings,
                            //         hotelID,
                            //     });
                            // },
                        },
                        // {
                        //     type: GRID_HORIZONTAL,
                        //     spacing: 2,
                        //     reports: [
                        //         {
                        //             type: REPORT_CHART_BARS_CATEGORY,
                        //             props: { title: "Top selling rooms" },
                        //             groupBy: [GROUP_ROOM],
                        //             metric: "bookingsTotal",
                        //             metrics: {
                        //                 ...DISPLAY_ELEMENT_HELPER,
                        //                 bookingsTotal: {
                        //                     ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
                        //                     short: "Bookings",
                        //                 },
                        //             },
                        //         },
                        //         {
                        //             type: REPORT_CHART_BARS_CATEGORY,
                        //             props: { title: "Top selling rates" },
                        //             groupBy: [GROUP_OFFERS],
                        //             metric: "bookingsTotal",
                        //             metrics: {
                        //                 ...DISPLAY_ELEMENT_HELPER,
                        //                 bookingsTotal: {
                        //                     ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
                        //                     short: "Bookings",
                        //                 },
                        //             },
                        //         },
                        //     ],
                        // },
                    ]}
                />
            </Container>
            <br />
            <br />
            <br />
        </>
    );
};
export default PacingScreen;
