import { dataCommissionTypes } from "../types/dataCommissionTypes";

export const loading = () => {
    return {
        type: dataCommissionTypes.loading,
    };
};
export const reloading = () => {
    return {
        type: dataCommissionTypes.reloading,
    };
};
export const partialLoad = (data) => {
    return {
        type: dataCommissionTypes.partialLoad,
        payload: data,
    };
};
export const load = (
    data,
    site_names,
    currency_symbols,
    currency_code,
    currencies
) => {
    return {
        type: dataCommissionTypes.load,
        payload: {
            data,
            site_names,
            currency_symbols,
            currency_code,
            currencies,
        },
    };
};
export const error = (error, data) => {
    return {
        type: dataCommissionTypes.error,
        payload: data,
        error: error,
    };
};
export const updateOne = (who, month_v, year_v, obj) => {
    return {
        type: dataCommissionTypes.updateOne,
        payload: {
            who: who,
            month_v: month_v,
            year_v: year_v,
            obj,
        },
    };
};
