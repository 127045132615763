import React, { useEffect, useState } from "react";
import {
    Box,
    InputAdornment,
    TextField,
    Typography,
    styled,
} from "@mui/material";

import {
    LocalizationProvider,
    DatePicker,
    PickersDay,
} from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
// import "dayjs/locale/en-ie";

import Colours from "../helpers/colours";
import { formatDate, getDatesBetween } from "../helpers/filters";
import { MAX_DATE, MIN_DATE } from "../helpers/dates";
import Icons from "../helpers/icons";

dayjs.extend(utc);
dayjs.extend(timezone);
// dayjs.locale("utc");

const HighlightedDay = styled(PickersDay)(({ isSelected, isFromTo }) => ({
    ...(isSelected && {
        backgroundColor: isFromTo
            ? Colours.secondarySoft
            : Colours.tableRowBackground,
    }),
}));

const DefaultBoxProps = {
    sx: {
        height: "auto",
        maxWidth: 370, // TODO: UNDO
        border: 1,
        borderRadius: 1,
        borderColor: Colours.midLightText,

        "& .MuiTextField-root": {
            border: "none",
            "& .MuiOutlinedInput-input": {
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 0,
                marginTop: "4px",
                marginBottom: "4px",
                width: "100%",
            },
            "& fieldset": {
                border: "none",
            },
        },
    },
};

const DefaultFromDateProps = {
    value: dayjs.utc(),
    minDate: dayjs.utc(MIN_DATE),
    maxDate: dayjs.utc(MAX_DATE),
};

const DefaultToDateProps = {
    value: dayjs.utc(),
    maxDate: dayjs.utc(MAX_DATE),
};

const ITEM_HEIGHT = "50px";

const DateRangePicker = ({
    title = "",
    isRangePicker = true, // When set to false, the fromDate props are the one who controls the date picker
    onChangeFromDate,
    onChangeToDate,
    fromDateProps = {},
    toDateProps = {},
    boxProps = { sx: { height: ITEM_HEIGHT } },
    open = false,
}) => {
    const [openFromDatePicker, setOpenFromDatePicker] = useState(false);
    const [openEndDatePicker, setOpenEndDatePicker] = useState(false);

    useEffect(() => {
        setOpenFromDatePicker(open);
    }, [open]);

    const [fromDate, setFromDate] = useState(dayjs.utc());
    const [toDate, setToDate] = useState(dayjs.utc());

    useEffect(() => {
        const date = fromDateProps.hasOwnProperty("value")
            ? fromDateProps.value
            : DefaultFromDateProps.value;
        setFromDate(date);
    }, [fromDateProps]);

    useEffect(() => {
        const date = toDateProps.hasOwnProperty("value")
            ? toDateProps.value
            : DefaultToDateProps.value;
        setToDate(date);
    }, [toDateProps]);

    const renderDay = (day, _selectedDates, pickersDayProps) => {
        const adjustedDay = day.startOf("day");
        const fromDay = fromDate.startOf("day");
        const toDay = toDate.startOf("day");

        const isWithinRange =
            adjustedDay.valueOf() >= fromDay.valueOf() &&
            adjustedDay.valueOf() <= toDay.valueOf();

        const isFromTo =
            adjustedDay.valueOf() === fromDay.valueOf() ||
            adjustedDay.valueOf() === toDay.valueOf();

        return (
            <HighlightedDay
                {...pickersDayProps}
                isSelected={isWithinRange}
                isFromTo={isFromTo}
            />
        );
    };

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            // adapterLocale="en-ie"
            // adapterTimeZone="UTC"
        >
            <Box>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    {title}
                </Typography>

                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        ...DefaultBoxProps.sx,
                        ...(boxProps.sx ?? {}),
                        ...(isRangePicker ? {} : { maxWidth: 200 }),
                    }}
                >
                    {isRangePicker ? (
                        <>
                            <DatePicker
                                format="DD MMM YYYY"
                                timezone="default"
                                open={openFromDatePicker}
                                onOpen={() => setOpenFromDatePicker(true)}
                                onClose={() => {
                                    setOpenFromDatePicker(false);
                                    setOpenEndDatePicker(true);
                                }}
                                value={dayjs.utc(fromDate)}
                                minDate={dayjs.utc(
                                    fromDateProps.minDate ??
                                        DefaultFromDateProps.minDate
                                )}
                                maxDate={dayjs.utc(
                                    fromDateProps.maxDate ??
                                        DefaultFromDateProps.maxDate
                                )}
                                onChange={(newValue) => {
                                    const utcDate = newValue.startOf("day");
                                    setFromDate(utcDate);
                                    onChangeFromDate?.(utcDate);
                                }}
                                slotProps={{
                                    popper: {
                                        keepMounted: true,
                                    },
                                    textField: {
                                        onClick: () =>
                                            setOpenFromDatePicker(true),
                                        InputProps: {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Icons.Calendar />
                                                </InputAdornment>
                                            ),

                                            endAdornment: null,
                                        },
                                    },
                                }}
                                renderDay={renderDay}
                            />
                            <Typography
                                variant="body2"
                                sx={{ margin: "0 8px" }}
                            >
                                –
                            </Typography>
                            <DatePicker
                                format="DD MMM YYYY"
                                timezone="default"
                                open={openEndDatePicker}
                                onOpen={() => setOpenEndDatePicker(true)}
                                onClose={() => setOpenEndDatePicker(false)}
                                disableOpenPicker
                                value={dayjs.utc(toDate)}
                                minDate={fromDate}
                                maxDate={dayjs(
                                    toDateProps.maxDate ??
                                        DefaultToDateProps.maxDate
                                )}
                                onChange={(newValue) => {
                                    const utcDate = newValue.startOf("day");
                                    setToDate(utcDate);
                                    onChangeToDate?.(utcDate);
                                }}
                                slotProps={{
                                    popper: {
                                        keepMounted: true,
                                    },
                                    textField: {
                                        onClick: () =>
                                            setOpenEndDatePicker(true),
                                    },
                                }}
                                renderDay={renderDay}
                            />{" "}
                        </>
                    ) : (
                        <DatePicker
                            timezone="default"
                            open={openFromDatePicker}
                            onOpen={() => setOpenFromDatePicker(true)}
                            onClose={() => setOpenFromDatePicker(false)}
                            format="MMM YYYY"
                            views={["year", "month"]}
                            openTo="month"
                            slotProps={{
                                popper: {
                                    keepMounted: true,
                                },
                                textField: {
                                    onClick: () => setOpenFromDatePicker(true),
                                },
                            }}
                            value={dayjs.utc(fromDate)}
                            minDate={dayjs.utc(
                                fromDateProps.minDate ??
                                    DefaultFromDateProps.minDate
                            )}
                            maxDate={dayjs.utc(
                                fromDateProps.maxDate ??
                                    DefaultFromDateProps.maxDate
                            )}
                            onChange={(newValue) => {
                                const utcFromDate = newValue.startOf("day");
                                setFromDate(utcFromDate);
                                onChangeFromDate?.(utcFromDate);

                                // const utcToDate = newValue.endOf("month");
                                // setToDate(utcToDate);
                                // onChangeToDate?.(utcToDate);

                                console.log({
                                    newValue,
                                    utcFromDate,
                                    // utcToDate,
                                });
                            }}
                        />
                    )}
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default DateRangePicker;
