import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Axios from "axios";
import {
    Container,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
    Button,
    Box,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useSnackbar } from "notistack";
import EChartsReact from "echarts-for-react";

import ProgressBarSteps from "./shared/ProgressBarSteps";
import WrapperPDFEChart from "./shared/WrapperPDFEChart";
import HoverPaper from "./shared/HoverPaper";

import DateSelector, {
    currentMonthButton,
    currentYearButton,
    nextMonthButton,
    nextYearButton,
} from "./DateSelector";
import CardDashboard_Old from "./CardDashboard_Old";
import SmallPercentage from "./SmallPercentage";
import RoundedButton from "./RoundedButton";

import RevenueComparisonMonth from "./reports/RevenueComparisonMonth";

import Colours from "../helpers/colours";
import { tooltip } from "../helpers/eCharts";
import { get_currency } from "../helpers/currency";

import {
    AI_CANCELLATIONS_THRESHOLD,
    DEFAULT_HOTEL,
    HOTELS_INFO,
    URLAPI,
} from "../configuration";
import dayjs from "dayjs";

const ArrayIntoPieces = (arr, N) => {
    return Array.from({ length: Math.ceil(arr.length / N) }, (v, i) =>
        arr.slice(i * N, i * N + N)
    );
};
const AICancellations = () => {
    const TITLE = "Predicted Cancellations";

    const { id: hotelID } = useSelector((state) => state.hotelID);
    const { auth } = useSelector((state) => state);

    const [time, setTime] = useState(dayjs());
    const [fullYear, setFullYear] = useState(false);

    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [cardSelected, setCardSelected] = useState("bookings");

    const [loaded, setLoaded] = useState(false);

    const [dates, setDates] = useState([]);
    const [count, setCount] = useState(0);

    const [options, setOptions] = useState({
        bookings: {
            title: "Bookings",
            id: "bookings",
            value: { current: 0, old: 0 },
        },
        bookings_risk: {
            title: "Bookings At Risk",
            id: "bookings_risk",
            // subbody: `(>${AI_CANCELLATIONS_THRESHOLD}%)`,
            value: { current: 0, old: 0 },
        },
        cancellation: {
            title: "Cancellation Rate",
            id: "cancellation",
            value: { current: 0, old: 0 },
        },
        revenue_lost: {
            title: "Revenue Lost",
            id: "revenue_lost",
            value: { current: 0, old: 0 },
        },
    });
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setLoading(true);

        let fromTime;
        let toTime;

        if (fullYear) {
            fromTime = new Date(Date.UTC(time.year(), 0, 1))
                .toISOString()
                .split("T")[0];
            toTime = new Date(Date.UTC(time.year() + 1, 0, 0))
                .toISOString()
                .split("T")[0];
        } else {
            fromTime = new Date(Date.UTC(time.year(), time.month(), 1))
                .toISOString()
                .split("T")[0];

            toTime = new Date(Date.UTC(time.year(), time.month() + 1, 0))
                .toISOString()
                .split("T")[0];
        }

        Axios({
            method: "get",
            url: `${URLAPI}/AI/cancellations/${
                hotelID === "default" ? DEFAULT_HOTEL : hotelID
            }`,
            params: {
                from: fromTime,
                to: toTime,
                threshold: AI_CANCELLATIONS_THRESHOLD,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((response) => {
                const {
                    data: { data, predictions, individual_cancellations },
                } = response;

                let aux_bookings = {
                    title: "Bookings",
                    id: "bookings",
                    value: {
                        current: data.reduce((a, b) => a + b.Bookings, 0),
                        old: data.reduce((a, b) => a + b.Bookings_before, 0),
                        data,
                    },
                    days: data.map((d) => d.date),
                };

                let aux_bookings_risk = {
                    noArrow: true,
                    title: "Bookings At Risk",
                    id: "bookings_risk",
                    // subbody: `(>${AI_CANCELLATIONS_THRESHOLD}%)`,
                    value: {
                        current: individual_cancellations.length,
                        old: 0,
                    },
                    predictions,
                    individual_cancellations,
                };

                let aux_cancellations = {
                    title: "Cancellation Rate",
                    id: "cancellation",
                    value: {
                        current:
                            (data.reduce((a, b) => a + b.CancelledBookings, 0) /
                                (data.reduce(
                                    (a, b) => a + b.CancelledBookings,
                                    0
                                ) +
                                    data.reduce((a, b) => a + b.Bookings, 0))) *
                            100,
                        old:
                            (data.reduce(
                                (a, b) => a + b.CancelledBookings_before,
                                0
                            ) /
                                data.reduce(
                                    (a, b) => a + b.Bookings_before,
                                    0
                                )) *
                            100,
                        quantity_current: data.reduce(
                            (a, b) => a + b.CancelledBookings,
                            0
                        ),
                        quantity_old: data.reduce(
                            (a, b) => a + b.CancelledBookings_before,
                            0
                        ),
                    },
                    isInverted: true,
                    format: { right: "%" },
                };

                let aux_revenue_lost = {
                    title: "Revenue Lost",
                    id: "revenue_lost",
                    value: {
                        current: data.reduce(
                            (a, b) => a + b.CancelledRevenue,
                            0
                        ),
                        old: data.reduce(
                            (a, b) => a + b.CancelledRevenue_before,
                            0
                        ),
                    },
                };
                setOptions({
                    ...options,
                    bookings: aux_bookings,
                    cancellation: aux_cancellations,
                    revenue_lost: aux_revenue_lost,
                    bookings_risk: aux_bookings_risk,
                });
                setDates(data.map((d) => d.date));
                setLoaded(true);
                setLoading(false);
            })
            .catch((e) => setLoading(false));
    }, [hotelID, count, time, fullYear]);

    return (
        <Container component={HoverPaper}>
            <br />
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
            >
                <Stack
                    alignItems="center"
                    // style={{ border: "1px solid yellow" }}
                >
                    <Typography fontWeight={"bold"}>{TITLE}</Typography>
                    <Typography fontStyle={"italic"} variant="subtitle2">
                        By CheckIn date
                    </Typography>
                </Stack>
                {/*  ____        _         ____       _           _             
                    |  _ \  __ _| |_ ___  / ___|  ___| | ___  ___| |_ ___  _ __ 
                    | | | |/ _` | __/ _ \ \___ \ / _ \ |/ _ \/ __| __/ _ \| '__|
                    | |_| | (_| | ||  __/  ___) |  __/ |  __/ (__| || (_) | |   
                    |____/ \__,_|\__\___| |____/ \___|_|\___|\___|\__\___/|_|   */}
                <DateSelector
                    collapse={isMobile}
                    fullYear={fullYear}
                    setFullYear={setFullYear}
                    time={dayjs(time)}
                    setTime={setTime}
                    day={false}
                    buttons={[
                        {
                            label: "Current Month",
                            fullYear: false,
                            value: currentMonthButton(),
                        },
                        {
                            label: "Next Month",
                            fullYear: false,
                            value: nextMonthButton(),
                        },
                        {
                            label: "Current Year",
                            fullYear: true,
                            value: currentYearButton(),
                        },
                        {
                            label: "Next Year",
                            fullYear: true,
                            value: nextYearButton(),
                        },
                    ]}
                />
                <Button onClick={() => setCount(count + 1)}>Reload</Button>
                {loading && (
                    <Box width="100%">
                        <ProgressBarSteps
                            steps={[
                                3, 5, 10, 15, 20, 25, 40, 60, 65, 72, 89, 100,
                            ]}
                        />
                    </Box>
                )}
                {/*  ____              _     
                    / ___|__ _ _ __ __| |___ 
                   | |   / _` | '__/ _` / __|
                   | |__| (_| | | | (_| \__ \
                    \____\__,_|_|  \__,_|___/  */}
                <Grid container spacing={0}>
                    {Object.values(options).map((o) => (
                        <Grid
                            item
                            key={o.id}
                            xs={6}
                            md={12 / Object.values(options).length}
                            onClick={() => {
                                cardSelected === o.id
                                    ? setCardSelected("")
                                    : setCardSelected(o.id);
                            }}
                        >
                            <CardDashboard_Old
                                title={o.title}
                                value={(o.value ?? {}).current}
                                isIncrease={
                                    (o.value ?? {}).current >
                                    (o.value ?? {}).old
                                }
                                isInverted={o.isInverted || false}
                                noArrow={o.noArrow}
                                footer={
                                    <SmallPercentage
                                        colorized
                                        tooltip={false}
                                        actual={(o.value ?? {}).current}
                                        old={(o.value ?? {}).old}
                                    />
                                }
                                selected={cardSelected === o.id}
                                selectedColoured={cardSelected === o.id}
                                elevation={1}
                                format={o.format}
                                colours={{
                                    selected: null,
                                    unselected: Colours.navbarBottomLine,
                                }}
                                pointer={true}
                                subbody={o.subbody}
                            />
                        </Grid>
                    ))}
                </Grid>
                <br />
                {loaded && (
                    <>
                        {cardSelected === "bookings" ? (
                            <>
                                {/*  _                 _    _                 
                                    | |__   ___   ___ | | _(_)_ __   __ _ ___ 
                                    | '_ \ / _ \ / _ \| |/ / | '_ \ / _` / __|
                                    | |_) | (_) | (_) |   <| | | | | (_| \__ \
                                    |_.__/ \___/ \___/|_|\_\_|_| |_|\__, |___/
                                                                    |___/      */}
                                <WrapperPDFEChart k={TITLE + " Bookings"}>
                                    <EChartsReact
                                        // style={{ marginTop: "16px" }}
                                        style={{ height: 300, width: "100%" }}
                                        option={{
                                            tooltip: tooltip,
                                            legend: {
                                                data: [
                                                    "Bookings This",
                                                    "Cancelled Bookings",
                                                    "Bookings at Risk",
                                                    "Bookings Last year",
                                                    "Cancelled Bookings Last Year",
                                                ],
                                                selected: {
                                                    "Bookings Last year": false,
                                                    "Cancelled Bookings Last Year": false,
                                                },
                                            },
                                            xAxis: {
                                                data: dates,
                                            },
                                            yAxis: {},
                                            series: [
                                                {
                                                    name: "Bookings This",
                                                    type: "line",
                                                    data: options.bookings.value.data.map(
                                                        (d) => d.Bookings
                                                    ),
                                                    // stack: "Total",
                                                    // areaStyle: {},
                                                    itemStyle: {
                                                        color: Colours.graphMainLine1,
                                                    },
                                                },
                                                {
                                                    name: "Bookings Last year",
                                                    type: "line",
                                                    data: options.bookings.value.data.map(
                                                        (d) => d.Bookings_before
                                                    ),
                                                    itemStyle: {
                                                        color: Colours.graphDisabledLighter,
                                                    },
                                                },
                                                {
                                                    name: "Bookings at Risk",
                                                    type: "scatter",
                                                    data: Object.entries(
                                                        options.bookings_risk.individual_cancellations.reduce(
                                                            (acc, d) => (
                                                                (acc[d.date] =
                                                                    (acc[
                                                                        d.date
                                                                    ] || 0) +
                                                                    1),
                                                                acc
                                                            ),
                                                            {}
                                                        )
                                                    ).map(([date, count]) => ({
                                                        value: [
                                                            date,
                                                            count,
                                                            count,
                                                        ],
                                                    })),
                                                    itemStyle: {
                                                        color: Colours.graphExtraLine1,
                                                    },
                                                },
                                                {
                                                    name: "Cancelled Bookings Last Year",
                                                    type: "line",
                                                    data: options.bookings.value.data.map(
                                                        (d) =>
                                                            d.CancelledBookings_before
                                                    ),
                                                    itemStyle: {
                                                        color: Colours.graphDisabledDarker,
                                                    },
                                                },
                                                {
                                                    name: "Cancelled Bookings",
                                                    type: "line",
                                                    data: options.bookings.value.data.map(
                                                        (d) =>
                                                            d.CancelledBookings
                                                    ),
                                                    itemStyle: {
                                                        color: Colours.graphMainLine2,
                                                    },
                                                },
                                            ],
                                        }}
                                    />
                                </WrapperPDFEChart>
                            </>
                        ) : cardSelected === "bookings_risk" ? (
                            <>
                                {/*____              _    _                   ____  _     _    
                                  | __ )  ___   ___ | | _(_)_ __   __ _ ___  |  _ \(_)___| | __
                                  |  _ \ / _ \ / _ \| |/ / | '_ \ / _` / __| | |_) | / __| |/ /
                                  | |_) | (_) | (_) |   <| | | | | (_| \__ \ |  _ <| \__ \   < 
                                  |____/ \___/ \___/|_|\_\_|_| |_|\__, |___/ |_| \_\_|___/_|\_\
                                                                  |___/                         */}
                                <Box width={"100%"}>
                                    {options.bookings_risk
                                        .individual_cancellations.length ===
                                        0 && (
                                        <Typography>
                                            Congratulations, there is not
                                            bookings at risk for the selected
                                            period
                                        </Typography>
                                    )}
                                    <Carousel
                                        animation={"slide"}
                                        autoPlay={false}
                                        duration={550}
                                    >
                                        {ArrayIntoPieces(
                                            options.bookings_risk
                                                .individual_cancellations,
                                            isMobile ? 1 : 3
                                        ).map((group, index) => (
                                            <Grid
                                                container
                                                key={index}
                                                justifyContent="center"
                                                alignItems="center"
                                                sx={{ width: "100%" }}
                                            >
                                                {group.map((p, i) => (
                                                    <Grid
                                                        item
                                                        key={i}
                                                        xs={12}
                                                        md={12 / 3}
                                                    >
                                                        <HoverPaper
                                                            sx={{
                                                                p: 5,
                                                                pb: 3,
                                                                ml: 1,
                                                                mr: 1,
                                                                backgroundColor:
                                                                    Colours.secondarySoft,
                                                            }}
                                                            elevation={5}
                                                        >
                                                            <Stack
                                                                direction="column"
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >
                                                                <Typography
                                                                    fontSize={
                                                                        "0.80em"
                                                                    }
                                                                >
                                                                    Cancel
                                                                    probability:{" "}
                                                                    <strong>
                                                                        {(
                                                                            p.prediction_score *
                                                                            100
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                        %
                                                                    </strong>
                                                                </Typography>
                                                                <Typography
                                                                    align="center"
                                                                    fontWeight={
                                                                        "bold"
                                                                    }
                                                                    style={{
                                                                        // This is to avoid different heights when one element takes 2 lines and others 1
                                                                        minHeight:
                                                                            "3em",
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    {
                                                                        p.OfferTitle
                                                                    }
                                                                </Typography>
                                                                <br />
                                                                <Typography>
                                                                    {new Date(
                                                                        p.CheckIn
                                                                    )
                                                                        .toISOString()
                                                                        .slice(
                                                                            0,
                                                                            10
                                                                        )}{" "}
                                                                    ↔{" "}
                                                                    {new Date(
                                                                        p.CheckOut
                                                                    )
                                                                        .toISOString()
                                                                        .slice(
                                                                            0,
                                                                            10
                                                                        )}
                                                                </Typography>
                                                                <Typography>
                                                                    <strong>
                                                                        {
                                                                            p.TotalOccupancy
                                                                        }{" "}
                                                                    </strong>
                                                                    {p.TotalOccupancy >
                                                                    1
                                                                        ? "people"
                                                                        : "person"}
                                                                </Typography>
                                                                <Typography>
                                                                    Number of
                                                                    Rooms:{" "}
                                                                    <strong>
                                                                        {
                                                                            p.NumberRooms
                                                                        }
                                                                    </strong>
                                                                </Typography>
                                                                <Typography>
                                                                    {p.Country}
                                                                </Typography>
                                                                <br />
                                                                <Typography
                                                                    fontWeight={
                                                                        "bold"
                                                                    }
                                                                >
                                                                    {p.TotalPrice.toFixed(
                                                                        2
                                                                    )}
                                                                    {get_currency(
                                                                        hotelID
                                                                    )}
                                                                </Typography>
                                                                <br />

                                                                <RoundedButton
                                                                    onClick={() => {
                                                                        navigator.clipboard
                                                                            .writeText(
                                                                                p.TrackSessionID
                                                                            )
                                                                            .then(
                                                                                () =>
                                                                                    enqueueSnackbar(
                                                                                        `Session ID copied: ${p.TrackSessionID}`,
                                                                                        {
                                                                                            anchorOrigin:
                                                                                                {
                                                                                                    vertical:
                                                                                                        "bottom",
                                                                                                    horizontal:
                                                                                                        "center",
                                                                                                },
                                                                                            variant:
                                                                                                "success",
                                                                                            autoHideDuration: 3000,
                                                                                        }
                                                                                    )
                                                                            )
                                                                            .catch(
                                                                                () =>
                                                                                    enqueueSnackbar(
                                                                                        "Error at copy",
                                                                                        {
                                                                                            anchorOrigin:
                                                                                                {
                                                                                                    vertical:
                                                                                                        "bottom",
                                                                                                    horizontal:
                                                                                                        "center",
                                                                                                },
                                                                                            variant:
                                                                                                "error",
                                                                                            autoHideDuration: 3000,
                                                                                        }
                                                                                    )
                                                                            );
                                                                    }}
                                                                >
                                                                    Copy Session
                                                                    ID
                                                                </RoundedButton>
                                                            </Stack>
                                                        </HoverPaper>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ))}
                                    </Carousel>
                                </Box>
                            </>
                        ) : cardSelected === "cancellation" ? (
                            <>
                                {/*____                     _ _       _   _               ____       _       
                                  / ___|__ _ _ __   ___ ___| | | __ _| |_(_) ___  _ __   |  _ \ __ _| |_ ___ 
                                 | |   / _` | '_ \ / __/ _ \ | |/ _` | __| |/ _ \| '_ \  | |_) / _` | __/ _ \
                                 | |__| (_| | | | | (_|  __/ | | (_| | |_| | (_) | | | | |  _ < (_| | ||  __/
                                  \____\__,_|_| |_|\___\___|_|_|\__,_|\__|_|\___/|_| |_| |_| \_\__,_|\__\___|*/}
                                <RevenueComparisonMonth
                                    revenueName="Bookings"
                                    thisTitle={
                                        "Current bookings and cancellations"
                                    }
                                    lastTitle={
                                        "Last year bookings and cancellations"
                                    }
                                    thisRevenue={options.bookings.value.current}
                                    thisCancellations={
                                        options.cancellation.value
                                            .quantity_current
                                    }
                                    lastRevenue={options.bookings.value.old}
                                    lastCancellations={
                                        options.cancellation.value.quantity_old
                                    }
                                    hotel_id={hotelID}
                                    noSymbol
                                    noCentralLabel
                                />
                            </>
                        ) : cardSelected === "revenue_lost" ? (
                            <>
                                {/*____                                   _              _   
                                  |  _ \ _____   _____ _ __  _   _  ___  | |    ___  ___| |_ 
                                  | |_) / _ \ \ / / _ \ '_ \| | | |/ _ \ | |   / _ \/ __| __|
                                  |  _ <  __/\ V /  __/ | | | |_| |  __/ | |__| (_) \__ \ |_ 
                                  |_| \_\___| \_/ \___|_| |_|\__,_|\___| |_____\___/|___/\__|*/}
                                <WrapperPDFEChart k={TITLE + " Revenue Lost"}>
                                    <EChartsReact
                                        style={{ height: 300, width: "100%" }}
                                        option={{
                                            tooltip: tooltip,
                                            legend: {
                                                data: [
                                                    "Revenue",
                                                    "Cancelled Revenue",
                                                    "Potential Cancelled Revenue",
                                                    "Revenue Last Year",
                                                    "Cancelled Revenue Last Year",
                                                ],
                                                selected: {
                                                    Revenue: false,
                                                    "Revenue Last Year": false,
                                                },
                                            },
                                            xAxis: {
                                                data: dates,
                                            },
                                            yAxis: {},
                                            series: [
                                                {
                                                    name: "Revenue",
                                                    type: "line",
                                                    data: options.bookings.value.data.reduce(
                                                        (acc, d) => {
                                                            const lastValue =
                                                                acc.length > 0
                                                                    ? acc[
                                                                          acc.length -
                                                                              1
                                                                      ]
                                                                    : 0;
                                                            acc.push(
                                                                +(
                                                                    lastValue +
                                                                    d.Revenue
                                                                ).toFixed(2)
                                                            );
                                                            return acc;
                                                        },
                                                        []
                                                    ),
                                                    itemStyle: {
                                                        color: Colours.graphMainLine1,
                                                    },
                                                },
                                                {
                                                    name: "Revenue Last Year",
                                                    type: "line",
                                                    data: options.bookings.value.data.reduce(
                                                        (acc, d) => {
                                                            const lastValue =
                                                                acc.length > 0
                                                                    ? acc[
                                                                          acc.length -
                                                                              1
                                                                      ]
                                                                    : 0;
                                                            acc.push(
                                                                +(
                                                                    lastValue +
                                                                    d.Revenue_before
                                                                ).toFixed(2)
                                                            );
                                                            return acc;
                                                        },
                                                        []
                                                    ),
                                                    itemStyle: {
                                                        color: Colours.graphDisabledLighter,
                                                    },
                                                },
                                                {
                                                    name: "Cancelled Revenue",
                                                    type: "line",
                                                    data: options.bookings.value.data.reduce(
                                                        (acc, d) => {
                                                            const lastValue =
                                                                acc.length > 0
                                                                    ? acc[
                                                                          acc.length -
                                                                              1
                                                                      ]
                                                                    : 0;
                                                            acc.push(
                                                                +(
                                                                    lastValue +
                                                                    d.CancelledRevenue
                                                                ).toFixed(2)
                                                            );
                                                            return acc;
                                                        },
                                                        []
                                                    ),
                                                    itemStyle: {
                                                        color: Colours.graphMainLine2,
                                                    },
                                                },
                                                {
                                                    name: "Potential Cancelled Revenue",
                                                    type: "line",
                                                    data: (() => {
                                                        let acc = {};
                                                        options.bookings.value.data.forEach(
                                                            (d) =>
                                                                (acc[d.date] =
                                                                    (acc[
                                                                        d.date
                                                                    ] || 0) +
                                                                    d.CancelledRevenue)
                                                        );
                                                        options.bookings_risk.individual_cancellations.forEach(
                                                            (d) =>
                                                                (acc[d.date] =
                                                                    (acc[
                                                                        d.date
                                                                    ] || 0) +
                                                                    d.TotalPrice)
                                                        );

                                                        let old = 0;
                                                        return Object.entries(
                                                            acc
                                                        ).map(([k, v]) => {
                                                            old = v + old;
                                                            return [k, old];
                                                        });
                                                    })(),
                                                    itemStyle: {
                                                        color: Colours.graphExtraLine1,
                                                    },
                                                },
                                                {
                                                    name: "Cancelled Revenue Last Year",
                                                    type: "line",
                                                    data: options.bookings.value.data.reduce(
                                                        (acc, d) => {
                                                            const lastValue =
                                                                acc.length > 0
                                                                    ? acc[
                                                                          acc.length -
                                                                              1
                                                                      ]
                                                                    : 0;
                                                            acc.push(
                                                                +(
                                                                    lastValue +
                                                                    d.CancelledRevenue_before
                                                                ).toFixed(2)
                                                            );
                                                            return acc;
                                                        },
                                                        []
                                                    ),
                                                    itemStyle: {
                                                        color: Colours.graphDisabledDarker,
                                                    },
                                                },
                                            ],
                                        }}
                                    />
                                </WrapperPDFEChart>
                            </>
                        ) : null}
                    </>
                )}
            </Stack>
            <br />
        </Container>
    );
};

export default AICancellations;
