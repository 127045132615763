import React, { useEffect, useState } from "react";

import {
    Box,
    Button,
    IconButton,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import EventDisplay from "./EventDisplay";
import EventsModal from "./EventsModal";
import RoundedButton from "../RoundedButton";
import { EventsAddMultipleButton } from "./EventsAddMultiple";
import { EventsListButton } from "./EventsList";

import Icons from "../../helpers/icons";
import {
    weekDays,
    newDateUTC,
    getYearsBefore,
    months,
} from "../../helpers/dates";

import Colours from "../../helpers/colours";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const CALENDAR_MODE = "CALENDAR_MODE";

const eventPlacementHandler = (days, events) => {
    let eventsThroughMonth = {}; // Duplicated events for every day they're on

    let daysStrings = Object.keys(days);
    daysStrings.forEach((day, indexDay) => {
        // Takes only events from calendar days
        (events[day] ?? []).forEach((event) => {
            // For every event that happens in that day...

            let startDate = new Date(event.FromDate);
            event.FromDateString = startDate.toISOString().slice(0, 10);
            let endDate = new Date(event.ToDate);
            event.ToDateString = endDate.toISOString().slice(0, 10);

            let currentDate = event.FromDateString;
            let currentDateIndex = indexDay;
            while (
                currentDate !== event.ToDateString &&
                currentDateIndex < daysStrings.length
            ) {
                // ...Make it happen every day it lasts too(duplicate)

                if (!eventsThroughMonth.hasOwnProperty(currentDate)) {
                    eventsThroughMonth[currentDate] = [];
                }
                let auxEvent = { ...event };
                auxEvent["CurrentDateString"] = currentDate;
                eventsThroughMonth[currentDate].push(auxEvent);

                currentDateIndex++;
                currentDate = daysStrings[currentDateIndex] ?? "";
            }
            if (currentDate === event.ToDateString) {
                if (!eventsThroughMonth.hasOwnProperty(currentDate))
                    eventsThroughMonth[currentDate] = [];

                let auxEvent = { ...event };
                auxEvent["CurrentDateString"] = currentDate;
                eventsThroughMonth[currentDate].push(auxEvent);
            }
        });
    });

    const sortEventsDay = (eventsDay) =>
        eventsDay.sort((a, b) => {
            // Compare based on the "from" property (the oldest event first)
            if (a.FromDateString !== b.FromDateString) {
                return new Date(a.FromDateString) - new Date(b.FromDateString);
            }

            // If the events have the same from date, compare based on duration (to - from)
            const durationA =
                new Date(a.ToDateString) - new Date(a.FromDateString);
            const durationB =
                new Date(b.ToDateString) - new Date(b.FromDateString);

            return durationB - durationA; // Sort in descending order of duration
        });

    let eventsID = {};

    Object.values(events)
        .flat()
        .forEach((event) => {
            eventsID[`${event.EventID + event.FromDateString}`] = event;
        });

    // Place events in the calendar days
    daysStrings.forEach((day) => {
        if (eventsThroughMonth.hasOwnProperty(day)) {
            // 1º - Spot old(assigned) and new(unassigned) events for that day
            let assigned = [];
            let unassigned = [];
            eventsThroughMonth[day].forEach((event) => {
                if (
                    eventsID[
                        `${event.EventID + event.FromDateString}`
                    ].hasOwnProperty("space")
                ) {
                    if (new Date(day).getUTCDay() === 1) {
                        // Start of the week, renew spaces
                        delete eventsID[
                            `${event.EventID + event.FromDateString}`
                        ]["space"];
                        unassigned.push(event);
                    } else assigned.push(event);
                } else unassigned.push(event);
            });

            // 2º - Place assigned and initial spaces (start of the week not needed)

            // Total spaces with assigned and null spaces
            let initialDayLength =
                assigned.length > 0
                    ? Math.max(
                          ...assigned.map(
                              (event) =>
                                  eventsID[
                                      `${event.EventID + event.FromDateString}`
                                  ].space
                          )
                      )
                    : 1;

            // Fill the day total spaces with null values
            days[day] = new Array(initialDayLength).fill(null); // day = [null, null, null, null]

            // Place assigned events in their space
            assigned.forEach(
                (event) =>
                    (days[day][
                        eventsID[`${event.EventID + event.FromDateString}`]
                            .space - 1
                    ] = event)
            ); // day = [event, null, null, event]

            // 3º - Place unassigned

            // Sort new(unassigned)
            unassigned = sortEventsDay(unassigned);

            // Place unassigned
            while (unassigned.length > 0) {
                const id = unassigned[0].EventID + unassigned[0].FromDateString;
                const nullSpace = days[day].findIndex(
                    (place) => place === null
                ); // Is there any empty space?

                if (nullSpace !== -1) {
                    // Yes
                    unassigned[0]["space"] = nullSpace + 1;
                    days[day][nullSpace] = unassigned.shift();
                    eventsID[id]["space"] = nullSpace + 1;
                } else {
                    // No
                    days[day].push(unassigned.shift());
                    const lastPlace = days[day].length;
                    days[day][lastPlace - 1]["space"] = lastPlace;
                    eventsID[id]["space"] = lastPlace;
                }
            }

            // Start of the week removes all blank spaces
            if (new Date(day).getUTCDay() === 1) {
                const removedNull = days[day].filter((place) => place !== null);

                const spaceAdjust = days[day].length - removedNull.length;

                const newDay = [];
                removedNull.forEach((event) => {
                    event.space -= spaceAdjust;
                    eventsID[`${event.EventID + event.FromDateString}`].space =
                        event.space;
                    newDay.push(event);
                });

                days[day] = newDay;
            }
        }
    });

    return days;
};

const CustomDateButton = ({ date, onChange = () => {} }) => {
    const [open, setOpen] = useState(false);

    const ButtonField = (props) => {
        const { id, InputProps: { ref } = {} } = props;

        return (
            <Box id="theRoundedButton">
                <RoundedButton
                    ref={ref}
                    id={id}
                    onClick={() => setOpen?.((prev) => !prev)}
                    component="button"
                    sx={{
                        width: 255,
                        background: "none",
                        "&:hover": {
                            backgroundColor: "whiteSmoke",
                        },
                    }}
                >
                    <Typography
                        display={"flex"}
                        alignItems={"center"}
                        fontSize={"1.3em"}
                        sx={{ color: "black" }}
                    >
                        {`${months[dayjs.utc(date).month()]} ${dayjs
                            .utc(date)
                            .year()}`}
                    </Typography>
                </RoundedButton>
            </Box>
        );
    };

    const ButtonDatePicker = (props) => {
        return (
            <DatePicker
                timezone="UTC"
                open={open}
                views={["year", "month"]}
                openTo="month"
                minDate={dayjs(
                    new Date(
                        new Date().getUTCFullYear() -
                            getYearsBefore().length +
                            2,
                        0,
                        1
                    )
                )}
                maxDate={dayjs(new Date(new Date().getUTCFullYear() + 2, 0, 0))}
                onChange={(newValue) => {
                    onChange(newValue);
                    setOpen(false);
                }}
                onClose={() => setOpen(false)}
                onOpen={() => {
                    setOpen(true);
                }}
                value={date}
                slots={{ ...props.slots, field: ButtonField }}
                slotProps={{ ...props.slotProps, field: { setOpen } }}
            />
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box component="div" display={"inline"}>
                <ButtonDatePicker value={dayjs(date)} onChange={onChange} />
            </Box>
        </LocalizationProvider>
    );
};

const EventsCalendar = ({
    hotelID,
    isMobile = false,
    setMainMode,
    events = {},
    handleRefreshEvents,
}) => {
    const [mode, setMode] = useState(CALENDAR_MODE);

    const [anchorDatePicker, setAnchorDatePicker] = useState(null);

    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [time, setTime] = useState(dayjs.utc());

    const [clickedDate, setClickedDate] = useState("");
    const [openDay, setOpenDay] = useState(false);

    const [calendarDays, setCalendarDays] = useState({});
    const [eventsList, setEventsList] = useState([]);

    const currentDate = dayjs.utc(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        1
    );

    const getEventsMonthList = () => {
        if (Object.keys(events).length > 0) {
            const year = time.toISOString().slice(0, 4);
            const month = time.toISOString().slice(5, 7);

            let auxMonthEvents = [];
            Object.keys(events).forEach((eventDate) => {
                let eventYear = eventDate.slice(0, 4);
                let eventMonth = eventDate.slice(5, 7);

                if (eventMonth === month && eventYear === year)
                    auxMonthEvents.push(events[eventDate]);
            });

            let auxSortedMonthEvents = auxMonthEvents
                .flat()
                .sort((a, b) => new Date(a.FromDate) - new Date(b.FromDate));

            setEventsList(auxSortedMonthEvents);
        }
    };

    useEffect(() => {
        getEventsMonthList();
    }, [events]);

    const handlePrevNextDateChange = (changeTo) => {
        const year = time.year();
        const month = time.month() + changeTo;

        const auxTime = dayjs.utc().year(year).month(month).date(1);
        setTime(auxTime);
    };

    const backToCurrentDate = () => {
        setTime(currentDate);
    };

    const getMonthCalendarDays = () => {
        const year = time.year();
        const month = time.month();

        let auxDays = {};

        // Days of the previous month
        let firstWeekDay = newDateUTC(year, month, 1).getUTCDay();
        firstWeekDay = firstWeekDay === 0 ? 6 : firstWeekDay - 1; // week day correction so monday is 0 and sunday is 6
        for (let i = firstWeekDay - 1; i >= 0; i--) {
            const dateString = newDateUTC(year, month, i * -1)
                .toISOString()
                .slice(0, 10);

            auxDays[dateString] = [];
        }

        // Days of actual month
        let lastDay = newDateUTC(year, month + 1, 0).getUTCDate();
        for (let i = 1; i <= lastDay; i++) {
            const dateString = newDateUTC(year, month, i)
                .toISOString()
                .slice(0, 10);
            auxDays[dateString] = [];
        }

        // Days of the next month
        let lastWeekDay = newDateUTC(year, month + 1, 0).getUTCDay();
        new Date(Date.UTC(year, month + 1, 0)).getUTCDay();
        lastWeekDay = lastWeekDay === 0 ? 6 : lastWeekDay - 1; // week day correction so monday is 0 and sunday is 6
        for (let i = 1; i <= 6 - lastWeekDay; i++) {
            const dateString = newDateUTC(year, month + 1, i)
                .toISOString()
                .slice(0, 10);
            auxDays[dateString] = [];
        }

        if (Object.keys(events).length > 0) {
            let eventsCopy = JSON.parse(JSON.stringify(events));
            let auxCalendarEventsDays = eventPlacementHandler(
                auxDays,
                eventsCopy
            );
            setCalendarDays(auxCalendarEventsDays);
        } else {
            setCalendarDays(auxDays);
        }
    };

    useEffect(() => {
        getMonthCalendarDays();
        getEventsMonthList();
    }, [time, events]);

    return (
        <>
            <Stack
                direction={"row"}
                sx={{
                    justifyContent: "space-between",
                }}
            >
                <Stack direction="row">
                    <IconButton onClick={() => handlePrevNextDateChange(-1)}>
                        <Icons.ArrowLeft color={"black"} />
                    </IconButton>
                    <CustomDateButton
                        date={time}
                        onChange={(newValue) => {
                            setTime(dayjs(newValue));
                        }}
                    />

                    <IconButton onClick={() => handlePrevNextDateChange(+1)}>
                        <Icons.ArrowRight color={"black"} />
                    </IconButton>

                    {/* {time !== currentDate && (
                        <IconButton onClick={() => backToCurrentDate()}>
                            <Icons.Today color={"black"} />
                        </IconButton>
                    )} */}
                </Stack>
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    spacing={2}
                >
                    <EventsListButton
                        isMobile={isMobile}
                        setMainMode={setMainMode}
                    />
                    <EventsModal
                        hotelID={hotelID}
                        handleRefreshEvents={handleRefreshEvents}
                    />
                    <EventsAddMultipleButton isMobile={isMobile} />
                </Stack>
            </Stack>

            {mode === CALENDAR_MODE && Object.keys(calendarDays).length > 0 && (
                <>
                    <Table>
                        <TableBody>
                            <>
                                <TableRow
                                    sx={{
                                        display: "flex",
                                        height: "auto",
                                        width: "100%",
                                    }}
                                    key={"weekdays-row"}
                                >
                                    {weekDays.map((weekDay) => {
                                        return (
                                            <TableCell
                                                sx={{
                                                    justifyContent: "center",
                                                    width: "14%",

                                                    border: "1px solid lightgrey",

                                                    backgroundColor:
                                                        Colours.tableRowBackground,
                                                    display: "flex",
                                                    paddingX: "1px",
                                                    paddingY: "1px",
                                                }}
                                                key={weekDay + "-cell"}
                                            >
                                                <Typography>
                                                    {weekDay.slice(0, 3)}
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>

                                {[
                                    ...Array(
                                        Object.keys(calendarDays).length / 7
                                    ),
                                ].map((row, rowIndex) => {
                                    return (
                                        <TableRow
                                            sx={{
                                                display: "flex",
                                                height: "120px",
                                                width: "100%",
                                            }}
                                            key={rowIndex}
                                        >
                                            {Object.keys(calendarDays)
                                                .slice(
                                                    rowIndex * 7,
                                                    (rowIndex + 1) * 7
                                                )
                                                .map((date, dayIndex) => {
                                                    return (
                                                        <TableCell
                                                            sx={{
                                                                width: "14%", // 7 tableCells / 100%

                                                                border: "1px solid lightgrey",

                                                                backgroundColor:
                                                                    new Date()
                                                                        .toISOString()
                                                                        .slice(
                                                                            0,
                                                                            10
                                                                        ) ===
                                                                    date
                                                                        ? "AliceBlue"
                                                                        : "none",

                                                                display: "flex",
                                                                paddingX: "1px",
                                                                paddingY: "1px",
                                                            }}
                                                            key={date}
                                                        >
                                                            <Stack
                                                                display={"flex"}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                }}
                                                            >
                                                                {/*  ____               */}
                                                                {/* |  _ \  __ _ _   _  */}
                                                                {/* | | | |/ _` | | | | */}
                                                                {/* | |_| | (_| | |_| | */}
                                                                {/* |____/ \__,_|\__, | */}
                                                                {/*              |___/  */}
                                                                <Stack
                                                                    alignItems={
                                                                        "center"
                                                                    }
                                                                >
                                                                    <Button
                                                                        sx={{
                                                                            paddingY: 0,
                                                                            textTransform:
                                                                                "none",
                                                                            color:
                                                                                parseInt(
                                                                                    date.slice(
                                                                                        5,
                                                                                        7
                                                                                    )
                                                                                ) ===
                                                                                time.month() +
                                                                                    1
                                                                                    ? "black"
                                                                                    : "gray",
                                                                            ":hover":
                                                                                {
                                                                                    backgroundColor:
                                                                                        calendarDays[
                                                                                            date
                                                                                        ]
                                                                                            .length >
                                                                                        0
                                                                                            ? "lightgray"
                                                                                            : "transparent",
                                                                                },
                                                                            cursor:
                                                                                calendarDays[
                                                                                    date
                                                                                ]
                                                                                    .length >
                                                                                0
                                                                                    ? "pointer"
                                                                                    : "default",
                                                                        }}
                                                                        onClick={(
                                                                            v
                                                                        ) => {
                                                                            if (
                                                                                calendarDays[
                                                                                    date
                                                                                ]
                                                                                    .length >
                                                                                0
                                                                            ) {
                                                                                setClickedDate(
                                                                                    date
                                                                                );
                                                                                setOpenDay(
                                                                                    !openDay
                                                                                );
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            fontWeight={
                                                                                parseInt(
                                                                                    date.slice(
                                                                                        5,
                                                                                        7
                                                                                    )
                                                                                ) ===
                                                                                time.month() +
                                                                                    1
                                                                                    ? "bold"
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            {parseInt(
                                                                                date.slice(
                                                                                    8,
                                                                                    10
                                                                                )
                                                                            )}
                                                                        </Typography>
                                                                    </Button>
                                                                </Stack>

                                                                {/*  _____                 _        */}
                                                                {/* | ____|_   _____ _ __ | |_ ___  */}
                                                                {/* |  _| \ \ / / _ \ '_ \| __/ __| */}
                                                                {/* | |___ \ V /  __/ | | | |_\__ \ */}
                                                                {/* |_____| \_/ \___|_| |_|\__|___/ */}
                                                                <Stack
                                                                    direction={
                                                                        "column"
                                                                    }
                                                                    spacing={
                                                                        0.7
                                                                    }
                                                                    sx={{
                                                                        width: "100%",
                                                                        height: "80%",
                                                                        paddingY:
                                                                            "0px",
                                                                        marginY:
                                                                            "0px",
                                                                    }}
                                                                >
                                                                    {calendarDays[
                                                                        date
                                                                    ].length >
                                                                        0 &&
                                                                        calendarDays[
                                                                            date
                                                                        ].map(
                                                                            (
                                                                                event,
                                                                                eventNo
                                                                            ) => {
                                                                                if (
                                                                                    eventNo ===
                                                                                    3
                                                                                ) {
                                                                                    return (
                                                                                        <Button
                                                                                            onClick={(
                                                                                                v
                                                                                            ) => {
                                                                                                setClickedDate(
                                                                                                    date
                                                                                                );
                                                                                                setOpenDay(
                                                                                                    !openDay
                                                                                                );
                                                                                            }}
                                                                                            sx={{
                                                                                                fontSize:
                                                                                                    "80%",
                                                                                                textTransform:
                                                                                                    "none",
                                                                                                cursor: "pointer",
                                                                                                width: "100%",
                                                                                                maxHeight:
                                                                                                    "16px",

                                                                                                color: "black",
                                                                                                ":hover":
                                                                                                    {
                                                                                                        backgroundColor:
                                                                                                            "lightgray",
                                                                                                    },
                                                                                            }}
                                                                                        >
                                                                                            {`+ ${
                                                                                                calendarDays[
                                                                                                    date
                                                                                                ].filter(
                                                                                                    (
                                                                                                        event,
                                                                                                        index
                                                                                                    ) =>
                                                                                                        event !==
                                                                                                            null &&
                                                                                                        index >=
                                                                                                            3
                                                                                                )
                                                                                                    .length
                                                                                            } more`}
                                                                                        </Button>
                                                                                    );
                                                                                } else if (
                                                                                    event ===
                                                                                    null
                                                                                ) {
                                                                                    return (
                                                                                        <EventDisplay
                                                                                            mode={
                                                                                                "empty"
                                                                                            }
                                                                                            event={{
                                                                                                CurrentDateString:
                                                                                                    date,
                                                                                            }}
                                                                                            hotelID={
                                                                                                hotelID
                                                                                            }
                                                                                            handleRefreshEvents={
                                                                                                handleRefreshEvents
                                                                                            }
                                                                                        />
                                                                                    );
                                                                                } else if (
                                                                                    eventNo <
                                                                                    3
                                                                                ) {
                                                                                    event.Style =
                                                                                        event.CurrentDateString ===
                                                                                            event.FromDateString ||
                                                                                        new Date(
                                                                                            event.CurrentDateString
                                                                                        ).getUTCDay() ===
                                                                                            1
                                                                                            ? "start"
                                                                                            : "";
                                                                                    event.Elevation =
                                                                                        dayIndex *
                                                                                            -1 +
                                                                                        7;

                                                                                    return (
                                                                                        <EventDisplay
                                                                                            mode={
                                                                                                event.Style ===
                                                                                                "start"
                                                                                                    ? "line"
                                                                                                    : "empty"
                                                                                            }
                                                                                            hotelID={
                                                                                                hotelID
                                                                                            }
                                                                                            event={
                                                                                                event
                                                                                            }
                                                                                            handleRefreshEvents={
                                                                                                handleRefreshEvents
                                                                                            }
                                                                                        />
                                                                                    );
                                                                                }
                                                                                return (
                                                                                    <>

                                                                                    </>
                                                                                );
                                                                            }
                                                                        )}
                                                                </Stack>
                                                            </Stack>
                                                        </TableCell>
                                                    );
                                                })}
                                        </TableRow>
                                    );
                                })}
                            </>
                        </TableBody>
                    </Table>
                    {openDay && clickedDate && (
                        <Modal open={openDay} onClose={() => setOpenDay(false)}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: 400,
                                    minHeight: "200px",
                                    bgcolor: "background.paper",
                                    borderRadius: "10px",
                                    boxShadow: 24,
                                    p: 4,
                                }}
                            >
                                <Stack
                                    alignItems={"center"}
                                    direction={"column"}
                                >
                                    <Typography>{`${clickedDate}`}</Typography>
                                    <Stack
                                        direction={"column"}
                                        spacing={2}
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            paddingY: "5px",
                                        }}
                                    >
                                        {calendarDays[clickedDate]
                                            .filter((event) => event !== null)
                                            .map((event) => {
                                                let eventStyled = { ...event };
                                                eventStyled.Style = "full";

                                                return (
                                                    <EventDisplay
                                                        hotelID={hotelID}
                                                        event={eventStyled}
                                                        handleRefreshEvents={
                                                            handleRefreshEvents
                                                        }
                                                    />
                                                );
                                            })}
                                    </Stack>
                                </Stack>
                            </Box>
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

export default EventsCalendar;

export const EventsCalendarButton = ({ isMobile = false, setMainMode }) => {
    return (
        <Button
            variant="contained"
            sx={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid orange",
                borderRadius: 1000,
                "&:hover": {
                    backgroundColor: "orange",
                    color: "white",
                },
            }}
            disableElevation
            onClick={(v) => {
                setMainMode(CALENDAR_MODE);
            }}
        >
            <>
                <Icons.EventsCalendar sx={{ paddingRight: 1 }} />
                {isMobile ? "" : "Calendar"}
            </>
        </Button>
    );
};
