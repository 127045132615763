import React, { useEffect } from "react";

import ReportChartBarsExtras from "../components/ReportChartBarsExtras";

const ExtrasScreen = ({ data }) => {
    useEffect(() => {
        if (data[""]) {
            //
        }
    }, [data]);

    return (
        <>
            <ReportChartBarsExtras
                data={data}
                entriesLimit={5}
                entriesLimitSort="descending"
                initialMetric="totalCost"
                metrics={{
                    totalCost: {
                        label: "Total cost",
                        short: "Extras Revenue",
                        format: { left: "currency" },
                    },
                    count: {
                        label: "Count",
                        short: "Number of extras sold",
                        format: {},
                    },
                }}
                title="Total Revenue by Extras"
            />
        </>
    );
};

export default ExtrasScreen;
