import React, { useEffect, useState } from "react";
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Avatar,
    Typography,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    Stack,
} from "@mui/material";
import { format } from "date-fns";
import { HOTELS_INFO } from "../configuration";

// --- Funciones auxiliares (range category) ---
function differenceInDays(date1, date2) {
    const msInDay = 24 * 60 * 60 * 1000;
    return Math.floor((date1 - date2) / msInDay);
}

function getRangeCategory(lastConnection) {
    const now = new Date();
    const diff = differenceInDays(now, lastConnection);
    if (diff === 0) return "Today";
    if (diff === 1) return "Yesterday";
    if (diff < 7) return "Last 7 days";
    if (diff < 30) return "Last 30 days";
    return "+30 days";
}

function getRangeCategoryColor(rangeCategory) {
    switch (rangeCategory) {
        case "Today":
            return "#c8e6c9"; // verde suave
        case "Yesterday":
            return "#dcedc8";
        case "Last 7 days":
            return "#fff9c4"; // amarillo suave
        case "Last 30 days":
            return "#ffe0b2"; // naranja suave
        default:
            return "#ffc4b2"; // tono rojizo suave
    }
}

const SitesUserActivity = ({ data = [] }) => {
    const [sitesSummary, setSitesSummary] = useState([]);

    // Para el modal:
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedSiteEmails, setSelectedSiteEmails] = useState([]);

    // ---- Cálculos / Resumen por sitio ----
    const computeSitesSummary = (records) => {
        const sitesMap = {};

        records.forEach((item) => {
            const siteIds = (item.hotel_id || "")
                .split(",")
                .map((s) => s.trim());
            const siteNames = (item.SiteName || "")
                .split(",")
                .map((s) => s.trim());

            siteIds.forEach((siteId, idx) => {
                const thisSiteName = siteNames[idx] || siteId;

                if (!sitesMap[siteId]) {
                    sitesMap[siteId] = {
                        siteId,
                        siteName: thisSiteName,
                        records: [],
                    };
                }
                sitesMap[siteId].records.push(item);
            });
        });

        // Calcula estadísticas
        const summaries = Object.values(sitesMap).map((siteObj) => {
            const { siteId, siteName, records } = siteObj;

            // Distinct Users
            const userSet = new Set();
            // Distinct Sessions
            const sessionSet = new Set();
            // Última conexión
            let lastConnectionTime = 0;

            records.forEach((r) => {
                userSet.add(r.email || "(unknown)");
                sessionSet.add(r.sessionId || "(no-session)");
                const ts = new Date(r.ts).getTime();
                if (ts > lastConnectionTime) {
                    lastConnectionTime = ts;
                }
            });

            const distinctUsersCount = userSet.size;
            const distinctSessionsCount = sessionSet.size;
            const lastConnection = lastConnectionTime
                ? new Date(lastConnectionTime)
                : null;

            const rangeCategory = lastConnection
                ? getRangeCategory(lastConnection)
                : "No data";

            return {
                siteId,
                siteName,
                distinctUsersCount,
                distinctSessionsCount,
                lastConnection,
                rangeCategory,
                records,
                // ojo, aquí guardamos los "records" por si queremos usarlos luego
            };
        });

        // Ordenar por la última conexión (desc)
        summaries.sort((a, b) => {
            if (!a.lastConnection && !b.lastConnection) return 0;
            if (!a.lastConnection) return 1;
            if (!b.lastConnection) return -1;
            return b.lastConnection - a.lastConnection;
        });

        return summaries;
    };

    useEffect(() => {
        if (data.length === 0) {
            setSitesSummary([]);
            return;
        }
        setSitesSummary(computeSitesSummary(data));
    }, [data]);

    // ---- Manejador para abrir el diálogo con los usuarios ----
    const handleCardClick = (site) => {
        // Sacamos los emails de site.records
        // Así evitamos duplicados, si queremos
        const emails = Array.from(
            new Set(site.records.map((r) => r.email || "(unknown)"))
        ).sort();

        setSelectedSite(site);
        setSelectedSiteEmails(emails);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedSite(null);
        setSelectedSiteEmails([]);
    };

    // ---- Render principal ----
    return (
        <>
            <Grid container spacing={2} sx={{ marginTop: 4 }}>
                {sitesSummary.map((site) => {
                    const {
                        siteId,
                        siteName,
                        distinctUsersCount,
                        distinctSessionsCount,
                        lastConnection,
                        rangeCategory,
                    } = site;

                    const lastConnectionStr = lastConnection
                        ? format(lastConnection, "yyyy-MM-dd HH:mm:ss")
                        : "No data";
                    const bgColor = getRangeCategoryColor(rangeCategory);

                    // Avatares e imágenes (opcional):
                    const siteIdNumeric = parseInt(siteId, 10);
                    const hotelInfo = HOTELS_INFO[siteIdNumeric] || {};
                    const avatarSrc = hotelInfo.img;

                    return (
                        <Grid item xs={12} sm={6} md={4} key={siteId}>
                            <Card
                                // Asegúrate de que se muestre un puntero de mano al hover
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleCardClick(site)}
                            >
                                <CardHeader
                                    avatar={
                                        <Avatar alt={siteName} src={avatarSrc}>
                                            {siteName?.charAt(0) || "?"}
                                        </Avatar>
                                    }
                                    title={siteName}
                                    subheader={`ID: ${siteId}`}
                                />
                                <CardContent>
                                    <Typography variant="body2">
                                        <strong>Number of users:</strong>{" "}
                                        {distinctUsersCount}
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Number of sessions:</strong>{" "}
                                        {distinctSessionsCount}
                                    </Typography>
                                    <Typography variant="body2">
                                        <strong>Last connection:</strong>{" "}
                                        {lastConnectionStr}
                                    </Typography>
                                    <Box
                                        mt={1}
                                        sx={{
                                            display: "inline-block",
                                            backgroundColor: bgColor,
                                            padding: "4px 8px",
                                            borderRadius: 4,
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            align="center"
                                        >
                                            {rangeCategory}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>

            {/* Dialog (Modal) con la lista de usuarios */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="xs"
                fullWidth
            >
                {selectedSite && (
                    <DialogTitle>
                        <Stack direction="row" spacing={2}>
                            <Avatar
                                sx={{ mr: 2 }}
                                alt={selectedSite.siteName ?? ""}
                                src={HOTELS_INFO[selectedSite.siteId ?? 0].img}
                            >
                                {selectedSite.siteName?.charAt(0) || "?"}
                            </Avatar>
                            {selectedSite ? selectedSite.siteName : "Details"}
                        </Stack>
                    </DialogTitle>
                )}
                <DialogContent dividers>
                    <Typography
                        variant="subtitle2"
                        gutterBottom
                        fontWeight={"bold"}
                    >
                        {selectedSite ? selectedSite.distinctUsersCount : 0}{" "}
                        User(s):
                    </Typography>
                    <List>
                        {selectedSiteEmails.map((email) => (
                            <ListItem key={email} dense disableGutters>
                                <ListItemText primary={email} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SitesUserActivity;
