import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ButtonBase, Container, Grid2, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { clean } from "../actions/PDFExport";

import PDFReport from "../components/PDFReport";
import PDFBuilder from "../components/PDFBuilder";
import Icons from "../helpers/icons";

/* TODO: This will be a screen like MonthlyReportScreen, where:
- /pdfexport -> List of templates of pdf(report arrays with ID associated) to generate and download
- /pdfexport/create -> User can save and transform a report from other screen to a PDF 
- /pdfexport/:reportID -> Go to an existing template associated to that reportID
*/
const PDFExportScreen = () => {
    const dispatch = useDispatch();

    let { reportId } = useParams();

    useEffect(() => {
        return () => {
            dispatch(clean());
        };
    }, []);

    return (
        <>
            {reportId ? (
                <>
                    <PDFReport reportId={reportId} isCollapsible={false} />
                </>
            ) : (
                <>
                    <Container maxWidth={false}>
                        <Grid2 container direction={"column"}>
                            <Grid2 item sx={{ mt: 2, mb: 1 }} xs={12}>
                                <Typography variant="h4">PDF Export</Typography>
                            </Grid2>
                            <Grid2 item sx={{ mt: 0, mb: 2 }} xs={12}>
                                <ButtonBase
                                    variant="contained"
                                    startIcon={Icons.ArrowLeft}
                                    component={Link}
                                    onClick={() => window.history.back()}
                                >
                                    <Icons.ArrowLeft fontSize={"1em"} />
                                    <Typography>Exit PDF Export</Typography>
                                </ButtonBase>
                            </Grid2>
                        </Grid2>

                        <PDFBuilder />
                    </Container>
                    <br />
                    <br />
                    <br />
                </>
            )}
        </>
    );
};
export default PDFExportScreen;
