// CommissionScreen.jsx

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Grid,
    Typography,
    FormControl,
    Select,
    MenuItem,
    Box,
    TextField,
    Container,
    Stack,
    Table,
    TableBody,
} from "@mui/material";

import {
    PRESET_CHOOSE_MONTH,
    PRESET_CUSTOM,
    PRESET_BOOKING_GROUP,
    PresetDates,
    formatDate,
    GROUP_DATE_DAY,
    GROUP_DATE_MONTH,
    GROUP_DATE_YEAR,
    groupBy,
    PRESET_THIS_YEAR,
    PRESET_THIS_MONTH,
    PRESET_MONTH_TO_DATE,
} from "../helpers/filters";

import { fields_Commission } from "../components/shared/ReportBuilder/ReportBuilder";

import { processCommission } from "../helpers/reports";
import Colours from "../helpers/colours";
import { getYearsBefore, MAX_DATE, MIN_DATE } from "../helpers/dates";

import DateRangePicker from "../components/DateRangePicker";

import { setJS } from "../actions/globalReportFilters";
import ReportTableMetricsSummary from "../components/shared/ReportBuilder/ReportTableMetricsSummary";
import CommissionTable from "../components/CommissionTable";
import FiltersByClient from "../components/FiltersByClient";
import NoDataRow from "../components/NoDataRow";
import HoverPaper from "../components/shared/HoverPaper";
import { useNavigate } from "react-router-dom";

import DataLoader from "../components/DataLoader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/en-ie";
dayjs.extend(utc);
dayjs.locale("en-ie");

const ITEM_HEIGHT = "50px";

const CommissionFilters = ({
    handleProcessedData,
    handleJS,
    handleSelectedHotels,
    handleSelectedCurrencies,
}) => {
    const js = useSelector((state) => state.globalReportFilters);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { dataCommission } = useSelector((state) => state);

    const [openDatePicker, setOpenDatePicker] = useState(false);

    // const [auxPresetDate, setAuxPresetDate] = useState({});
    // const [lenProcessed, setLenProcessed] = useState(0);
    // const [processedData, setProcessedData] = useState([]);
    // const [processed, setProcessed] = useState([]);

    const [selectedHotels, setSelectedHotels] = useState([]);
    const [selectedCurrencies, setSelectedCurrencies] = useState([]);

    useEffect(() => {
        // const { fromDay, toDay } = PresetDates(PRESET_THIS_YEAR, {
        //     year: new Date().getUTCFullYear(),
        //     month: new Date().getUTCMonth(),
        // });

        const current_year = new Date().getUTCFullYear();
        const current_month = new Date().getUTCMonth();

        const fromDay = new Date(current_year, current_month - 5, 1);
        const toDay = new Date(current_year, current_month + 6, 1);

        dispatch(
            setJS({
                ...js,
                fromDay,
                toDay,
                presetDate: PRESET_CUSTOM,
            })
        );

        return () => {
            // console.log("Route is about to change");
            const { fromDay, toDay } = PresetDates(PRESET_MONTH_TO_DATE, {
                year: new Date().getUTCFullYear(),
                month: new Date().getUTCMonth(),
            });
            dispatch(
                setJS({
                    ...js,
                    fromDay,
                    toDay,
                    presetDate: PRESET_MONTH_TO_DATE,
                })
            );
        };
    }, [navigate]);

    //  ___
    // | _ \_ _ ___  __ ___ ______
    // |  _/ '_/ _ \/ _/ -_|_-<_-<
    // |_| |_| \___/\__\___/__/__/
    useEffect(() => {
        if (dataCommission.status !== "loaded") {
            return;
        }

        let dataToUse = dataCommission.data.data ?? [];
        console.log({ dataToUse, dataCommission });

        // let dataToUse = JSON.parse(localStorage.getItem("commission"));

        const { toDay, fromDay } = { fromDay: js.fromDay, toDay: js.toDay };
        const { Hotels = [] } = js.filters ?? {};

        let fromDayOneYearBefore = new Date(fromDay);
        fromDayOneYearBefore.setFullYear(
            fromDayOneYearBefore.getFullYear() - 1
        );
        fromDayOneYearBefore = formatDate(fromDayOneYearBefore);
        let toDayOneYearBefore = new Date(toDay);
        toDayOneYearBefore.setFullYear(toDayOneYearBefore.getFullYear() - 1);
        toDayOneYearBefore = formatDate(toDayOneYearBefore);

        // decides if the element must be included according to the Cancellation filter
        // const FilterCancellations = (element) =>
        //     CANCELLATIONS_FILTER[Cancellations].includeCancellation(element);

        const FilterData = (
            data,
            from_,
            to_,
            selectedHotels,
            selectedCurrencies
        ) => {
            // console.log(data);

            let aux = data.filter((e) => {
                let date = new Date(e.CheckOut);

                let siteName = e.SiteName;
                let currencyCode = e.CurrencyCode;
                // let filteredCancellation = FilterCancellations(e);

                let isHotel = true;

                if (Hotels.length > 0) {
                    const { Hotel } = e;
                    isHotel = Hotels.includes(Hotel);
                }

                let toPlusOneDay = new Date(to_);
                toPlusOneDay.setDate(toPlusOneDay.getDate() + 1);

                return (
                    (selectedHotels.length === 0 ||
                        selectedHotels.includes(siteName)) &&
                    (selectedCurrencies.length === 0 ||
                        selectedCurrencies.includes(currencyCode)) &&
                    new Date(from_) <= date &&
                    date < toPlusOneDay &&
                    isHotel
                );
            });

            return aux.map((e) => {
                let date = new Date(e.CheckOut);
                return {
                    ...e,
                    [GROUP_DATE_DAY]: date.getUTCDate(),
                    [GROUP_DATE_MONTH]: date.getUTCMonth() + 1,
                    [GROUP_DATE_YEAR]: date.getUTCFullYear(),
                    // [GROUP_DATE_WEEK]: Math.ceil(
                    //     ((date - new Date(date.getUTCFullYear(), 0, 1)) /
                    //         (1000 * 60 * 60 * 24) + //one day: (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
                    //         1) /
                    //         7
                    // ),
                    selectedDate: formatDate(date),
                };
            });
        };

        let dataToUseLastYear = FilterData(
            dataToUse,
            fromDayOneYearBefore,
            toDayOneYearBefore,
            selectedHotels,
            selectedCurrencies
        );

        dataToUse = FilterData(
            dataToUse,
            fromDay,
            toDay,
            selectedHotels,
            selectedCurrencies
        );

        const LENGTH = dataToUse.length;

        // group values by types
        // let keys_groups = [...(js.groupBy ?? [])]; // ...timeGroups];

        let keys_groups = [...[]]; // ...timeGroups];

        // console.log(keys_groups);

        // Fix case when no keys because it is voucher type
        dataToUse.forEach((obj) => {
            if (obj.ResTypeID === 4) {
                keys_groups.forEach((key) => {
                    if (obj[key] === null) {
                        obj[key] = "Voucher";
                    }
                });
            }
        });
        dataToUseLastYear.forEach((obj) => {
            if (obj.ResTypeID === 4) {
                keys_groups.forEach((key) => {
                    if (obj[key] === null) {
                        obj[key] = "Voucher";
                    }
                });
            }
        });

        let groups = groupBy(dataToUse, ...keys_groups);
        let groupsLastYear = groupBy(
            dataToUseLastYear.map((e) => {
                let e_ = { ...e, [GROUP_DATE_YEAR]: e[GROUP_DATE_YEAR] + 1 }; // Here we need to add 1 to the year to match the key
                return e_;
            }),
            ...keys_groups
        );

        // process each group
        //TODO:review cancellation rate
        let entries = Object.entries(groups)
            .map(([k, d]) => {
                return {
                    [k]: {
                        result: processCommission(d),
                        keys: keys_groups.map((key) => [key, d[0][key]]),
                        data: d,
                    },
                };
            })
            .reduce((newObj, v) => {
                return { ...newObj, ...v };
            }, {});

        Object.entries(groupsLastYear).forEach(([k, d]) => {
            if (entries[k]) {
                entries[k].resultOneYearBefore = processCommission(d);
            }
        });

        // setLenProcessed(LENGTH);
        // setProcessedData(entries);
        // setProcessed(entries);

        let aux_js = {
            // dataType: js["dataType"],
            // filters: js["filters"],
            // fromDay: js["fromDay"],
            // groupBy: js["groupBy"],
            // presetDate: js["presetDate"],
            // toDay: js["toDay"],
            ...js,
            ...fields_Commission,
        };

        // console.log(aux_js);
        handleJS(aux_js);

        handleProcessedData(entries);
    }, [js, dataCommission, selectedHotels, selectedCurrencies]);

    //  ___             _
    // | _ \___ _ _  __| |___ _ _
    // |   / -_) ' \/ _` / -_) '_|
    // |_|_\___|_||_\__,_\___|_|

    return (
        <>
            <DataLoader Commission />

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
                spacing={1}
            >
                {/*  _____ ___ __  __ _____   ____  _____ ____  ___ ___  ____   */}
                {/* |_   _|_ _|  \/  | ____| |  _ \| ____|  _ \|_ _/ _ \|  _ \  */}
                {/*   | |  | || |\/| |  _|   | |_) |  _| | |_) || | | | | | | | */}
                {/*   | |  | || |  | | |___  |  __/| |___|  _ < | | |_| | |_| | */}
                {/*   |_| |___|_|  |_|_____| |_|   |_____|_| \_\___\___/|____/  */}
                <Grid item>
                    <Typography>Time period</Typography>
                    <FormControl size="small">
                        <Select
                            labelId="timePeriodLabel"
                            id="timePeriodSelect"
                            value={js.presetDate ?? PRESET_CUSTOM}
                            onChange={(e, v) => {
                                if (v.props.value === PRESET_CUSTOM) {
                                    const { fromDay, toDay } = PresetDates(
                                        PRESET_THIS_YEAR,
                                        {
                                            year: new Date().getUTCFullYear(),
                                            month: new Date().getUTCMonth(),
                                        }
                                    );
                                    dispatch(
                                        setJS({
                                            ...js,
                                            fromDay,
                                            toDay,
                                            presetDate: v.props.value,
                                        })
                                    );
                                    // setOpenDatePicker(true);
                                } else {
                                    setOpenDatePicker(false);

                                    const { fromDay, toDay } = PresetDates(
                                        v.props.value,
                                        {
                                            year: new Date().getUTCFullYear(),
                                            month: new Date().getUTCMonth(),
                                        }
                                    );

                                    dispatch(
                                        setJS({
                                            ...js,
                                            fromDay,
                                            toDay,
                                            presetDate: v.props.value,
                                        })
                                    );
                                }
                            }}
                            sx={{ height: ITEM_HEIGHT }}
                            MenuProps={{ sx: { maxHeight: "280px" } }}
                        >
                            {PRESET_BOOKING_GROUP.map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                            <MenuItem value={PRESET_CUSTOM}>Custom</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/*  _____ ___ __  __ _____   ____      _    _   _  ____ _____  */}
                {/* |_   _|_ _|  \/  | ____| |  _ \    / \  | \ | |/ ___| ____| */}
                {/*   | |  | || |\/| |  _|   | |_) |  / _ \ |  \| | |  _|  _|   */}
                {/*   | |  | || |  | | |___  |  _ <  / ___ \| |\  | |_| | |___  */}
                {/*   |_| |___|_|  |_|_____| |_| \_\/_/   \_\_| \_|\____|_____| */}
                <Grid item>
                    <Typography>Stay date</Typography>

                    <DateRangePicker
                        boxProps={{ sx: { height: ITEM_HEIGHT } }}
                        fromDateProps={{
                            value: dayjs(js.fromDay),
                        }}
                        isRangePicker={
                            js.presetDate !== PRESET_CHOOSE_MONTH // If SINGLE PICKER logic changes to follow fromDate
                        }
                        onChangeFromDate={(newValue) => {
                            let auxChooseMonthToDay =
                                js.presetDate !== PRESET_CHOOSE_MONTH
                                    ? js.toDay
                                    : PresetDates(PRESET_CHOOSE_MONTH, {
                                          year: newValue.year(),
                                          month: newValue.month(),
                                      }).toDay;
                            dispatch(
                                setJS({
                                    ...js,
                                    fromDay: formatDate(newValue),
                                    ...(js.presetDate !== PRESET_CHOOSE_MONTH
                                        ? { presetDate: PRESET_CUSTOM }
                                        : {
                                              toDay: auxChooseMonthToDay,
                                          }),
                                })
                            );
                        }}
                        toDateProps={{ value: dayjs(js.toDay) }}
                        onChangeToDate={(newValue) => {
                            dispatch(
                                setJS({
                                    ...js,
                                    toDay: formatDate(newValue),
                                    presetDate: PRESET_CUSTOM,
                                })
                            );
                        }}
                        open={openDatePicker}
                    />
                </Grid>

                {/* <Grid item>
                    <Typography>Select client</Typography>

                    <FormControl size="small">
                        <Select
                            labelID="selectClientLabel"
                            id="selectClientSelect"
                            value={selectedHotel ?? "All"}
                            onChange={(e, v) => {
                                setselectedHotel(e.target.value);
                            }}
                            sx={{ height: ITEM_HEIGHT }}
                            MenuProps={{ sx: { maxHeight: "280p" } }}
                        >
                            {hotelList.map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> */}
                <Grid item>
                    <FiltersByClient
                        height={ITEM_HEIGHT}
                        hotelsList={
                            dataCommission.status === "loaded"
                                ? dataCommission.data.site_names
                                : []
                        }
                        setSelectedHotels={setSelectedHotels}
                        handleSelectedHotels={handleSelectedHotels}
                        currencies={
                            dataCommission.status === "loaded"
                                ? dataCommission.data.currencies
                                : []
                        }
                        setSelectedCurrencies={setSelectedCurrencies}
                        handleSelectedCurrencies={handleSelectedCurrencies}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const CommissionScreen = () => {
    const { dataCommission } = useSelector((state) => state);

    const [processedData, setProcessedData] = useState({});
    const [JS, setJS] = useState({ hideFields: [], fieldsOrder: [] });

    const [selectedHotels, setSelectedHotels] = useState([]);
    const [selectedCurrencies, setSelectedCurrencies] = useState([]);

    const handleProcessedData = (data) => {
        setProcessedData(data);
    };

    const handleJS = (js) => {
        setJS(js);
    };

    const handleSelectedHotels = (hotels) => {
        setSelectedHotels(hotels);
    };
    const handleSelectedCurrencies = (Currencies) => {
        setSelectedCurrencies(Currencies);
    };

    return (
        <Container maxWidth={false}>
            <Stack sx={{ pt: 2, pb: 2 }} spacing={2}>
                <Typography variant={"h4"}>Commission Report</Typography>

                <Typography variant={"h8"}>
                    'Checked out' revenue without cancellations.
                </Typography>

                <CommissionFilters
                    handleProcessedData={handleProcessedData}
                    handleJS={handleJS}
                    handleSelectedHotels={handleSelectedHotels}
                    handleSelectedCurrencies={handleSelectedCurrencies}
                />

                <>
                    <ReportTableMetricsSummary
                        data={processedData}
                        js={JS}
                        hideExtra={true}
                        place="CommissionScreen"
                    />

                    <CommissionTable
                        data={dataCommission.data?.data ?? []}
                        js={JS}
                        selectedHotels={selectedHotels}
                        selectedCurrencies={selectedCurrencies}
                    />
                </>
            </Stack>
        </Container>
    );
};

export default CommissionScreen;
