import React, { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";

const MIN_SESSIONS_TO_EXCLUDE_OUTLIERS = 5;
const OUTLIER_PERCENTAGE = 0.1;

const UserConnectionsTable = ({ data }) => {
    const [usersSummary, setUsersSummary] = useState([]);

    //     ____ ___  _    _   _ __  __ _   _ ____
    //   / ___/ _ \| |  | | | |  \/  | \ | / ___|
    //  | |  | | | | |  | | | | |\/| |  \| \___ \
    //  | |__| |_| | |__| |_| | |  | | |\  |___) |
    //   \____\___/|_____\___/|_|  |_|_| \_|____/
    const userStatsColumns = [
        {
            field: "email",
            headerName: "User",
            width: 300,
        },
        {
            field: "isAro",
            headerName: "Is Aró?",
            width: 120,
        },
        {
            field: "lastConnection",
            headerName: "Last Connection",
            width: 180,
            valueFormatter: (params) => {
                if (!params.value) return "";
                return format(params.value, "yyyy-MM-dd HH:mm:ss");
            },
        },
        {
            field: "rangeCategory",
            headerName: "Range Category",
            width: 150,
            renderCell: (params) => {
                const range = params.value;
                const bgColor = getRangeCategoryColor(range);
                return (
                    <div
                        style={{
                            backgroundColor: bgColor,
                            padding: "4px 8px",
                            borderRadius: 4,
                            width: "100%",
                            textAlign: "center",
                        }}
                    >
                        {range}
                    </div>
                );
            },
        },
        {
            field: "distinctDays",
            headerName: "Distinct Days",
            width: 150,
        },
        {
            field: "distinctDaysLast30",
            headerName: "Distinct Days (Last 30)",
            width: 190,
        },
        {
            field: "avgSessionTime",
            headerName: "Avg Session Time (min)",
            width: 220,
            valueFormatter: (params) => {
                if (!params.value) return "0";
                return params.value.toFixed(2);
            },
        },
        {
            field: "SiteName",
            headerName: "Hotel",
            width: 200,
        },
    ];

    const differenceInDays = (date1, date2) => {
        const msInDay = 24 * 60 * 60 * 1000;
        return Math.floor((date1 - date2) / msInDay);
    };

    const getRangeCategory = (lastConnection) => {
        const now = new Date();
        const diff = differenceInDays(now, lastConnection); // días transcurridos

        if (diff === 0) return "Today";
        if (diff === 1) return "Yesterday";
        if (diff < 7) return "Last 7 days";
        if (diff < 30) return "Last 30 days";
        return "+30 days";
    };

    const getRangeCategoryColor = (rangeCategory) => {
        switch (rangeCategory) {
            case "Today":
                return "#c8e6c9"; // verde suave
            case "Yesterday":
                return "#dcedc8"; // otro tono de verde
            case "Last 7 days":
                return "#fff9c4"; // amarillo suave
            case "Last 30 days":
                return "#ffe0b2"; // naranja muy suave
            default:
                return "#FFC4B2FF"; // gris claro
        }
    };

    const getUsersSummary = (data) => {
        // Mapa: email => array de todos sus registros
        const mapUserToRecords = {};
        data.forEach((item) => {
            const email = item.email || "(Unknown)";
            if (!mapUserToRecords[email]) {
                mapUserToRecords[email] = [];
            }
            mapUserToRecords[email].push(item);
        });

        const now = new Date();

        return Object.entries(mapUserToRecords).map(([email, records]) => {
            // 1) Última conexión
            const dates = records.map((r) => new Date(r.ts));
            const lastConnection = new Date(
                Math.max(...dates.map((d) => d.getTime()))
            );
            // 2) Rango
            const rangeCategory = getRangeCategory(lastConnection);

            // 3) Días distintos en total
            const distinctDaysSet = new Set(
                dates.map((d) => d.toISOString().slice(0, 10))
            );
            const distinctDays = distinctDaysSet.size;

            // 4) Días distintos en los últimos 30
            const thirtyDaysAgo = new Date(
                now.getTime() - 30 * 24 * 60 * 60 * 1000
            );
            const distinctDaysLast30 = Array.from(distinctDaysSet).filter(
                (dayStr) => {
                    const dayDate = new Date(dayStr);
                    return dayDate >= thirtyDaysAgo;
                }
            ).length;

            // 5) ¿Es Aro?
            const isAro = email.includes("@aro.ie");

            // 6) Cálculo de tiempos de sesión (min)
            // Mapa: sessionId => { min: Date, max: Date }
            const sessionMap = {};
            records.forEach((r) => {
                const sId = r.sessionId;
                const tsDate = new Date(r.ts);

                if (!sessionMap[sId]) {
                    sessionMap[sId] = { min: tsDate, max: tsDate };
                } else {
                    // Actualiza min/max
                    if (tsDate < sessionMap[sId].min)
                        sessionMap[sId].min = tsDate;
                    if (tsDate > sessionMap[sId].max)
                        sessionMap[sId].max = tsDate;
                }
            });

            // Array de duraciones (en minutos) de cada sesión
            let sessionDurations = Object.values(sessionMap).map(
                ({ min, max }) => {
                    return (max - min) / 1000 / 60; // ms => min
                }
            );

            // Solo quitamos outliers si hay más de X sesiones
            if (sessionDurations.length > MIN_SESSIONS_TO_EXCLUDE_OUTLIERS) {
                sessionDurations.sort((a, b) => a - b);
                // Cuántos elementos quitar arriba y abajo
                const cutoff = Math.floor(
                    sessionDurations.length * OUTLIER_PERCENTAGE
                );
                // Si cutoff > 0, quitamos los outliers
                if (cutoff > 0 && 2 * cutoff < sessionDurations.length) {
                    sessionDurations = sessionDurations.slice(
                        cutoff,
                        sessionDurations.length - cutoff
                    );
                }
            }

            const avgSessionTime =
                sessionDurations.reduce((acc, val) => acc + val, 0) /
                    sessionDurations.length || 0;

            const SiteName = records?.[0]?.SiteName || "";

            return {
                id: email,
                email,
                isAro,
                lastConnection,
                rangeCategory,
                distinctDays,
                distinctDaysLast30,
                avgSessionTime,
                SiteName,
            };
        });
    };

    useEffect(() => {
        if (data && data.length > 0) {
            setUsersSummary(getUsersSummary(data));
        } else {
            setUsersSummary([]);
        }
    }, [data]);

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
                All Users & Last Connection
            </Typography>
            <Box
                sx={{
                    // altura fija, con scroll vertical
                    height: 500,
                    overflowY: "auto",
                    // truco para pegar el header de la DataGrid
                    "& .MuiDataGrid-columnHeaders": {
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "#f7f7f7",
                    },
                }}
            >
                <DataGrid
                    rows={usersSummary}
                    columns={userStatsColumns}
                    pageSize={100}
                    disableSelectionOnClick
                    initialState={{
                        sorting: {
                            sortModel: [
                                { field: "lastConnection", sort: "desc" },
                            ],
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default UserConnectionsTable;
