import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Axios from "axios";

import {
    AROLOGO,
    DEFAULT_HOTEL,
    ILH_HOTEL_LIST,
    URLAPI,
    YEARS_BEFORE,
} from "../configuration";

import { months } from "../helpers/dates";

import {
    partialLoad as partialLoad_Membership,
    load as load_Membership,
    loading as loading_Membership,
    error as error_Membership,
} from "../actions/dataMembership";
import {
    load as load_Bookings,
    partialLoad as partialLoad_Bookings,
    loading as loading_Bookings,
    error as error_Bookings,
} from "../actions/dataBookings";
import {
    load as load_VouchersDetails,
    loading as loading_VouchersDetails,
    error as error_VouchersDetails,
} from "../actions/dataVouchersDetails";
import {
    partialLoad as partialLoad_Simple,
    load as load_Simple,
    loading as loading_Simple,
    error as error_Simple,
} from "../actions/dataSimple";
import {
    partialLoad as partialLoad_Searches,
    load as load_Searches,
    loading as loading_Searches,
    error as error_Searches,
} from "../actions/dataSearches";
import {
    // partialLoad as partialLoad_Commission,
    load as load_Commission,
    loading as loading_Commission,
    error as error_Commission,
} from "../actions/dataCommission";

import { load as load_Settings } from "../actions/settings";

import { loadNotifications } from "../actions/Notifications";
import { dataBookingsTypes } from "../types/dataBookingsTypes";

import LoadingBubble from "./LoadingBubble";
import { BUBBLES_DATA } from "./SideMenu";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    CircularProgress,
    LinearProgress,
    Modal,
    Paper,
    Popover,
    Snackbar,
    Stack,
    Typography,
} from "@mui/material";
import ProgressBarSteps from "./shared/ProgressBarSteps";
import Colours from "../helpers/colours";
import Icons from "../helpers/icons";

export const reservationsToYearMonth = (data, column) => {
    let res = {};
    data.forEach((element) => {
        const year_ = new Date(element[column]).getUTCFullYear();
        const month_ = new Date(element[column]).getUTCMonth();
        if (!res.hasOwnProperty(year_)) res[year_] = {};
        if (!res[year_].hasOwnProperty(months[month_]))
            res[year_][months[month_]] = [];
        res[year_][months[month_]].push(element);
    });

    return res;
};

export const MONTHS_AGO = 24;
export const timesFirstReservations = () => {
    const year = new Date().getUTCFullYear();
    const currentMonth = new Date().getUTCMonth();
    const fromTime = new Date(Date.UTC(year, currentMonth - MONTHS_AGO, 1)); // HERE
    const toTime = new Date(Date.UTC(year + 2, 0, 0)); // Actual Year + 1
    return { year, currentMonth, fromTime, toTime };
};
export const timesSecondReservations = () => {
    const year = new Date().getUTCFullYear();
    const currentMonth = new Date().getUTCMonth();
    const fromTime = new Date(Date.UTC(year - YEARS_BEFORE, 0, 1));
    const toTime = new Date(Date.UTC(year, currentMonth - MONTHS_AGO, 1));
    return { year, currentMonth, fromTime, toTime };
};

const DataLoader = ({
    Reservations,
    VouchersDetails,
    SimpleData,
    Searches,
    Commission,
}) => {
    const dispatch = useDispatch();
    const { id: auxHotelID } = useSelector((state) => state.hotelID);
    const [hotelID, setHotelID] = useState("default");

    const authRoles = useSelector((state) => state.authRoles.edited);
    const authHotels = useSelector((state) => state.authRoles.hotels);

    const { dataSimple } = useSelector((state) => state);
    const { dataBookings } = useSelector((state) => state);
    const { dataVouchersDetails } = useSelector((state) => state);
    const { dataSearches } = useSelector((state) => state);
    const { dataCommission } = useSelector((state) => state);
    const { dataMembership } = useSelector((state) => state);
    const { notifications } = useSelector((state) => state);
    const { settings } = useSelector((state) => state);

    const { auth } = useSelector((state) => state);

    const [waitToLoad, setWaitToLoad] = useState(true);

    const checkCache = async () => {
        try {
            let r = await Axios({
                method: "get",
                url: `${URLAPI}/cache/`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth.user.jwt}`,
                },
                timeout: 10000,
            });
            console.log({ cache_status: r.data.status });
        } catch (error_) {
            console.error("Error in cache");
            console.error(error_);
        }
    };

    //   ____                                _   _
    //  |  _ \ ___  ___  ___ _ ____   ____ _| |_(_) ___  _ __  ___
    //  | |_) / _ \/ __|/ _ \ '__\ \ / / _` | __| |/ _ \| '_ \/ __|
    //  |  _ <  __/\__ \  __/ |   \ V / (_| | |_| | (_) | | | \__ \
    //  |_| \_\___||___/\___|_|    \_/ \__,_|\__|_|\___/|_| |_|___/
    const [timesTried, setTimesTried] = useState(1);
    // First block - This year's data
    useEffect(() => {
        if (
            (authRoles.length === 0 && authHotels.length === 0) ||
            !Reservations ||
            hotelID === "default"
        ) {
            return;
        }
        if (
            hotelID === dataBookings.hotelID &&
            (dataBookings.status === "loaded" ||
                dataBookings.status === "partiallyLoaded")
        ) {
            setWaitToLoad(false); // If data is correctly loaded, set wait to false
            return;
        }

        dispatch(loading_Bookings(hotelID));

        let res = {};

        const { fromTime, toTime } = timesFirstReservations();

        let args = JSON.stringify({
            from: fromTime.toISOString(),
            to: toTime.toISOString(),
            dateBy: "ResDate",
        });

        Axios({
            method: "post",
            url: `${URLAPI}/reservations/${hotelID}`,
            data: args,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
            timeout: 7500 + timesTried * 5000,
        })
            .then((response) => {
                const {
                    data: { data = [], last_ResDate },
                } = response;
                dispatch(partialLoad_Bookings(data, hotelID, last_ResDate));
                setWaitToLoad(false);
            })
            .catch((error_) => {
                checkCache();
                dispatch(error_Bookings(error_, res));
                console.error(error_);
                setWaitToLoad(false);
            });
    }, [hotelID, dataBookings.reload, authHotels]);

    // Second load - Data from a year ago and backwards
    useEffect(() => {
        if (waitToLoad || dataBookings.status !== "partiallyLoaded") return;
        if (
            (authRoles.length === 0 && authHotels.length === 0) ||
            !Reservations ||
            hotelID === "default"
        ) {
            return;
        }

        const { fromTime, toTime } = timesSecondReservations();

        let args = JSON.stringify({
            from: fromTime.toISOString(),
            to: toTime.toISOString(),
            dateBy: "ResDate",
        });
        Axios({
            method: "post",
            url: `${URLAPI}/reservations/${hotelID}`,
            data: args,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
            timeout: 10000 + timesTried * 5000,
        })
            .then((response) => {
                const {
                    data: { data = [], last_ResDate },
                } = response;

                dispatch(
                    load_Bookings(
                        data,
                        hotelID,
                        dataBookings.last_ResDate ?? last_ResDate
                    )
                );
                // setWaitToLoad(false);
            })
            .catch((error_) => {
                // checkCache();
                // dispatch(error_Bookings(error_, res));
                console.error(error_);
                setTimesTried((prev) => prev + 1);
            });
    }, [
        hotelID,
        dataBookings.reload,
        waitToLoad,
        dataBookings.status,
        timesTried,
        authHotels,
    ]);

    // Retry load ResDate / 5 times each 2 seconds each
    useEffect(() => {
        if (waitToLoad) return;
        if (dataBookings.status === "error" && timesTried <= 15) {
            const timeoutId = setTimeout(() => {
                dispatch({ type: dataBookingsTypes.reload });
                setTimesTried((prev) => prev + 1);
            }, 2000);

            return () => clearTimeout(timeoutId);
        }
        if (dataBookings.status === "loaded") {
            setTimesTried(1);
        }
    }, [dataBookings.status, waitToLoad]);

    // If we are showing the grand budapest hotel, it will load Park house
    useEffect(() => {
        if (auxHotelID) {
            if (auxHotelID === "default") setHotelID(DEFAULT_HOTEL);
            else setHotelID(auxHotelID);
        }
    }, [auxHotelID]);

    //   __  __                _                   _     _
    //  |  \/  | ___ _ __ ___ | |__   ___ _ __ ___| |__ (_)_ __
    //  | |\/| |/ _ \ '_ ` _ \| '_ \ / _ \ '__/ __| '_ \| | '_ \
    //  | |  | |  __/ | | | | | |_) |  __/ |  \__ \ | | | | |_) |
    //  |_|  |_|\___|_| |_| |_|_.__/ \___|_|  |___/_| |_|_| .__/
    //                                                    |_|
    const [membershipCache, setMembershipCache] = useState({});
    useEffect(() => {
        if (
            ILH_HOTEL_LIST.includes(hotelID) &&
            dataMembership.status === "loaded"
        ) {
            // console.log({ membership_cache: membershipCache });
        }
    }, [dataMembership.status]);

    useEffect(() => {
        if (waitToLoad) return;
        // console.log({ ILH_HOTEL_LIST, hotelID, dataMembership });
        if (
            ILH_HOTEL_LIST.includes(String(hotelID)) &&
            dataMembership.status === "loaded"
        ) {
            // console.log({ membership_cache: membershipCache });
            return;
        } else if (
            (authRoles.length > 0 || authHotels.length > 0) &&
            ILH_HOTEL_LIST.includes(String(hotelID))
        ) {
            dispatch(loading_Membership(hotelID));

            let endpoints = [];
            let dataToStore = {};
            // TOTAL
            const fetchTotal = async () => {
                try {
                    const response = await Axios.get(
                        `${URLAPI}/membership/total/${92}`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${auth.user.jwt}`,
                            },
                        }
                    );
                    setMembershipCache((old) => {
                        return {
                            ...old,
                            total: {
                                cache: response.data.is_cache,
                                time: response.data.time,
                            },
                        };
                    });
                    dataToStore["Total"] = response.data.data;
                } catch (error) {
                    console.error("Error at Membership Totals:", error);
                    throw error;
                }
            };

            // SIGN UPS
            const fetchSignUps = async () => {
                try {
                    const response = await Axios.get(
                        `${URLAPI}/membership/signup/${92}`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${auth.user.jwt}`,
                            },
                        }
                    );
                    setMembershipCache((old) => {
                        return {
                            ...old,
                            signUps: {
                                cache: response.data.is_cache,
                                time: response.data.time,
                            },
                        };
                    });
                    dataToStore["Signups"] = response.data.data;
                } catch (error) {
                    console.error("Error at Membership SignUps:", error);
                    throw error;
                }
            };

            let startYear = new Date(dataMembership.addYear, 0, 1);
            let endYear = new Date(dataMembership.addYear + 1, 0, 0);
            // REEPEAT BOOKERS
            const fetchRepeatBookers = async () => {
                try {
                    const response = await Axios.get(
                        `${URLAPI}/membership/repeat_bookers`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${auth.user.jwt}`,
                            },
                            params: {
                                from: startYear,
                                to: endYear,
                            },
                        }
                    );
                    setMembershipCache((old) => {
                        return {
                            ...old,
                            RepeatBookers: {
                                cache: response.data.is_cache,
                                time: response.data.time,
                                args: { from: startYear, to: endYear },
                            },
                        };
                    });
                    dataToStore["RepeatBookers"] = {
                        ...((dataMembership.data ?? {})["RepeatBookers"] ?? {}),
                        ...response.data.data,
                    };
                } catch (error) {
                    console.error("Error at Membership RepeatBookers:", error);
                    throw error;
                }
            };

            const mergeData = (obj1, obj2) => {
                let merged = { ...obj1 };

                for (let key in obj2) {
                    if (obj2.hasOwnProperty(key)) {
                        if (merged.hasOwnProperty(key)) {
                            merged[key] = merged[key].concat(obj2[key]);
                        } else {
                            merged[key] = obj2[key];
                        }
                    }
                }
                return merged;
            };

            // BOOKINGS
            const fetchBookings = async () => {
                try {
                    const response = await Axios.get(
                        `${URLAPI}/membership/bookings/${92}`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${auth.user.jwt}`,
                            },
                            params: {
                                from: startYear,
                                to: endYear,
                            },
                        }
                    );
                    setMembershipCache((old) => {
                        return {
                            ...old,
                            bookings: {
                                cache: response.data.is_cache,
                                time: response.data.time,
                                args: { from: startYear, to: endYear },
                            },
                        };
                    });
                    let auxBookings = {
                        ...((dataMembership.data ?? {})["Bookings"] ?? {}),
                    };
                    dataToStore["Bookings"] = mergeData(
                        auxBookings,
                        response.data.data
                    );
                } catch (error) {
                    console.error("Error at Membership Bookings:", error);
                    throw error;
                }
            };

            // DETAILS
            const fetchDetails = async () => {
                try {
                    const response = await Axios.get(
                        `${URLAPI}/membership/details/${92}`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${auth.user.jwt}`,
                            },
                            params: {
                                from: startYear,
                                to: endYear,
                                // useCache: false,
                            },
                        }
                    );
                    setMembershipCache((old) => {
                        return {
                            ...old,
                            details: {
                                cache: response.data.is_cache,
                                time: response.data.time,
                                args: { from: startYear, to: endYear },
                            },
                        };
                    });

                    let auxDetails = {
                        ...((dataMembership.data ?? {})["Details"] ?? {}),
                    };
                    dataToStore["Details"] = mergeData(
                        auxDetails,
                        response.data.data
                    );
                } catch (error) {
                    console.error("Error at Membership Details:", error);
                    throw error;
                }
            };

            endpoints.push(
                fetchTotal().then(() => {
                    dispatch(
                        partialLoad_Membership({ ...dataToStore }, hotelID)
                    );
                })
            );

            endpoints.push(
                fetchRepeatBookers().then(() => {
                    dispatch(
                        partialLoad_Membership({ ...dataToStore }, hotelID)
                    );
                })
            );

            endpoints.push(
                fetchSignUps().then(() => {
                    dispatch(
                        partialLoad_Membership({ ...dataToStore }, hotelID)
                    );
                })
            );

            endpoints.push(
                fetchBookings().then(() => {
                    dispatch(
                        partialLoad_Membership({ ...dataToStore }, hotelID)
                    );
                })
            );

            endpoints.push(
                fetchDetails().then(() => {
                    dispatch(
                        partialLoad_Membership({ ...dataToStore }, hotelID)
                    );
                })
            );

            Promise.all(endpoints)
                .then(() => {
                    dispatch(load_Membership(dataToStore, hotelID));
                })
                .catch((error_) => {
                    dispatch(error_Membership(error_, dataToStore));
                });
        }
    }, [
        hotelID,
        dataMembership.reload,
        dataMembership.addYear,
        waitToLoad,
        authHotels,
    ]);

    //   ____  _                 _
    //  / ___|(_)_ __ ___  _ __ | | ___
    //  \___ \| | '_ ` _ \| '_ \| |/ _ \
    //   ___) | | | | | | | |_) | |  __/
    //  |____/|_|_| |_| |_| .__/|_|\___|
    //                    |_|
    useEffect(() => {
        if (waitToLoad) return;
        if (
            (authRoles.length === 0 && authHotels.length === 0) ||
            !SimpleData ||
            hotelID === "default" ||
            (hotelID === dataSimple.hotelID && dataSimple.status === "loaded")
        ) {
            return;
        }

        const shouldLoad = (endpoint) =>
            // If it is an array, loads only the endpoints from that Array
            // If it is a bool, loads all
            (Array.isArray(SimpleData) && SimpleData.includes(endpoint)) ||
            (!Array.isArray(SimpleData) && SimpleData);

        let dataToStore = { ...dataSimple.data };

        dispatch(loading_Simple(hotelID));

        let endpoints = [];

        //   ___         _ _         _
        //  |_ _|_ _  __| (_)__ __ _| |_ ___ _ _ ___
        //   | || ' \/ _` | / _/ _` |  _/ _ \ '_(_-<
        //  |___|_||_\__,_|_\__\__,_|\__\___/_| /__/
        const fetchIndicators = async () => {
            try {
                const response = await Axios.get(
                    `${URLAPI}/indicators/${hotelID}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${auth.user.jwt}`,
                        },
                    }
                );
                dataToStore["Indicators"] = response.data.data;
            } catch (error) {
                console.error("Error at Indicators:", error);
                throw error;
            }
        };
        //   _           _   ___
        //  | |   ___ __| |_| _ \_____ _____ _ _ _  _ ___
        //  | |__/ _ (_-<  _|   / -_) V / -_) ' \ || / -_)
        //  |____\___/__/\__|_|_\___|\_/\___|_||_\_,_\___|
        const fetchLostRevenueSummary = async () => {
            try {
                const response = await Axios({
                    method: "post",
                    url: `${URLAPI}/lost_revenue/summary/${hotelID}`,
                    data: {},
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                });
                dataToStore["LostRevenueSummary"] = response.data.data;
            } catch (error) {
                console.error("Error at LostRevenueSummary:", error);
                throw error;
            }
        };

        //   __  __         _   _    ___
        //  |  \/  |___ _ _| |_| |_ / __|_  _ _ __  _ __  __ _ _ _ _  _
        //  | |\/| / _ \ ' \  _| ' \\__ \ || | '  \| '  \/ _` | '_| || |
        //  |_|  |_\___/_||_\__|_||_|___/\_,_|_|_|_|_|_|_\__,_|_|  \_, |
        //                                                         |__/
        const fetchMonthlySummary = async () => {
            try {
                const response = await Axios({
                    method: "get",
                    url: `${URLAPI}/month_summary/${hotelID}`,
                    params: {
                        from: `${new Date().getUTCFullYear()}-01-01`,
                        to: `${new Date().getUTCFullYear() + 2}-01-01`,
                    },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                });
                dataToStore["MonthSummary"] = response.data.data;
            } catch (error) {
                console.error("Error at MonthSummary:", error);
                throw error;
            }
        };

        // ___             _
        // | __|_ _____ _ _| |_ ___
        // | _|\ V / -_) ' \  _(_-<
        // |___|\_/\___|_||_\__/__/

        const fetchEvents = async () => {
            try {
                const response = await Axios({
                    method: "get",
                    // Not working with deployed API
                    url: `${URLAPI}/events/${
                        hotelID === "default" ? -1 : hotelID //DEFAULT_HOTEL : hotelID
                    }`,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                });
                dataToStore["Events"] = response.data.data;
            } catch (error) {
                console.error("Error at Events:", error);
                throw error;
            }
        };

        const addEndpoint = (endpointFunc) => {
            endpoints.push(
                endpointFunc().then(() => {
                    dispatch(partialLoad_Simple({ ...dataToStore }, hotelID));
                })
            );
        };

        if (shouldLoad("Indicators")) {
            addEndpoint(fetchIndicators);
        }

        if (shouldLoad("LostRevenueSummary")) {
            addEndpoint(fetchLostRevenueSummary);
        }

        if (shouldLoad("MonthSummary")) {
            addEndpoint(fetchMonthlySummary);
        }

        if (shouldLoad("Events")) {
            addEndpoint(fetchEvents);
        }

        Promise.all(endpoints)
            .then(() => {
                dispatch(load_Simple(dataToStore, hotelID));
            })
            .catch((error_) => {
                dispatch(error_Simple(error_, dataToStore));
            });
    }, [hotelID, dataSimple.reload, waitToLoad, authHotels]);

    //  __     __               _                   ____       _        _ _
    //  \ \   / /__  _   _  ___| |__   ___ _ __ ___|  _ \  ___| |_ __ _(_) |___
    //   \ \ / / _ \| | | |/ __| '_ \ / _ \ '__/ __| | | |/ _ \ __/ _` | | / __|
    //    \ V / (_) | |_| | (__| | | |  __/ |  \__ \ |_| |  __/ || (_| | | \__ \
    //     \_/ \___/ \__,_|\___|_| |_|\___|_|  |___/____/ \___|\__\__,_|_|_|___/
    useEffect(() => {
        if (waitToLoad) return;
        if (
            (authRoles.length === 0 && authHotels.length === 0) ||
            !VouchersDetails ||
            hotelID === "default" ||
            (hotelID === dataVouchersDetails.hotelID &&
                dataVouchersDetails.status === "loaded")
        ) {
            return;
        }

        dispatch(loading_VouchersDetails(hotelID));
        const year = new Date().getUTCFullYear();
        const fromTime = new Date(year - YEARS_BEFORE, 0, 1);
        const toTime = new Date(year + 2, 0, 0); // Actual Year + 1

        let args = JSON.stringify({
            from: fromTime,
            to: toTime,
        });
        let res = {};
        Axios({
            method: "post",
            url: `${URLAPI}/vouchers_details/${hotelID}`,
            data: args,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.user.jwt}`,
            },
        })
            .then((response) => {
                const {
                    data: { data },
                } = response;

                data.forEach((element) => {
                    const { ResDate } = element;
                    const year_ = new Date(ResDate).getUTCFullYear();
                    const month_ = new Date(ResDate).getUTCMonth();
                    if (!res.hasOwnProperty(year_)) res[year_] = {};
                    if (!res[year_].hasOwnProperty(months[month_]))
                        res[year_][months[month_]] = [];

                    res[year_][months[month_]].push(element);
                });
                return dispatch(load_VouchersDetails(res, hotelID));
            })
            .catch((error_) => {
                dispatch(error_VouchersDetails(error_, res));
            });
    }, [hotelID, dataVouchersDetails.reload, waitToLoad, authHotels]);

    //      _____                     _
    //     / ____|                   | |
    //    | (___   ___  __ _ _ __ ___| |__   ___  ___
    //     \___ \ / _ \/ _` | '__/ __| '_ \ / _ \/ __|
    //     ____) |  __/ (_| | | | (__| | | |  __/\__ \
    //    |_____/ \___|\__,_|_|  \___|_| |_|\___||___/
    useEffect(() => {
        if (waitToLoad) return;
        if (
            (authRoles.length === 0 && authHotels.length === 0) ||
            !Searches ||
            hotelID === "default" ||
            (hotelID === dataSearches.hotelID &&
                dataSearches.status === "loaded")
        ) {
            return;
        }

        let dataToStore = {};

        dispatch(loading_Searches(hotelID));
        Promise.all([
            //   _           _   ___
            //  | |   ___ __| |_| _ \_____ _____ _ _ _  _ ___
            //  | |__/ _ (_-<  _|   / -_) V / -_) ' \ || / -_)
            //  |____\___/__/\__|_|_\___|\_/\___|_||_\_,_\___|
            (async () => {
                const response = await Axios({
                    method: "post",
                    url: `${URLAPI}/lost_revenue/summary/${hotelID}`,
                    data: {},
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                });

                return response.data.data;
            })()
                .then((data) => {
                    dataToStore["LostRevenueSummary"] = data;
                    dispatch(partialLoad_Searches(dataToStore, hotelID));
                })
                .catch((error) => {
                    console.error(error);
                }),

            //      _    _ _ ____                      _
            //     / \  | | / ___|  ___  __ _ _ __ ___| |__   ___  ___
            //    / _ \ | | \___ \ / _ \/ _` | '__/ __| '_ \ / _ \/ __|
            //   / ___ \| | |___) |  __/ (_| | | | (__| | | |  __/\__ \
            //  /_/   \_\_|_|____/ \___|\__,_|_|  \___|_| |_|\___||___/
            (async () => {
                const response = await Axios({
                    method: "post",
                    url: `${URLAPI}/search_requests/summary/${hotelID}`,
                    params: {
                        from: `${new Date().getUTCFullYear() - 5}-01-01`,
                        to: `${new Date().getUTCFullYear() + 1}-01-01`,
                    },
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                });
                return response.data.data;
            })()
                .then((data) => {
                    dataToStore["AllSearches"] = data;
                    dispatch(partialLoad_Searches(dataToStore, hotelID));
                })
                .catch((error) => {
                    console.error(error);
                }),
        ])
            .then(() => {
                dispatch(load_Searches(dataToStore, hotelID));
            })
            .catch((error_) => {
                dispatch(error_Searches(error_, dataToStore));
            });
    }, [hotelID, dataSearches.reload, waitToLoad, authHotels]);

    //      _____                          _         _
    //     / ____|                        (_)       (_)
    //    | |     ___  _ __ ___  _ __ ___  _ ___ ___ _  ___  _ __
    //    | |    / _ \| '_ ` _ \| '_ ` _ \| / __/ __| |/ _ \| '_ \
    //    | |___| (_) | | | | | | | | | | | \__ \__ \ | (_) | | | |
    //     \_____\___/|_| |_| |_|_| |_| |_|_|___/___/_|\___/|_| |_|
    useEffect(() => {
        // if (waitToLoad) return;
        if (
            (authRoles.length === 0 && authHotels.length === 0) ||
            !Commission ||
            dataCommission.status === "loaded"
        ) {
            return;
        }
        dispatch(loading_Commission(hotelID));

        let res = {};

        const year = new Date().getUTCFullYear();
        const month = new Date().getUTCMonth();
        const day = new Date().getUTCDate();

        const firstDayOfMonth = day === 1 ? true : false;

        // Args to use for cache

        // 2023-01-01 to end of last month
        const from_cache = new Date(year - 1, 0, 1);
        const to_cache = new Date(year, month, 1);

        // console.log(from_cache, to_cache);

        // Args to use for resDate

        const from_resDate = new Date(year, month, 1);
        const to_resDate = new Date(year, month, day);

        // console.log(from_resDate, to_resDate);

        // Args to use for checkIn & checkOut

        const to_checkIn = new Date(year + 1, 11, 31);

        // console.log(from_resDate, to_checkIn);

        let args_cache = JSON.stringify({
            from: from_cache,
            to: to_cache,
            // overwriteCache: true,
        });

        let args = JSON.stringify({
            from: from_resDate,
            to: to_checkIn,
            useCache: false,
        });

        // Function to handle both requests and combine the results
        const fetchCommissionData = async () => {
            try {
                // Request with args_cache
                const responseCache = await Axios({
                    method: "post",
                    url: `${URLAPI}/commission`,
                    data: args_cache,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                });

                // Request with args
                const response = await Axios({
                    method: "post",
                    url: `${URLAPI}/commission`,
                    data: args,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.user.jwt}`,
                    },
                });

                const {
                    data: {
                        data: dataCache,
                        site_names: site_names_cache,
                        currency_symbols: currency_symbolsCache,
                        currency_codes: currency_codesCache,
                        currencies: currenciesCache,
                    },
                } = responseCache;
                const {
                    data: {
                        data,
                        site_names,
                        currency_symbols,
                        currency_codes,
                        currencies,
                    },
                } = response;

                const site_names_set = [
                    ...new Set([...site_names_cache, ...site_names]),
                ];

                const currency_symbols_set = [
                    ...new Set([...currency_symbolsCache, ...currency_symbols]),
                ];

                const currency_code_set = [
                    ...new Set([...currency_codesCache, ...currency_codes]),
                ];

                const currencies_set = {
                    ...currenciesCache,
                    ...currencies,
                };

                // Combine the results
                const combinedData = [...dataCache, ...data];

                // localStorage.setItem(
                //     "commission",
                //     JSON.stringify(combinedData.slice(0, 100))
                // );

                dispatch(
                    load_Commission(
                        combinedData,
                        site_names_set,
                        currency_symbols_set,
                        currency_code_set,
                        currencies_set
                    )
                );
            } catch (error) {
                // checkCache();
                dispatch(error_Commission(error, res));
                console.error(error);
            }
        };

        fetchCommissionData();
    }, [hotelID, dataCommission.reload, waitToLoad, authHotels]);

    //   ____       _   _   _
    //  / ___|  ___| |_| |_(_)_ __   __ _ ___
    //  \___ \ / _ \ __| __| | '_ \ / _` / __|
    //   ___) |  __/ |_| |_| | | | | (_| \__ \
    //  |____/ \___|\__|\__|_|_| |_|\__, |___/
    //                              |___/
    useEffect(() => {
        if (waitToLoad) return;
        if (
            (authRoles.length === 0 && authHotels.length === 0) ||
            auth.loading !== false
        ) {
            return;
        }
        dispatch(load_Settings(auth.user.user_id));
    }, [auth, settings.reload, waitToLoad]);

    //   _   _       _   _  __ _           _   _
    //  | \ | | ___ | |_(_)/ _(_) ___ __ _| |_(_) ___  _ __  ___
    //  |  \| |/ _ \| __| | |_| |/ __/ _` | __| |/ _ \| '_ \/ __|
    //  | |\  | (_) | |_| |  _| | (_| (_| | |_| | (_) | | | \__ \
    //  |_| \_|\___/ \__|_|_| |_|\___\__,_|\__|_|\___/|_| |_|___/
    useEffect(() => {
        if (waitToLoad) return;
        if (
            (authRoles.length === 0 && authHotels.length === 0) ||
            auth.loading !== false
        ) {
            return;
        }
        {
            dispatch(loadNotifications(auth.user.user_id));
        }
    }, [auth, notifications.reload, waitToLoad, authHotels]);

    //  _                    _ _
    // | |    ___   __ _  __| (_)_ __   __ _
    // | |   / _ \ / _` |/ _` | | '_ \ / _` |
    // | |__| (_) | (_| | (_| | | | | | (_| |
    // |_____\___/ \__,_|\__,_|_|_| |_|\__, |
    //                                 |___/

    const DataToLoad = {
        ...(Reservations && { dataBookings: dataBookings }),
        ...(SimpleData && { dataSimple: dataSimple }),
        ...(VouchersDetails && { dataVouchersDetails: dataVouchersDetails }),
        ...(Searches && { dataSearches: dataSearches }),
        ...(Commission && { dataCommission: dataCommission }),
    };

    const isDataLoading = () => {
        let dataLoading = Object.values(DataToLoad).every(
            (dataType) => dataType.status !== "loaded"
        );

        // console.log({
        //     dataLoading,
        //     reservationsLoad:
        //         Reservations &&
        //         dataBookings.status !== "loaded" &&
        //         dataBookings.status !== "partiallyLoaded",
        // });

        return Reservations // TODO: special case for Bookings so it will close the message as soon it's loaded or partiallyLoaded
            ? dataBookings.status !== "loaded" &&
                  dataBookings.status !== "partiallyLoaded"
            : dataLoading;
    };

    const [openLoadingMessage, setOpenLoadingMessage] = useState(true);
    useEffect(() => {
        if (isDataLoading()) {
            setOpenLoadingMessage(true);
        } else {
            setOpenLoadingMessage(false);
        }
    }, [
        dataBookings.status,
        dataCommission.status,
        dataSimple.status,
        dataVouchersDetails.status,
        dataSearches.status,
        hotelID,
    ]);

    return (
        <>
            <Modal
                open={openLoadingMessage}
                onClose={(event, reason) => {
                    if (
                        reason !== "backdropClick" &&
                        reason !== "escapeKeyDown"
                    ) {
                        setOpenLoadingMessage(false);
                    }
                }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box component={Paper} sx={{ p: 5 }}>
                    <Stack direction={"column"} spacing={3}>
                        <Stack
                            direction={"row"}
                            spacing={1}
                            sx={{
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography variant="h5">Data loading</Typography>
                            <CircularProgress />
                        </Stack>

                        <Stack
                            direction={"row"}
                            spacing={1}
                            sx={{ justifyContent: "center" }}
                        >
                            {BUBBLES_DATA()
                                .filter(({ k }) => DataToLoad.hasOwnProperty(k))
                                .map(({ k, t, reload }) => {
                                    return (
                                        <LoadingBubble
                                            key={k}
                                            dataKey={k}
                                            title={t}
                                            reload={reload}
                                        />
                                    );
                                })}
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Button
                                startIcon={<Icons.Refresh />}
                                variant="outlined"
                                onClick={() => window.location.reload()}
                            >
                                Refresh page
                            </Button>
                            <Button
                                startIcon={<Icons.LogOut />}
                                variant="outlined"
                                onClick={() => {
                                    localStorage.clear();
                                    window.location.reload(false);
                                }}
                            >
                                Log out
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default DataLoader;
