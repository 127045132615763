import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Container,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
    Stack,
    ButtonBase,
} from "@mui/material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Colours from "../helpers/colours";
import {
    COMPARISON_FILTER,
    CANCELLATIONS_FILTER,
    DATATYPE_CHECKIN,
    DATATYPE_CHECKOUT,
    DATATYPE_RESDATE,
    GROUPs,
    PRESET_CHOOSE_MONTH,
    PRESET_CUSTOM,
    PRESET_BOOKING_GROUP,
    PRESET_MONTH_TO_DATE,
    PresetDates,
    formatDate,
    process_,
    DEFAULT_COMPARISON,
    PRESET_ARRIVAL_GROUP,
    COMPARISON_CUSTOM,
    PRESET_RESDATE_GROUP,
    PRESET_SELECTED_PERIOD,
    GROUP_COUNTRIES,
    GROUP_OFFERS,
    GROUP_ROOM,
    GROUP_TYPE,
    GROUP_VOUCHERS,
    GROUP_EXTRAS,
} from "../helpers/filters";
import Icons from "../helpers/icons";
import { fullExport } from "../helpers/export";
import { getYearsBefore, MAX_DATE, MIN_DATE } from "../helpers/dates";

import ToggleButtonTitled from "./ToggleButtonTitled";
import TextPulledAgo from "./TextPulledAgo";
import DateRangePicker from "./DateRangePicker";

import { setJS } from "../actions/globalReportFilters";
import { loadOne } from "../actions/PDFExport";

import { ACTIVE_FILTERS_LINE } from "./shared/ReportBuilder/ReportBuilder";
import FiltersByType, {
    ActiveFiltersLine,
} from "./shared/ReportBuilder/FiltersByType";
import dayjs from "dayjs";

//   _____ ___ _   _____ _____ ____  ____  ____  _____ ____   ___  ____ _____
//  |  ___|_ _| | |_   _| ____|  _ \/ ___||  _ \| ____|  _ \ / _ \|  _ \_   _|
//  | |_   | || |   | | |  _| | |_) \___ \| |_) |  _| | |_) | | | | |_) || |
//  |  _|  | || |___| | | |___|  _ < ___) |  _ <| |___|  __/| |_| |  _ < | |
//  |_|   |___|_____|_| |_____|_| \_\____/|_| \_\_____|_|    \___/|_| \_\|_|
export const ITEM_HEIGHT = "50px";

// This shows all the allowed filters and metrics to select for the reports
const FiltersReport = ({
    setProcessed = (v) => {},
    hide = [],
    PDF = false,
    defaultValues = {},
    CustomFilters = null,
    rename = {},
    BookingPresetOptions = PRESET_BOOKING_GROUP,
    ArrivalPresetOptions = PRESET_ARRIVAL_GROUP,
    ResDatePresetOptions = PRESET_RESDATE_GROUP,
    otherProps = {},
    filtersOrder = [
        "DATATYPE",
        "TIME_PERIOD",
        "TIME_RANGE",
        "ARRIVAL_PERIOD",
        "TIME_ARRIVAL_RANGE",
        "RESDATE_PERIOD",
        "TIME_BOOKING_RANGE",
        "FILTERS",
        "CANCELLATIONS",
        "COMPARISON",
        "CUSTOM_FILTERS",
    ],
}) => {
    const js = useSelector((state) => state.globalReportFilters);
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [updateKey, setUpdateKey] = useState(0);

    // SET DEFAULT VALUES
    useEffect(() => {
        let aux = { ...defaultValues };
        let newJS = { ...js };
        if ("presetDate" in defaultValues) {
            newJS = {
                ...newJS,
                ...handlePresetChange(defaultValues["presetDate"], newJS),
                // presetDate: defaultValues["presetDate"],
            };
            delete aux.presetDate;
        }
        if ("presetArrivalDate" in defaultValues) {
            newJS = {
                ...newJS,
                presetArrivalDate: defaultValues["presetArrivalDate"],
                ...handlePresetArrivalChange(
                    defaultValues["presetArrivalDate"],
                    newJS
                ),
            };

            delete aux.presetArrivalDate;
        }
        if ("presetResDate" in defaultValues) {
            newJS = {
                ...newJS,
                presetResDate: defaultValues["presetResDate"],
                ...handlePresetResDateChange(
                    defaultValues["presetResDate"],
                    newJS
                ),
            };

            delete aux.presetArrivalDate;
        }
        if ("filters" in defaultValues) {
            newJS = {
                ...newJS,
                // filters: { ...newJS.filters, ...aux.filters },
                filters: aux.filters,
            };

            delete aux.filters;
        }
        dispatch(
            setJS({
                ...newJS,
                ...aux,
            })
        );
    }, [hotelID]);

    useEffect(() => {
        if (!PDF) {
            dispatch(
                loadOne(<FiltersReport PDF={true} />, "Filters", hotelID, {
                    duplicable: false,
                })
            );
        }
    }, [PDF]);

    useEffect(() => {
        setUpdateKey(updateKey + 1);
    }, []);
    const { dataBookings } = useSelector((state) => state);
    const [processedData, setProcessedData] = useState([]);

    const [lenProcessed, setLenProcessed] = useState(0);

    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [openArrivalDatePicker, setOpenArrivalDatePicker] = useState(false);
    const [openResDateDatePicker, setOpenResDateDatePicker] = useState(false);
    const [openCustomComparison, setOpenCustomComparison] = useState(false);

    useEffect(() => {
        if (dataBookings.status === "loaded") {
            let { entries: process_out, len } = process_({
                js: js,
                dataBookings,
                hotelID,
            });
            setLenProcessed(len);
            setProcessedData(process_out);
            setProcessed(process_out);
        }
    }, [js, dataBookings]);

    // useEffect(() => {}, [processedData]);

    const handleExport = () => {
        const compileData = (config) => {
            let { entries: process_out, len } = process_({
                js: config,
                dataBookings,
                hotelID,
            });

            return { process_out, len };
        };

        const groupBys = [
            GROUP_COUNTRIES,
            GROUP_OFFERS,
            GROUP_ROOM,
            GROUP_TYPE,
            GROUP_VOUCHERS,
            GROUP_EXTRAS,
            "",
        ];

        let auxConfigs = {};

        groupBys.forEach((groupBy) => {
            let updatedConfigs = { ...js };
            updatedConfigs.groupBy = [groupBy]; // Update the groupBy property with the current groupBy value
            auxConfigs[groupBy] = updatedConfigs; // Store the updated object using the current groupBy value as key
        });

        const processedData = {};
        let totalLength = 0;
        Object.entries(auxConfigs).forEach(([configName, config]) => {
            const { process_out, len } = compileData(config);
            processedData[configName] = process_out;
            totalLength += len;
        });

        fullExport(js, processedData, hotelID);
    };

    const handlePresetChange = (presetValue, js_ = js_) => {
        const isOpen = presetValue === PRESET_CUSTOM;
        setOpenDatePicker(isOpen);

        const preset = isOpen ? PRESET_MONTH_TO_DATE : presetValue;
        const { fromDay, toDay } = PresetDates(preset, {
            year: dayjs().year(),
            month: dayjs().month(),
        });

        // If Arrival is selected period, update when time period changes
        if (js_.presetArrivalDate === PRESET_SELECTED_PERIOD) {
            const { fromDay: fromArrivalDay, toDay: toArrivalDay } =
                PresetDates(PRESET_SELECTED_PERIOD, {
                    js: { fromDay, toDay },
                });
            return {
                ...js_,
                fromDay,
                toDay,
                fromArrivalDay,
                toArrivalDay,
                presetDate: presetValue,
            };
        }

        return { ...js_, fromDay, toDay, presetDate: presetValue };
        // dispatch(setJS({ ...js, fromDay, toDay, presetDate: presetValue }));
    };

    const handlePresetArrivalChange = (presetArrivalValue, js_ = js_) => {
        const isOpen = presetArrivalValue === PRESET_CUSTOM;
        setOpenArrivalDatePicker(isOpen);

        // const preset = isOpen ? PRESET_ARRIVAL_DEFAULT : presetArrivalValue;
        const preset = presetArrivalValue;
        const { fromDay: fromArrivalDay, toDay: toArrivalDay } = PresetDates(
            preset,
            {
                year: dayjs().year(),
                month: dayjs().month(),
                js: js_,
            }
        );
        return {
            ...js_,
            fromArrivalDay,
            toArrivalDay,
            presetArrivalDate: presetArrivalValue,
        };
        // dispatch(setJS({ ...js, fromDay, toDay, presetDate: presetValue }));
    };
    const handlePresetResDateChange = (presetResDateValue, js_ = js_) => {
        const isOpen = presetResDateValue === PRESET_CUSTOM;
        setOpenResDateDatePicker(isOpen);

        // const preset = isOpen ? PRESET_ARRIVAL_DEFAULT : presetArrivalValue;
        const preset = presetResDateValue;
        const { fromDay: fromResDateDay, toDay: toResDateDay } = PresetDates(
            preset,
            {
                year: dayjs().year(),
                month: dayjs().month(),
                js: js_,
            }
        );
        return {
            ...js_,
            fromResDateDay,
            toResDateDay,
            presetResDate: presetResDateValue,
        };
        // dispatch(setJS({ ...js, fromDay, toDay, presetDate: presetValue }));
    };

    //   _____ ___ _   _____ _____ ____  ____
    //  |  ___|_ _| | |_   _| ____|  _ \/ ___|
    //  | |_   | || |   | | |  _| | |_) \___ \
    //  |  _|  | || |___| | | |___|  _ < ___) |
    //  |_|   |___|_____|_| |_____|_| \_\____/
    const filtersBlocks = {
        //  ____    _  _____  _  _______   ______  _____
        // |  _ \  / \|_   _|/ \|_   _\ \ / /  _ \| ____|
        // | | | |/ _ \ | | / _ \ | |  \ V /| |_) |  _|
        // | |_| / ___ \| |/ ___ \| |   | | |  __/| |___
        // |____/_/   \_\_/_/   \_\_|   |_| |_|   |_____|
        DATATYPE: {
            render: () =>
                !hide.includes("DATATYPE") && (
                    <ToggleButtonTitled
                        TITLE={
                            otherProps["DATATYPE_TITLE"] ??
                            "Show performance by"
                        }
                        values={
                            otherProps["DATATYPE_VALUES"] ?? {
                                [DATATYPE_RESDATE]: "Booked on date",
                                [DATATYPE_CHECKIN]: "Checked in date",
                                [DATATYPE_CHECKOUT]: "Checked out date",
                            }
                        }
                        valueSet={js.dataType ?? DATATYPE_RESDATE}
                        onChange={(e, v) => {
                            dispatch(setJS({ ...js, dataType: v }));
                        }}
                    />
                ),
        },
        //  _____ ___ __  __ _____   ____  _____ ____  ___ ___  ____
        // |_   _|_ _|  \/  | ____| |  _ \| ____|  _ \|_ _/ _ \|  _ \
        //   | |  | || |\/| |  _|   | |_) |  _| | |_) || | | | | | | |
        //   | |  | || |  | | |___  |  __/| |___|  _ < | | |_| | |_| |
        //   |_| |___|_|  |_|_____| |_|   |_____|_| \_\___\___/|____/
        TIME_PERIOD: {
            render: () =>
                !hide.includes("TIME_PERIOD") && (
                    <Grid item>
                        <Typography>
                            {rename["TIME_PERIOD"] ?? "Time period"}
                        </Typography>
                        <FormControl size="small">
                            <Select
                                labelId="timePeriodLabel"
                                id="timePeriodSelect"
                                value={js.presetDate ?? PRESET_CUSTOM}
                                onChange={(e, v) =>
                                    dispatch(
                                        setJS(
                                            handlePresetChange(
                                                v.props.value,
                                                js
                                            )
                                        )
                                    )
                                }
                                sx={{ height: ITEM_HEIGHT }}
                                MenuProps={{ sx: { maxHeight: "280px" } }}
                            >
                                {BookingPresetOptions.map((e) => (
                                    <MenuItem key={e} value={e}>
                                        {e}
                                    </MenuItem>
                                ))}
                                <MenuItem value={PRESET_CUSTOM}>
                                    Custom
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                ),
        },
        //  _____ ___ __  __ _____   ____      _    _   _  ____ _____
        // |_   _|_ _|  \/  | ____| |  _ \    / \  | \ | |/ ___| ____|
        //   | |  | || |\/| |  _|   | |_) |  / _ \ |  \| | |  _|  _|
        //   | |  | || |  | | |___  |  _ <  / ___ \| |\  | |_| | |___
        //   |_| |___|_|  |_|_____| |_| \_\/_/   \_\_| \_|\____|_____|
        TIME_RANGE: {
            render: () =>
                !hide.includes("TIME_RANGE") && (
                    <Grid item>
                        <Typography>
                            {rename["TIME_RANGE"] ?? "Stay date"}
                        </Typography>

                        <DateRangePicker
                            open={openDatePicker}
                            boxProps={{ sx: { height: ITEM_HEIGHT } }}
                            fromDateProps={
                                (otherProps["TIME_RANGE"] ?? {})[
                                    "fromDateProps"
                                ] ?? {
                                    value: dayjs(js.fromDay),
                                }
                            }
                            isRangePicker={
                                js.presetDate !== PRESET_CHOOSE_MONTH // If SINGLE PICKER logic changes to follow fromDate
                            }
                            onChangeFromDate={(newValue) => {
                                let auxChooseMonthToDay =
                                    js.presetDate !== PRESET_CHOOSE_MONTH
                                        ? js.toDay
                                        : PresetDates(PRESET_CHOOSE_MONTH, {
                                              year: newValue.year(),
                                              month: newValue.month(),
                                          }).toDay;
                                dispatch(
                                    setJS({
                                        ...js,
                                        fromDay: formatDate(newValue),
                                        ...(js.presetDate !==
                                        PRESET_CHOOSE_MONTH
                                            ? { presetDate: PRESET_CUSTOM }
                                            : {
                                                  toDay: auxChooseMonthToDay,
                                                  toArrivalDay:
                                                      js.presetArrivalDate ===
                                                      PRESET_SELECTED_PERIOD
                                                          ? auxChooseMonthToDay
                                                          : js.toArrivalDay,
                                              }),
                                        // If selected period, update arrival
                                        fromArrivalDay:
                                            js.presetArrivalDate ===
                                            PRESET_SELECTED_PERIOD
                                                ? formatDate(newValue)
                                                : js.fromArrivalDay,
                                    })
                                );
                            }}
                            toDateProps={
                                (otherProps["TIME_RANGE"] ?? {})[
                                    "toDateProps"
                                ] ?? { value: dayjs(js.toDay) }
                            }
                            onChangeToDate={(newValue) => {
                                dispatch(
                                    setJS({
                                        ...js,
                                        toDay: formatDate(newValue),
                                        presetDate: PRESET_CUSTOM,
                                        // If selected period, update arrival
                                        toArrivalDay:
                                            js.presetArrivalDate ===
                                            PRESET_SELECTED_PERIOD
                                                ? formatDate(newValue)
                                                : js.toArrivalDay,
                                    })
                                );
                            }}
                        />
                    </Grid>
                ),
        },
        //     _    ____  ____  _____     ___    _       ____  _____ ____  ___ ___  ____
        //    / \  |  _ \|  _ \|_ _\ \   / / \  | |     |  _ \| ____|  _ \|_ _/ _ \|  _ \
        //   / _ \ | |_) | |_) || | \ \ / / _ \ | |     | |_) |  _| | |_) || | | | | | | |
        //  / ___ \|  _ <|  _ < | |  \ V / ___ \| |___  |  __/| |___|  _ < | | |_| | |_| |
        // /_/   \_\_| \_\_| \_\___|  \_/_/   \_\_____| |_|   |_____|_| \_\___\___/|____/
        ARRIVAL_PERIOD: {
            render: () =>
                !hide.includes("ARRIVAL_PERIOD") && (
                    <Grid item>
                        <Typography>Arrival period</Typography>
                        <FormControl size="small">
                            <Select
                                labelId="timePeriodLabel"
                                id="arrivalPeriodSelect"
                                value={js.presetArrivalDate ?? PRESET_CUSTOM}
                                onChange={(e, v) =>
                                    dispatch(
                                        setJS(
                                            handlePresetArrivalChange(
                                                v.props.value,
                                                js
                                            )
                                        )
                                    )
                                }
                                sx={{ height: ITEM_HEIGHT }}
                                MenuProps={{ sx: { maxHeight: "280px" } }}
                            >
                                {ArrivalPresetOptions.map((e) => (
                                    <MenuItem key={e} value={e}>
                                        {e}
                                    </MenuItem>
                                ))}
                                <MenuItem value={PRESET_CUSTOM}>
                                    Custom
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                ),
        },
        //  _____ ___ __  __ _____      _    ____  ____  _____     ___    _
        // |_   _|_ _|  \/  | ____|    / \  |  _ \|  _ \|_ _\ \   / / \  | |
        //   | |  | || |\/| |  _|     / _ \ | |_) | |_) || | \ \ / / _ \ | |
        //   | |  | || |  | | |___   / ___ \|  _ <|  _ < | |  \ V / ___ \| |___
        //   |_| |___|_|  |_|_____| /_/   \_\_| \_\_| \_\___|  \_/_/   \_\_____|
        TIME_ARRIVAL_RANGE: {
            render: () =>
                !hide.includes("TIME_ARRIVAL_RANGE") && (
                    <Grid item>
                        <Typography>Custom arrival date</Typography>

                        <DateRangePicker
                            boxProps={{ sx: { height: ITEM_HEIGHT } }}
                            fromDateProps={{
                                value: dayjs(js.fromArrivalDay),
                            }}
                            isRangePicker={
                                js.presetArrivalDate !== PRESET_CHOOSE_MONTH // If SINGLE PICKER logic changes to follow fromDate
                            }
                            onChangeFromDate={(newValue) => {
                                let auxChooseMonthToDay =
                                    js.presetArrivalDate !== PRESET_CHOOSE_MONTH
                                        ? js.toArrivalDay
                                        : PresetDates(PRESET_CHOOSE_MONTH, {
                                              year: newValue.year(),
                                              month: newValue.month(),
                                          }).toDay;
                                dispatch(
                                    setJS({
                                        ...js,
                                        fromArrivalDay: formatDate(newValue),
                                        ...(js.presetArrivalDate !==
                                        PRESET_CHOOSE_MONTH
                                            ? {
                                                  presetArrivalDate:
                                                      PRESET_CUSTOM,
                                              }
                                            : {
                                                  toArrivalDay:
                                                      auxChooseMonthToDay,
                                              }),
                                    })
                                );
                            }}
                            toDateProps={{
                                value: dayjs(js.toArrivalDay),
                                maxDate: dayjs(
                                    dayjs(js.fromArrivalDay).month(
                                        dayjs(js.fromArrivalDay).month() + 12
                                    ) +
                                        24 * 60 * 60 * 1000
                                ),
                            }}
                            onChangeToDate={(newValue) => {
                                dispatch(
                                    setJS({
                                        ...js,
                                        toArrivalDay: formatDate(newValue),
                                        presetArrivalDate: PRESET_CUSTOM,
                                    })
                                );
                            }}
                            open={openArrivalDatePicker}
                        />
                    </Grid>
                ),
        },
        //  ____  _____ ____  ____    _  _____ _____   ____  _____ ____  ___ ___  ____
        // |  _ \| ____/ ___||  _ \  / \|_   _| ____| |  _ \| ____|  _ \|_ _/ _ \|  _ \
        // | |_) |  _| \___ \| | | |/ _ \ | | |  _|   | |_) |  _| | |_) || | | | | | | |
        // |  _ <| |___ ___) | |_| / ___ \| | | |___  |  __/| |___|  _ < | | |_| | |_| |
        // |_| \_\_____|____/|____/_/   \_\_| |_____| |_|   |_____|_| \_\___\___/|____/
        RESDATE_PERIOD: {
            render: () =>
                !hide.includes("RESDATE_PERIOD") && (
                    <Grid item>
                        <Typography>
                            {rename["RESDATE_PERIOD"] ?? "Booking period"}
                        </Typography>
                        <FormControl size="small">
                            <Select
                                labelId="timePeriodLabel"
                                id="arrivalPeriodSelect"
                                value={js.presetResDate ?? PRESET_CUSTOM}
                                onChange={(e, v) =>
                                    dispatch(
                                        setJS(
                                            handlePresetResDateChange(
                                                v.props.value,
                                                js
                                            )
                                        )
                                    )
                                }
                                sx={{ height: ITEM_HEIGHT }}
                                MenuProps={{ sx: { maxHeight: "280px" } }}
                            >
                                {ResDatePresetOptions.map((e) => (
                                    <MenuItem key={e} value={e}>
                                        {e}
                                    </MenuItem>
                                ))}
                                <MenuItem value={PRESET_CUSTOM}>
                                    Custom
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                ),
        },
        //  _____ ___ __  __ _____     ____   ___   ___  _  _____ _   _  ____     ____      _    _   _  ____ _____
        // |_   _|_ _|  \/  | ____|   | __ ) / _ \ / _ \| |/ /_ _| \ | |/ ___|   |  _ \    / \  | \ | |/ ___| ____|
        //   | |  | || |\/| |  _|     |  _ \| | | | | | | ' / | ||  \| | |  _    | |_) |  / _ \ |  \| | |  _|  _|
        //   | |  | || |  | | |___    | |_) | |_| | |_| | . \ | || |\  | |_| |   |  _ <  / ___ \| |\  | |_| | |___
        //   |_| |___|_|  |_|_____|___|____/ \___/ \___/|_|\_\___|_| \_|\____|___|_| \_\/_/   \_\_| \_|\____|_____|
        TIME_BOOKING_RANGE: {
            render: () =>
                !hide.includes("TIME_BOOKING_RANGE") && (
                    <Grid item>
                        <Typography>
                            {rename["TIME_BOOKING_RANGE"] ??
                                "Custom booking date"}
                        </Typography>

                        <DateRangePicker
                            boxProps={{ sx: { height: ITEM_HEIGHT } }}
                            fromDateProps={
                                (otherProps["TIME_BOOKING_RANGE"] ?? {})[
                                    "fromDateProps"
                                ] ?? {
                                    value: dayjs(js.fromResDateDay),
                                }
                            }
                            isRangePicker={
                                js.presetResDate !== PRESET_CHOOSE_MONTH // If SINGLE PICKER logic changes to follow fromDate
                            }
                            onChangeFromDate={(newValue) => {
                                let auxChooseMonthToDay =
                                    js.presetResDate !== PRESET_CHOOSE_MONTH
                                        ? js.toResDateDay
                                        : PresetDates(PRESET_CHOOSE_MONTH, {
                                              year: newValue.year(),
                                              month: newValue.month(),
                                          }).toDay;
                                dispatch(
                                    setJS({
                                        ...js,
                                        fromResDateDay: formatDate(newValue),
                                        ...(js.presetResDate !==
                                        PRESET_CHOOSE_MONTH
                                            ? {
                                                  presetResDate: PRESET_CUSTOM,
                                              }
                                            : {
                                                  toResDateDay:
                                                      auxChooseMonthToDay,
                                              }),
                                    })
                                );
                            }}
                            toDateProps={
                                (otherProps["TIME_BOOKING_RANGE"] ?? {})[
                                    "toDateProps"
                                ] ?? {
                                    value: dayjs(js.toResDateDay),
                                }
                            }
                            onChangeToDate={(newValue) => {
                                dispatch(
                                    setJS({
                                        ...js,
                                        toResDateDay: formatDate(newValue),
                                    })
                                );
                            }}
                            open={openResDateDatePicker}
                        />
                    </Grid>
                ),
        },
        //  _____ ___ _   _____ _____ ____  ____
        // |  ___|_ _| | |_   _| ____|  _ \/ ___|
        // | |_   | || |   | | |  _| | |_) \___ \
        // |  _|  | || |___| | | |___|  _ < ___) |
        // |_|   |___|_____|_| |_____|_| \_\____/
        FILTERS: {
            render: () =>
                !hide.includes("FILTERS") && (
                    <Grid item>
                        <FiltersByType hide={hide} height={ITEM_HEIGHT} />
                    </Grid>
                ),
        },
        //      ____                     _ _       _   _                    ___        _
        //     / ___|__ _ _ __   ___ ___| | | __ _| |_(_) ___  _ __  ___   / _ \ _ __ | |_   _
        //    | |   / _` | '_ \ / __/ _ \ | |/ _` | __| |/ _ \| '_ \/ __| | | | | '_ \| | | | |
        //    | |__| (_| | | | | (_|  __/ | | (_| | |_| | (_) | | | \__ \ | |_| | | | | | |_| |
        //     \____\__,_|_| |_|\___\___|_|_|\__,_|\__|_|\___/|_| |_|___/  \___/|_| |_|_|\__, |
        //                                                                               |___/
        CANCELLATIONS: {
            render: () =>
                !hide.includes("CANCELLATIONS") && (
                    <Grid item>
                        <Typography>Cancellations</Typography>
                        <FormControl>
                            <Select
                                labelId="cancellationsFilter"
                                id="cancellationsFilter"
                                value={js.filters.Cancellations}
                                sx={{
                                    height: ITEM_HEIGHT,
                                    width: "140px",
                                }}
                                onChange={(event) => {
                                    let value = event.target.value;
                                    dispatch(
                                        setJS({
                                            ...js,
                                            filters: {
                                                ...(js.filters ?? {}),
                                                Cancellations: value,
                                            },
                                        })
                                    );
                                }}
                                size="small"
                            >
                                {Object.keys(CANCELLATIONS_FILTER).map(
                                    (key) => {
                                        return hide.includes(
                                            CANCELLATIONS_FILTER[key].hideKey
                                        ) ? null : (
                                            <MenuItem
                                                key={key + "key"}
                                                value={key}
                                            >
                                                {
                                                    CANCELLATIONS_FILTER[key]
                                                        .label
                                                }
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                ),
        },
        //      _____                                 _
        //     / ____|     _ _  __   _ __   __ _ _ __(_)___  ___  _ __
        //    | |    / _ \| '_ ` _ \| '_ \ / _` | '__| / __|/ _ \| '_ \
        //    | |___| (_) | | | | | | |_) | (_| | |  | \__ \ (_) | | | |
        //     \_____\___/|_| |_| |_| .__/ \__,_|_|  |_|___/\___/|_| |_|
        //                          |_|
        COMPARISON: {
            render: () =>
                !hide.includes("COMPARISON") && (
                    <>
                        <Grid item>
                            <Typography>Comparison</Typography>

                            <FormControl>
                                <Select
                                    labelId="comparisonFilter"
                                    id="comparisonFilter"
                                    value={
                                        js.filters.Comparison ??
                                        DEFAULT_COMPARISON
                                    }
                                    sx={{
                                        height: ITEM_HEIGHT,
                                        // width: "140px",
                                    }}
                                    onChange={(event) => {
                                        let value = event.target.value;
                                        dispatch(
                                            setJS({
                                                ...js,
                                                filters: {
                                                    ...(js.filters ?? {}),
                                                    Comparison: value,
                                                },
                                            })
                                        );
                                    }}
                                    size="small"
                                >
                                    {Object.keys(COMPARISON_FILTER).map(
                                        (key) => {
                                            return (
                                                <MenuItem
                                                    key={key + "key"}
                                                    value={key}
                                                >
                                                    {
                                                        COMPARISON_FILTER[key]
                                                            .label
                                                    }
                                                </MenuItem>
                                            );
                                        }
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            {js.filters.Comparison === COMPARISON_CUSTOM && (
                                <DateRangePicker
                                    boxProps={{ sx: { height: ITEM_HEIGHT } }}
                                    fromDateProps={{
                                        value: dayjs(
                                            js.filters.ComparisonCustom.from
                                        ),
                                    }}
                                    onChangeFromDate={(newValue) => {
                                        dispatch(
                                            setJS({
                                                ...js,
                                                filters: {
                                                    ...js.filters,
                                                    ComparisonCustom: {
                                                        ...js.filters
                                                            .ComparisonCustom,
                                                        from: formatDate(
                                                            newValue
                                                        ),
                                                    },
                                                },
                                            })
                                        );
                                    }}
                                    toDateProps={{
                                        value: dayjs(
                                            js.filters.ComparisonCustom.to
                                        ),
                                    }}
                                    onChangeToDate={(newValue) => {
                                        dispatch(
                                            setJS({
                                                ...js,
                                                filters: {
                                                    ...js.filters,
                                                    ComparisonCustom: {
                                                        ...js.filters
                                                            .ComparisonCustom,
                                                        to: formatDate(
                                                            newValue
                                                        ),
                                                    },
                                                },
                                            })
                                        );
                                    }}
                                    open={openCustomComparison}
                                />
                            )}
                        </Grid>
                    </>
                ),
        },
        //      ____          _                  _____ _ _ _
        //     / ___|   _ ___| |_ ___  _ __ ___ |  ___(_) | |_ ___ _ __ ___
        //    | |  | | | / __| __/ _ \| '_ ` _ \| |_  | | | __/ _ \ '__/ __|
        //    | |__| |_| \__ \ || (_) | | | | | |  _| | | | ||  __/ |  \__ \
        //     \____\__,_|___/\__\___/|_| |_| |_|_|   |_|_|\__\___|_|  |___/
        CUSTOM_FILTERS: {
            render: () =>
                CustomFilters && (
                    <Grid item>
                        <CustomFilters />
                    </Grid>
                ),
        },
    };

    return (
        <>
            <Container
                maxWidth={false}
                // component={HoverPaper}
                // sx={{ p: 2 }}
            >
                {!PDF && (
                    <Stack direction="row" justifyContent="flex-end">
                        <Box
                            sx={{
                                p: 1,
                                textAlign: "right",
                                fontSize: "0.8em",
                                color: Colours.primary,
                            }}
                        >
                            <ButtonBase
                                onClick={handleExport}
                                sx={{ fontFamily: "Montserrat" }}
                            >
                                <Icons.ArrowFullDown
                                    sx={{ fontSize: "1em", mr: "0.5em" }}
                                />
                                Download excel
                            </ButtonBase>
                        </Box>
                        <Box
                            sx={{
                                p: 1,
                                textAlign: "right",
                                fontSize: "0.8em",
                                color: Colours.primary,
                            }}
                        >
                            <ButtonBase
                                onClick={() => {
                                    navigate("/pdfexport");
                                }}
                                sx={{ fontFamily: "Montserrat" }}
                            >
                                <Icons.ArrowFullDown
                                    sx={{ fontSize: "1em", mr: "0.5em" }}
                                />
                                Generate PDF
                            </ButtonBase>{" "}
                        </Box>
                    </Stack>
                )}

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    spacing={1}
                >
                    {/* Show filters in order */}
                    {filtersOrder.map((k) => (
                        <React.Fragment key={k}>
                            {filtersBlocks[k].render()}
                        </React.Fragment>
                    ))}

                    {/* Show the rest filters */}
                    {Object.keys(filtersBlocks)
                        .filter((e) => !filtersOrder.includes(e))
                        .map((k) => filtersBlocks[k].render())}

                    {/*  ____  _____ _____ ____  _____ ____  _   _  */}
                    {/* |  _ \| ____|  ___|  _ \| ____/ ___|| | | | */}
                    {/* | |_) |  _| | |_  | |_) |  _| \___ \| |_| | */}
                    {/* |  _ <| |___|  _| |  _ <| |___ ___) |  _  | */}
                    {/* |_| \_\_____|_|   |_| \_\_____|____/|_| |_| */}
                    {/* <Grid item>
                        <RefreshButton ITEM_HEIGHT={ITEM_HEIGHT} />
                    </Grid> */}

                    {/*   ____ ____   ___  _   _ ____    ______   __ */}
                    {/*  / ___|  _ \ / _ \| | | |  _ \  | __ ) \ / / */}
                    {/* | |  _| |_) | | | | | | | |_) | |  _ \\ V /  */}
                    {/* | |_| |  _ <| |_| | |_| |  __/  | |_) || |   */}
                    {/*  \____|_| \_\\___/ \___/|_|     |____/ |_|   */}
                    {/* <CheckShow rolesThatCanSee={ARO_ADMIN}>
                        <Grid item>
                            <Typography> Group by</Typography>
                            <FormControl>
                                <Select
                                    sx={{ height: ITEM_HEIGHT }}
                                    multiple
                                    value={js.groupBy ?? []}
                                    onChange={(e, v) => {
                                        let value = v.props.value;

                                        let index = (js.groupBy ?? []).indexOf(
                                            value
                                        );

                                        if (index > -1)
                                            dispatch(
                                                setJS({
                                                    ...js,
                                                    groupBy: (
                                                        js.groupBy ?? []
                                                    ).filter(
                                                        (item) => item !== value
                                                    ),
                                                })
                                            );
                                        else
                                            dispatch(
                                                setJS({
                                                    ...js,
                                                    groupBy: [
                                                        ...(js.groupBy ?? []),
                                                        value,
                                                    ],
                                                })
                                            );
                                    }}
                                    // input={<OutlinedInput label="Group By" />}
                                    renderValue={() => "Group by.."}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                // maxHeight: 60 * 4.5 + 8,
                                                width: "auto",
                                            },
                                        },
                                    }}
                                >
                                    {GROUPs.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox
                                                checked={
                                                    (js.groupBy ?? []).indexOf(
                                                        name
                                                    ) > -1
                                                }
                                            />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </CheckShow> */}
                    {/* <CheckShow rolesThatCanSee={ARO_ADMIN}>
                        <Grid item>
                            <Box
                                sx={{
                                    height: ITEM_HEIGHT,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Tooltip title="Number of entries under the selected filters">
                                    <Typography>{lenProcessed}</Typography>
                                </Tooltip>
                            </Box>
                        </Grid>
                    </CheckShow> */}
                </Grid>
            </Container>

            <Container maxWidth={false}>
                <TextPulledAgo />
            </Container>

            {/*  _____ ___ _   _____ _____ ____  ____    _     ___ _   _ _____  */}
            {/* |  ___|_ _| | |_   _| ____|  _ \/ ___|  | |   |_ _| \ | | ____| */}
            {/* | |_   | || |   | | |  _| | |_) \___ \  | |    | ||  \| |  _|   */}
            {/* |  _|  | || |___| | | |___|  _ < ___) | | |___ | || |\  | |___  */}
            {/* |_|   |___|_____|_| |_____|_| \_\____/  |_____|___|_| \_|_____| */}
            {!hide.includes(ACTIVE_FILTERS_LINE) && (
                <Container maxWidth={false} sx={{ pb: 2 }}>
                    <ActiveFiltersLine />
                </Container>
            )}
        </>
    );
};

export default FiltersReport;
