import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";

import MonthlyReportScreen from "../pages/MonthlyReportScreen";
import MonthlyKPIsScreen from "../pages/MonthlyKPIsScreen";
import MembershipReportScreen from "../pages/MembershipReportScreen";

import SalesSummary from "../components/SalesSummary";
import CheckShow from "../components/shared/CheckShow";
import FiltersReport from "../components/FiltersReport";
import ReportBuilder, {
    BREAK_LINE,
    CUSTOM,
    fields_OverviewTable,
    fields_OverviewTable_Vouchers,
    GRID_HORIZONTAL,
    REPORT_CHART_BARS_CATEGORY,
    REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY,
    REPORT_CHART_WORLD_MAP,
    REPORT_TABLE_METRICS_SUMMARY,
    REPORT_TABLE_OVERVIEW,
    TAB,
    TABS,
} from "../components/shared/ReportBuilder/ReportBuilder";

import { ARO_ADMIN } from "../configuration";
import {
    DATATYPE_RESDATE,
    DISPLAY_ELEMENT_HELPER,
    GROUP_COUNTRIES,
    GROUP_DATE_DAY,
    GROUP_DATE_MONTH,
    GROUP_DATE_YEAR,
    GROUP_EXTRAS,
    GROUP_OFFERS,
    GROUP_ROOM,
    GROUP_VOUCHERS,
    PRESET_ANYTIME,
    PRESET_MONTH_TO_DATE,
} from "../helpers/filters";
import { setHotelID } from "../actions/hotelID";
import ExtrasScreen from "../pages/ExtrasScreen";

const Dashboard = ({ handleProcessedData, processedData }) => {
    const js = useSelector((state) => state.globalReportFilters);

    const search = useLocation().search;
    const dispatch = useDispatch();

    const URLpresetDate = new URLSearchParams(search).get("presetDate");
    const URLhotelID = new URLSearchParams(search).get("hotelid");

    const [fields, setFields] = useState([
        "revenueTotal",
        "revenueRoom",
        "revenueRoomAndExtras",
        "revenueExtras",
        "bookingsRooms",
        "roomNightsTotal",
        "bookingsCancelled",
        "empty",
        "ABWTotal",
        "ALoSTotal",
        "ADRTotal",
        "empty",
        "revenueVouchers",
        "vouchersBookings",
        "vouchersCount",
    ]);
    let reports = [
        {
            type: REPORT_TABLE_METRICS_SUMMARY,
            groupBy: [],
            fieldsOrder: fields,
            hideFields: [
                ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
                    (e) => !fields.includes(e)
                ),
            ],
            PDF: "Metrics",
        },
        { type: BREAK_LINE, quantity: 1 },
        {
            type: TABS,
            default: "Rate Plan",
            tabs: [
                {
                    type: TAB,
                    title: "Rate Plan",
                    reports: [
                        {
                            type: GRID_HORIZONTAL,
                            spacing: 2,
                            reports: [
                                {
                                    type: REPORT_CHART_BARS_CATEGORY,
                                    title: "Rate Plan - Bar chart - 1",
                                    groupBy: [GROUP_OFFERS],
                                    metric: "bookingsTotal",
                                    metrics: {
                                        ...DISPLAY_ELEMENT_HELPER,
                                        bookingsTotal: {
                                            ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
                                            short: "Bookings total",
                                        },
                                    },
                                },
                            ],
                        },

                        {
                            type: REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY,
                            title: "Rate Plan - Trend chart - 1",
                            groupBy: [
                                GROUP_OFFERS,
                                GROUP_DATE_YEAR,
                                GROUP_DATE_MONTH,
                                GROUP_DATE_DAY,
                            ],
                            filters: { Types: ["Special Offer"] },
                            props: {
                                title: "Trend by rate plan",
                                showLastYear: false,
                                defaultBreakdown: "weekly",
                            },
                        },
                        {
                            type: REPORT_TABLE_OVERVIEW,
                            title: "Rate Plan - Overview table",
                            groupBy: [GROUP_OFFERS],
                            ...fields_OverviewTable,
                        },
                    ],
                },
                {
                    type: TAB,
                    title: "Room Type",
                    reports: [
                        {
                            type: GRID_HORIZONTAL,
                            spacing: 2,
                            reports: [
                                {
                                    type: REPORT_CHART_BARS_CATEGORY,
                                    title: "Room Type - Bar chart - 1",
                                    groupBy: [GROUP_ROOM],
                                    metric: "bookingsTotal",
                                    metrics: {
                                        ...DISPLAY_ELEMENT_HELPER,
                                        bookingsTotal: {
                                            ...DISPLAY_ELEMENT_HELPER.bookingsTotal,
                                            short: "Bookings total",
                                        },
                                    },
                                },
                            ],
                        },

                        {
                            type: REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY,
                            title: "Room Type - Trend chart - 1",

                            groupBy: [
                                GROUP_ROOM,
                                GROUP_DATE_YEAR,
                                GROUP_DATE_MONTH,
                                GROUP_DATE_DAY,
                            ],
                            filters: { Types: ["Special Offer"] },
                            props: {
                                title: "Trend by room type",
                                showLastYear: false,
                                defaultBreakdown: "weekly",
                            },
                        },
                        {
                            type: REPORT_TABLE_OVERVIEW,
                            title: "Room Type - Overview table",

                            groupBy: [GROUP_ROOM],
                            ...fields_OverviewTable,
                            sortable: [],
                        },
                    ],
                },
                {
                    type: TAB,
                    title: "Geographical Market",
                    reports: [
                        {
                            type: REPORT_CHART_WORLD_MAP,
                            title: "Geographical Market - Map",
                            groupBy: [GROUP_COUNTRIES],
                        },
                        {
                            type: REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY,
                            title: "Geographical Market - Trend chart - 1",
                            groupBy: [
                                GROUP_COUNTRIES,
                                GROUP_DATE_YEAR,
                                GROUP_DATE_MONTH,
                                GROUP_DATE_DAY,
                            ],
                            props: {
                                title: "Trend by country",
                                showLastYear: false,
                                defaultBreakdown: "weekly",
                            },
                        },
                        {
                            type: REPORT_TABLE_OVERVIEW,
                            title: "Geographical Market - Overview table",
                            groupBy: [GROUP_COUNTRIES],
                            ...fields_OverviewTable,
                        },
                    ],
                },
                {
                    // EXTRAS TAB
                    type: TAB,
                    title: "Extras",
                    reports: [
                        {
                            type: CUSTOM,
                            groupBy: [],
                            title: "Extras - Bar chart",
                            render: ({ js, hotelID, data }) => {
                                return (
                                    <ExtrasScreen
                                        js={js}
                                        hotelID={hotelID}
                                        data={data}
                                    />
                                );
                            },
                        },
                        {
                            type: REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY,
                            title: "Extras - Trend Chart",
                            groupBy: [
                                GROUP_EXTRAS,
                                GROUP_DATE_YEAR,
                                GROUP_DATE_MONTH,
                                GROUP_DATE_DAY,
                            ],
                            props: {
                                title: "Trend by Extras",

                                showLastYear: false,
                                defaultBreakdown: "weekly",
                                metrics: {
                                    revenueSelectedExtra: {
                                        ...DISPLAY_ELEMENT_HELPER.revenueSelectedExtra,
                                        short: "Revenue",
                                    },
                                    countSelectedExtra: {
                                        ...DISPLAY_ELEMENT_HELPER.countSelectedExtra,
                                        short: "Number sold",
                                    },
                                },
                            },
                        },
                        {
                            type: REPORT_TABLE_OVERVIEW,
                            title: "Extras - Overview table",
                            groupBy: [GROUP_EXTRAS],
                            fieldsOrder: [
                                "countSelectedExtra",
                                "revenueSelectedExtra",
                                "bookingsRooms",
                                "roomNightsTotal",
                            ],
                            hideFields: [
                                ...Object.keys(DISPLAY_ELEMENT_HELPER).filter(
                                    (e) =>
                                        ![
                                            "countSelectedExtra",
                                            "revenueSelectedExtra",
                                            "bookingsRooms",
                                            "roomNightsTotal",
                                        ].includes(e)
                                ),
                            ],
                        },
                    ],
                },
                {
                    type: TAB,
                    isVoucher: true,
                    title: "Vouchers",
                    reports: [
                        {
                            type: GRID_HORIZONTAL,
                            spacing: 2,
                            reports: [
                                {
                                    type: REPORT_CHART_BARS_CATEGORY,
                                    title: "Vouchers - Bar chart - 1",
                                    groupBy: [GROUP_VOUCHERS],
                                    metric: "vouchersCount",
                                    metrics: Object.entries(
                                        DISPLAY_ELEMENT_HELPER
                                    ).reduce((acc, [key, value]) => {
                                        acc[key] =
                                            key === "revenueVouchers" ||
                                            key === "vouchersCount"
                                                ? {
                                                      ...value,
                                                      important: true,
                                                      short:
                                                          "revenueVouchers" ===
                                                          key
                                                              ? "Revenue"
                                                              : "Number sold",
                                                  }
                                                : {
                                                      ...value,
                                                      important: false,
                                                  };
                                        return acc;
                                    }, {}),
                                    filters: { Types: ["Voucher"] },
                                    props: { showVouchers: true },
                                },
                            ],
                        },
                        {
                            type: REPORT_CHART_TREND_DAILY_WEEKLY_MONTHLY,
                            title: "Vouchers - Trend chart - 1",
                            groupBy: [
                                GROUP_VOUCHERS,
                                GROUP_DATE_YEAR,
                                GROUP_DATE_MONTH,
                                GROUP_DATE_DAY,
                            ],
                            filters: { Types: ["Voucher"] },
                            props: {
                                showVouchers: true,
                                showLastYear: false,
                                defaultBreakdown: "weekly",
                                metrics: {
                                    revenueVouchers: {
                                        ...DISPLAY_ELEMENT_HELPER.revenueVouchers,
                                        short: "Revenue",
                                    },
                                    vouchersCount: {
                                        ...DISPLAY_ELEMENT_HELPER.vouchersCount,
                                        short: "Number sold",
                                    },
                                },
                            },
                        },
                        {
                            type: REPORT_TABLE_OVERVIEW,
                            title: "Vouchers - Overview table",
                            groupBy: [GROUP_VOUCHERS],
                            filters: { Types: ["Voucher"] },
                            props: { showVouchers: true },
                            ...fields_OverviewTable_Vouchers,
                        },
                    ],
                },
            ],
        },
    ];

    useEffect(() => {
        if (URLhotelID) dispatch(setHotelID(URLhotelID));
    }, [URLhotelID]);

    useEffect(() => {
        if ((js.filters.Extras ?? []).length > 0) {
            setFields([
                "revenueTotal",
                "revenueExtras",
                "numberOfExtras",
                "bookingsWithExtras",
                "roomNightsTotal",
            ]);
        } else {
            setFields([
                "revenueTotal",
                "revenueRoom",
                "revenueRoomAndExtras",
                "revenueExtras",
                "bookingsRooms",
                "roomNightsTotal",
                "bookingsCancelled",
                "empty",
                "ABWTotal",
                "ALoSTotal",
                "ADRTotal",
                "empty",
                "revenueVouchers",
                "vouchersBookings",
                "vouchersCount",
            ]);
        }
    }, [js.filters.Extras, js]);

    return (
        <>
            <Grid container>
                <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                    <Typography variant="h4">Reporting</Typography>
                </Grid>
            </Grid>{" "}
            <FiltersReport
                hide={[
                    "ARRIVAL_PERIOD",
                    "TIME_ARRIVAL_RANGE",
                    "RESDATE_PERIOD",
                    "TIME_BOOKING_RANGE",
                    "FILTERS_TYPES",
                ]}
                defaultValues={{
                    presetDate: URLpresetDate ?? PRESET_MONTH_TO_DATE,
                    presetArrivalDate: PRESET_ANYTIME,
                    presetResDate: PRESET_ANYTIME,
                    dataType: DATATYPE_RESDATE,
                    filters: {
                        Cancellations: "included",
                    },
                }}
                setProcessed={handleProcessedData}
            />
            <ReportBuilder reports={reports} />
        </>
    );
};

const AroReportScreen = ({}) => {
    const [processedData, setProcessedData] = useState({});

    const handleProcessedData = (data) => {
        setProcessedData(data);
    };

    return (
        <>
            <Routes>
                <Route
                    path=""
                    element={
                        <Dashboard
                            handleProcessedData={handleProcessedData}
                            processedData={processedData}
                        />
                    }
                />
                <Route
                    path="dashboard"
                    element={
                        <Dashboard
                            handleProcessedData={handleProcessedData}
                            processedData={processedData}
                        />
                    }
                />
                <Route
                    path="summary"
                    element={
                        <>
                            <br />
                            <SalesSummary />
                            <br />
                            <CheckShow rolesThatCanSee={ARO_ADMIN}>
                                <MonthlyReportScreen />
                            </CheckShow>
                        </>
                    }
                />
                <Route path="kpis" element={<MonthlyKPIsScreen isolated />} />
                <Route
                    path="membership"
                    element={
                        <>
                            <br />
                            <MembershipReportScreen />
                        </>
                    }
                />
            </Routes>
        </>
    );
};
export default AroReportScreen;
